import { Injectable } from '@angular/core';
import { PasswordValidate } from './signup.models';

@Injectable({
    providedIn: 'root'
})
export class SignUpService {
    validatePassword(pass?: string): PasswordValidate {
        let validateForm = {
            validations: {
                upperCase: false,
                lowerCase: false,
                number: false,
                special: false,
                minimum: false,
            },
            result: false,
        };
        if (pass!.match('[A-Z]')?.index !== undefined) {
            validateForm.validations.upperCase = true;
        } else {
            validateForm.validations.upperCase = false;
        }
        if (pass!.match('[a-z]')?.index !== undefined) {
            validateForm.validations.lowerCase = true;
        } else {
            validateForm.validations.lowerCase = false;
        }
        if (pass!.match('[0-9]')?.index !== undefined) {
            validateForm.validations.number = true;
        } else {
            validateForm.validations.number = false;
        }
        if (pass!.match('[^A-Za-z0-9]')?.index !== undefined) {
            validateForm.validations.special = true;
        } else {
            validateForm.validations.special = false;
        }
        if (pass!.length > 5) {
            validateForm.validations.minimum = true;
        } else {
            validateForm.validations.minimum = false;
        }

        if (Object.values(validateForm.validations).every(item => item === true)) {
            validateForm.result = true;
        }
        return validateForm;
    }
}