import { User } from "../players/player.model";

export interface ClubFinancial {
	_id: number,
	_clubId: string,
	dateStart: string,
	dateEnd: string,
	fileName: string,
	status: string,
	createdAt: string,
	totalPlayers: number,
	sentCountError: number,
	sentCountSuccess: number,
	players: ImportedPlayer[]
}

export interface GameAccountRakebackData {
	data: Array<GameAccountRakeback>;
}

export interface GameAccountRakeback {
	_gameAccount: string;
	rakebackPercent: number;
}

export enum ClubFinancialStatus {
	NOT_STARTED = 'NOT_STARTED',
	STARTED = 'STARTED',
	COMPLETED = 'COMPLETED',
	COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
	NO_PERCENTAGE_DEFINED = 'NO_PERCENTAGE_DEFINED',
	ERROR = 'ERROR',
	IN_QUEUE = 'IN_QUEUE',
	UNRELEATED = 'UNRELEATED',
	WITHOUT_PERCENTS = 'WITHOUT_PERCENTS',
	WITH_PERCENTS = 'WITH_PERCENTS',
	SUCCESS = 'SUCCESS',
	IN_PROGRESS = 'IN_PROGRESS',
	FAILURE = 'FAILURE',
	TO_QUEUE = 'TO_QUEUE'
}

export enum ClubFinancialStatusNamed {
	NOT_STARTED = 'Aguardando início..',
	STARTED = 'Processando',
	COMPLETED = 'Efetuado',
	COMPLETED_WITH_ERRORS = 'Efetuado com erro(s)',
	NO_PERCENTAGE_DEFINED = 'Sem % definida',
	ERROR = 'ERROR',
	IN_QUEUE = 'Na fila',
	WITH_PERCENTS = 'Com porcentagem',
	WITHOUT_PERCENTS = 'Sem porcentagem',
	UNRELEATED = 'Não vinculado',
	SUCCESS = "Sucesso no envio",
	IN_PROGRESS = 'Enviando fichas...',
	FAILURE = 'Erro no envio',
	TO_QUEUE = 'Aguardando entrar na fila'
}

export enum ClubFinancialErrorJaimeTask {
	MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
	THERE_IS_NO_BALANCE_TO_SEND = 'THERE_IS_NO_BALANCE_TO_SEND',
	TRANSACTION_NOT_CONFIRMED_BY_SCREEN = 'TRANSACTION_NOT_CONFIRMED_BY_SCREEN',
}

export interface ImportedPlayer {
	_id: string;
	_rakebackSender: string;
	_gameAccount: string;
	playerId: string;
	rake: number;
	rakebackPercent: number | null;
	rakebackValue: number;
	sentManually?: boolean;
	sentManuallyAt?: Date;
	sentManuallyBy?: User;
	status: string;
	errorJaimeTask: string;
}

export interface RakebackList {
	data: ClubFinancial[],
	total: number,
	limit: number,
	page: number,
	pages: number
}

export interface RakebackPlayersList {
	_id: string;
	_gameAccount: string;
	_rakebackSenderId: string;
	rakebackPercents: number;
	rake: number;
	status: string;
	sentManually?: boolean;
	sentManuallyAt?: Date;
	sentManuallyBy?: User;
}

export interface Pageable<T> {
  total: number;
  limit: number;
  page: number;
  pages: number;
  data: Array<T>;
}

export class Criteria {
	limit: number;
	page: number;
	pages: number;
	total: number;
	sortColumn: any;
	rakebackSenderId: string;
	status?: ClubFinancialStatus;
	searchTerm: string;
	rakebackPlayers: string;
	filterBy?: ClubFinancialStatus;
  
	constructor() {
	  this.limit = 15;
	  this.page = 1;
	  this.pages = 1;
	  this.total = 0;
	  this.sortColumn = '';
		this.rakebackSenderId = '';
		this.status = ClubFinancialStatus.NOT_STARTED;
		this.filterBy = ClubFinancialStatus.WITH_PERCENTS;
    this.searchTerm = '';
    this.rakebackPlayers = '';
	}
}

export interface RakebackPayment {
	_gameAccount: string;
	rakebackValue: number;
}
