<app-user-info-modal #userInfoModal></app-user-info-modal>
<div>
  <div class="dashboard border border-0">
    <div class="row row-cols-1 row-cols-md-2 justify-content-between m-0 bg-light">
      <div class="col-md-4 p-0">
        <div class="p-4 mb-2 mb-md-4 mr-0 mr-md-2 rounded-lg bg-white shadow-sm d-flex align-items-center"
          style="height: 110px;">
          <i class="bi bi-person-x-fill mr-4" style="font-size: 1.5rem;"></i>
          <div class="mb-4 mb-sm-0 p-0">
            <dt class="text-muted">Inativo / Bloqueado</dt>
            <div class="h5">
              {{gameAccountData.notActivated}} / {{gameAccountData.blocked}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-0">
        <div class="p-4 mb-2 mb-md-4 mx-0 mx-md-2 rounded-lg bg-white shadow-sm d-flex align-items-center"
          style="height: 110px;">
          <i class="bi bi-person-check-fill mr-4" style="font-size: 1.5rem;"></i>
          <div class="mb-4 mb-sm-0 p-0">
            <dt class="text-muted">Ativo / Saque bloqueado</dt>
            <div class="h5">
              {{gameAccountData.activated}} / {{gameAccountData.withdrawBlocked}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-0">
        <div class="p-4 mb-2 mb-md-4 ml-0 ml-md-2 rounded-lg bg-white shadow-sm d-flex align-items-center"
          style="height: 110px;">
          <i class="bi bi-person-lines-fill mr-4" style="font-size: 1.5rem;"></i>
          <div class="mb-4 mb-sm-0 p-0">
            <dt class="text-muted">Total de jogadores</dt>
            <div class="h5">
              {{gameAccountData.total}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-header-options">
  <div class="text-muted">Visualizar informações dos jogadores por:</div>
  <div class="btn-group" role="group" aria-label="radio toggle button group">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn btn-filter active m-0">
        <input type="radio" name="options" id="all" autocomplete="off" (change)="onRadioChange('all')" checked>Todos
      </label>
      <label class="btn btn-filter m-0">
        <input type="radio" name="options" id="custom" autocomplete="off" (change)="onRadioChange('custom')">Personalizados
      </label>
      <label class="btn btn-filter m-0">
        <input type="radio" name="options" id="invited" autocomplete="off" (change)="onRadioChange('invited')">Convidados
      </label>
    </div>
  </div>
</div>
<div *ngIf="!isInviteNewPlayersPage; else inviteNewPlayersPage">
  <div class="form-inline p-3 m-0 d-flex justify-content-between">
    <div class="input-group">
      <input class="form-control border-right-0" type="text" placeholder="Buscar..." name="searchTerm"
        (input)="onSearchChange(criteria)" [(ngModel)]="criteria.searchTerm" />
      <div class="input-group-append">
        <span class="input-group-text bg-white">
          <i class="bi bi-search"></i>
        </span>
      </div>
    </div>
    <select title="player-filter" class="form-control custom-select my-1" [(ngModel)]="filterByType"
      (ngModelChange)="onFilterChange($event)">
      <option selected [value]="playerStatus.ALL">{{playerStatusNamed[playerStatus.ALL]}}</option>
      <option [value]="playerStatus.ACTIVE"> {{playerStatusNamed[playerStatus.ACTIVE]}}</option>
      <option [value]="playerStatus.NOT_ACTIVE">{{playerStatusNamed[playerStatus.NOT_ACTIVE]}}</option>
      <option [value]="playerStatus.WITHDRAW_BLOCKED">{{playerStatusNamed[playerStatus.WITHDRAW_BLOCKED]}}</option>
      <option [value]="playerStatus.BLOCKED">{{playerStatusNamed[playerStatus.BLOCKED]}}</option>
      <option [value]="playerStatus.WITHDRAW_MANUAL">{{playerStatusNamed[playerStatus.WITHDRAW_MANUAL]}}</option>
      <option [value]="playerStatus.WITHDRAW_AUTOMATIC">{{playerStatusNamed[playerStatus.WITHDRAW_AUTOMATIC]}}</option>
    </select>
  </div>
  
  <div class="table-responsive table table-striped"  style="min-height: 250px;">
    <table class="table table-borderless m-0">
      <thead class="border-top border-bottom border-light">
        <tr class="text-muted">
          <th scope="col">Nome</th>
          <th scope="col" class="text-center">Jogador ID</th>
          <th scope="col" class="text-center">Telefone</th>
          <th scope="col" class="text-center">Data</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Restrição</th>
          <th scope="col" class="text-center">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-top border-bottom align-middle" style="height: 70px;" *ngFor="let player of players">
          <td class="align-middle">
            <ngb-highlight class="btn btn-link text-primary p-0" (click)="openUserInfoModal(player)"
              [result]="player._user.name" [term]="criteria.searchTerm">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="player.playerAccountId" [term]="criteria.searchTerm">
            </ngb-highlight>
          </td>
          <td class="align-middle text-center">
            <a href="https://wa.me/+55{{player._user.ddd + player._user.phoneNumber}}" target="_blank">
              <i class="bi bi-whatsapp mr-2"></i>
              ({{player._user.ddd}}) {{player._user.phoneNumber}}
            </a>
          </td>
          <td class="align-middle text-center">
            <ngb-highlight [result]="player.createdAt | date: 'd/M/yy'">
            </ngb-highlight>
          </td>
          <td class="text-center font-weight-light align-middle">
            <span *ngIf="player.wasActivated"
              class="badge badge-pill badge-success font-weight-normal">
              {{playerStatusNamed[playerStatus.ACTIVE]}}
            </span>
            <span *ngIf="!player.wasActivated" class="badge badge-pill badge-dark font-weight-normal">
              {{playerStatusNamed[playerStatus.NOT_ACTIVE]}}
            </span>
          </td>
          <td class="text-center font-weight-light align-middle">
            <span *ngIf="player.restrictionStatus === playerStatus.BLOCKED"
              class="badge badge-pill badge-danger font-weight-normal">
              {{playerStatusNamed[playerStatus.BLOCKED]}}
            </span>
            <span *ngIf="player.restrictionStatus === playerStatus.WITHDRAW_BLOCKED"
              class="badge badge-pill badge-warning font-weight-normal">
              {{playerStatusNamed[playerStatus.WITHDRAW_BLOCKED]}}
            </span>
            <span *ngIf="player.restrictionStatus === playerStatus.WITHDRAW_MANUAL" class="badge badge-pill badge-info font-weight-normal"> 
              {{playerStatusNamed[playerStatus.WITHDRAW_MANUAL]}}
            </span>
            <span *ngIf="!player.restrictionStatus" class="badge badge-pill badge-success font-weight-normal"> 
              {{playerStatusNamed[playerStatus.WITHDRAW_AUTOMATIC]}}
            </span>
          </td>
          <td class="align-middle">
            <app-actions [player]="player" [isModal]="true" (actionPerformed)="onDoubleCheckConfirmed()"></app-actions>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div *ngIf="this.players.length === 0">
    <div *ngIf="filterByType === playerStatus.ALL" class="alert alert-light text-center mx-auto mb-0" role="alert">
      Ainda não houve jogadores vinculados.
    </div>
    <div *ngIf="filterByType !== playerStatus.ALL" class="alert alert-light text-center mx-auto mb-0" role="alert">
      Nenhum jogador foi encontrado
    </div>
  </div>
  
  <div *ngIf="this.players.length > 0" class="row d-flex justify-content-between table-responsive m-0">
    <p class="mt-4 mx-3">
      <small class="font-weight-light">
        Exibindo
        <span class="font-weight-normal">{{criteria.page}}</span>
        de
        <span class="font-weight-normal">{{criteria.totalPages}}</span>
        {{criteria.totalPages > 1 ? 'páginas': 'página'}}
      </small>
    </p>
    <ngb-pagination class="mt-3 mx-3" [collectionSize]="criteria.totalDocs" [(page)]="criteria.page"
      [pageSize]="criteria.limit" [maxSize]="4" [boundaryLinks]="true" (pageChange)="jumpPagination($event)"
      [hidden]="criteria.page > criteria.totalDocs">
    </ngb-pagination>
  </div>
</div>

<ng-template #inviteNewPlayersPage>
  <div class="p-3">
    <app-invite-new-players (onInvitedPlayerModified)="updatePlayerList($event)"
      (onCriteriaChange)="jumpPagination($event)" [data]="players" [criteria]="criteria" [alertText]="this.doubleCheckModalText.ACTIVATE_ALERT_TEXT"></app-invite-new-players>
  </div>
</ng-template>