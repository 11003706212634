<div class="modal-content border border-light">
  <ng-container *ngIf="!isRefused; else refusedTemplate">
    <div class="modal-header bg-light border-light">
      <h4 class="font-weight-bold">Termos e Políticas</h4>
    </div>
    <form name="form" #termsOfUseForm="ngForm"
      (ngSubmit)="termsOfUseForm.form.valid && onSubmit()">
      <div class="modal-body">
        <div class="row">
          <span class="terms-alert font-weight-bold">Aceite os termos e políticas para continuar utilizando a
            Chippix.</span>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input name="privacyPolicy" [(ngModel)]="termsPolitics.isPrivacyPolicyChecked" #privacyPolicy="ngModel" 
              type="checkbox" class="custom-control-input" id="privacyPolicy" required privacyPolicy
              [ngClass]="{ 'is-invalid': termsOfUseForm.submitted && privacyPolicy.invalid }">
            <label class="custom-control-label" for="privacyPolicy"><a
                href="https://chippix.notion.site/POL-TICA-DE-PRIVACIDADE-2f8d2c68858745b6864bbdbab2d9b959"
                class="alert-link" target="_blank">Política de Privacidade</a></label>
          </div>
          <div class="custom-control custom-checkbox">
            <input name="termsAndConditionsOfUse" [(ngModel)]="termsPolitics.isTermsAndConditionsOfUseChecked" #termsAndConditionsOfUse="ngModel" 
              type="checkbox" class="custom-control-input" id="termsAndConditionsOfUse" required termsAndConditionsOfUse
              [ngClass]="{ 'is-invalid': termsOfUseForm.submitted && termsAndConditionsOfUse.invalid}">
            <label class="custom-control-label" for="termsAndConditionsOfUse"><a
                href="https://chippix.notion.site/TERMOS-E-CONDI-ES-DE-USO-8b3916cb6c9a44d5941f43ad133c4230"
                class="alert-link" target="_blank">Termos e Condições de Uso</a></label>
          </div>
          <div class="custom-control custom-checkbox">
            <input name="termsOfUse" [(ngModel)]="termsPolitics.isTermsOfUseChecked" #termsOfUse="ngModel" 
              type="checkbox" class="custom-control-input" id="termsOfUse" required termsOfUse
              [ngClass]="{ 'is-invalid': termsOfUseForm.submitted && termsOfUse.invalid }">
            <label class="custom-control-label" for="termsOfUse"><a
                href="https://chippix.notion.site/TERMOS-DE-USO-f041ac87b8a44b6dbe5c188febd87932" 
                class="alert-link" target="_blank">Termos de Uso</a></label>
          </div>
          <div *ngIf="termsOfUseForm.submitted && (termsAndConditionsOfUse.invalid || termsOfUse.invalid || privacyPolicy.invalid)"
            [ngClass]="{ 'invalid': termsOfUseForm.submitted}">
            <div>*Todos os termos devem ser aceitados para que você possa continuar.</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="csub-btn btn btn-light" (click)="openConfirmTermsModal()">
          Recusar
        </button>
        <button type="submit" class="csub-btn btn btn-primary" data-dismiss="modal">
          Aceitar
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #refusedTemplate>
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
          <h4 class="font-weight-bold">Deseja recusar os termos e políticas?</h4>
      </div>
      <div class="modal-body">
          <span>Para continuar usando a plataforma, é necessário aceitar nossos Termos e Políticas. Se você optar por <strong>recusar</strong>,
             será desconectado e só poderá voltar a usá-la assim que aceitar nossos Termos e Políticas.</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="csub-btn btn btn-light" (click)="backToTerms()">
          Não, voltar para os termos
        </button>
        <button type="button" class="csub-btn btn btn-danger" data-dismiss="modal" (click)="logout()">
          Sim, recusar
        </button>
      </div>
    </div>
  </ng-template>
</div>