export interface PageableData<T> {
  data: Pageable<T>;
}

export interface Pageable<T> {
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  data: Array<T>;
}

export interface Player {
  _id: string;
  _gameAccount: string;
  name: string;
  createdAt: string;
  pix: number;
  withdraw: number;
}

export interface TotalPlayers {
  total: number;
  lastPlayers: number;
}

export interface TotalPlayersResponse {
  data: TotalPlayers;
}

export class Criteria {
  limit: number;
  page: number;
  totalPages: number;
  searchTerm: string;
  totalDocs: number;
  listByName: null;
  datePeriod: number;
  temperatureLabel: string;
  isThermometerActive: boolean;

  constructor() {
    this.limit = 15;
    this.page = 1;
    this.totalPages = 1;
    this.searchTerm = '';
    this.totalDocs = 0;
    this.listByName = null;
    this.datePeriod = 30;
    this.temperatureLabel = 'all';
    this.isThermometerActive = false;
  }
}
