import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Criteria, ImportedPlayer } from "../../club-financials.model";
import { Router } from "@angular/router";

@Component({
  selector: 'app-financial-unreleated-list',
  templateUrl: './financial-unreleated-list.component.html',
  styleUrls: ['./financial-unreleated-list.component.scss'],
})
export class FinancialUnreleatedListComponent implements OnInit {

  @Input('rakebackPlayersUnreleated') rakebackPlayersUnreleated: ImportedPlayer[] = [];
  @Input('criteria') criteria: Criteria = new Criteria();

  @Output() getRakebackPlayerUnreleatedList = new EventEmitter<any>();

  @Input('isLoading') isLoading!: boolean;

  maxSize = 3;  
  rotatePagination = true;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }
  
  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.getRakebackPlayerUnreleatedList.emit(this.criteria);
  }

  onInvitePlayer(playerId: any) {
    this.router.navigate(['player'], {
      queryParams: {
        playerId: playerId,
        tab: "invited"
      },
    });
  }

  onCheckInvitePlayerStatus() {
    this.router.navigate(['player'], {
      queryParams: {
        tab: "invited"
      },
    });
  }
}