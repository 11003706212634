import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskModule } from 'ngx-mask';
import { FinanceWithdrawModalComponent } from './../../modals/finance-withdraw-modal/finance-withdraw-modal.component';
import { FinanceComponent } from './finance.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxCurrencyModule } from 'ngx-currency';
import { FinanceAddFundsModalComponent } from 'src/app/modals/finance-add-funds-modal/finance-add-funds-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from 'ngx-clipboard';
import { OperationModalComponent } from 'src/app/modals/operation-modal/operation-modal.component';
import { OperationDetailsModule } from './components/operation-details/operation-details.module';
import { WithdrawDetailsModule } from '../transactions/components/withdraw-details/withdraw-details.module';
import { TransferDetailsModule } from '../transactions/components/transfer-details/transfer-details.module';
import { FinanceSlidePanelComponent } from './components/finance-slide-panel/finance-slide-panel.component';
import { customCurrencyMaskConfig } from 'src/app/config/custom-currency-mask.config';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false,
    }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSkeletonLoaderModule,
    QRCodeModule,
    ClipboardModule,
    OperationDetailsModule,
    WithdrawDetailsModule,
    TransferDetailsModule
  ],
  declarations: [
    FinanceComponent,
    FinanceWithdrawModalComponent,
    FinanceAddFundsModalComponent,
    OperationModalComponent,
    FinanceSlidePanelComponent
  ],
  exports: [FinanceComponent],
  bootstrap: [FinanceComponent],
})
export class FinanceModule {}
