// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const base_url = 'http://localhost:4201';
const baseUrl = 'https://dev.api.chippix.com.br:3000/v1';
const baseSocketUrl = 'https://dev.api.chippix.com.br:3000';

export const environment = {
  production: true,
  baseUrl,
  baseSocketUrl,
  urls: {
    basePath: 'https://dev.api.chippix.com.br:3000/v1',
    login: '/authenticate',
    refresh: '/refresh-token',
    confirmation: '/confirmation',
    user: '/user',
  },
  firebase: {
    apiKey: "AIzaSyBhbq5CUl2HcP7R1A09NcQbpxA19uKopgY",
    authDomain: "chippix-dev-web.firebaseapp.com",
    projectId: "chippix-dev-web",
    storageBucket: "chippix-dev-web.appspot.com",
    messagingSenderId: "449927523661",
    appId: "1:449927523661:web:93beff69c9e36321285b04",
    measurementId: "G-F0PP59SV4J"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
