import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    private sidebarVisibilitySubject = new BehaviorSubject<boolean>(true);
    public sidebarVisibility$ = this.sidebarVisibilitySubject.asObservable();

    toggleSidebarVisibility(isVisible: boolean) {
        this.sidebarVisibilitySubject.next(isVisible);
    }
}