import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { TermsPolitics } from './terms-politics-modal.models';
import { TermsPoliticsModalService } from './terms-politics-modal.services';
import { NotificationService } from 'src/app/shared/notification.service';
import Constants from 'src/app/shared/constants';

@Component({
  selector: 'app-terms-politics-modal',
  templateUrl: './terms-politics-modal.component.html',
  styleUrls: ['./terms-politics-modal.component.scss']
})
export class TermsPoliticsModalComponent implements OnInit {
  isRefused: boolean = false;
  termsPolitics: TermsPolitics = {} as TermsPolitics;

  constructor(
    public activateModal: NgbActiveModal,
    private authService: AuthGuard,
    private termsOfUseService: TermsPoliticsModalService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

  }

  onTermsAccepted(): void {
    const isAllTermsChecked = Object.values(this.termsPolitics).every(value => value === true);
    if (isAllTermsChecked) {
      this.termsOfUseService.updateUserTermsOfUse()
        .subscribe(
          () => {
            this.notificationService.showSuccess('Os Termos de Uso foram atualizados com sucesso!', '');
            this.closeModal();
            window.location.reload();
          },
          (err) => {
            const error = err.error.error_code;
            switch (err) {
              case err === Constants.ENTITY_NOT_FOUND:
                this.notificationService.showError('Não foi possível encontrar o usuário', 'Erro')
                break;
              default:
                this.notificationService.showError('Não foi possivel realizar o aceite dos termos', 'Erro');
                break;
            }
          }
        );
    }
  }

  onSubmit(): void {
    this.onTermsAccepted();
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.activateModal.close();
  }

  openConfirmTermsModal(): void {
    this.isRefused = true;
  }

  backToTerms(): void {
    this.isRefused = false;
  }

  logout(): void {
    this.authService.logout();
    this.activateModal.close();
  }
}
