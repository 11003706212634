export interface BalanceResponse {
  data: Balance;
}

export class Balance {
  amount: number;
  bloqueado: number;
  total: number;
  constructor() {
    this.amount = 0;
    this.bloqueado = 0;
    this.total = 0;
  }
}

export interface BankAccount {
  shortName: string;
  conta: string;
  ispb: string;
  agencia: string;
  tipoConta: string;
  nome: string;
  cpf?: string;
  cnpj?: string;
}

export interface BankAccountResponse {
  data: {
    bankData: BankAccount;
  };
}

export interface CreateFinanceAccount {
  doc: string;
  fullName: string;
}

export interface UpdateFinanceAccount {
  pixKey: string;
  pixKeyType: string;
}

export interface FinanceAccount {
  doc: string;
  fullName: string;
  pixKey: string;
  pixKeyType: string;
}

export interface FinanceAccountResponse {
  data: FinanceAccount;
}

export interface Person {
  cpf: string;
  cnpj: string | null;
  name: string;
}

export interface Operation {
  createdAt: string;
  amount: number;
  fee: number;
  status: string;
  playerId: string;
  type: string;
  action: boolean;
  e2EId: string;
  purpose: string;
  receiver?: Person;
  payer?: Person;
  pixId: string;
  _transaction: string;
  paymentId: string;
}

export interface OperationData{
  data: Operation;
}

export interface OperationHistory {
  data: Array<Operation>;
}

export class FinanceTransferAmount {
  amount: number;
  constructor() {
    this.amount = 0;
  }
}

export class Criteria {
  limit: number;
  page: number;
  pages: number;
  total: number;
  sortColumn: any;

  constructor() {
    this.limit = 15;
    this.page = 1;
    this.pages = 1;
    this.total = 0;
    this.sortColumn = '';
  }
}

export interface Pageable<O> {
  total: number;
  limit: number;
  page: number;
  pages: number;
  data: Array<O>;
}

export class OperationFlow {
  cashIn: number;
  cashOut: number;
  constructor() {
    this.cashIn = 0;
    this.cashOut = 0;
  }
}

export interface OperationFlowResponse {
  data: OperationFlow;
}

export class DatePicker {
  startDate!: string;
  endDate?: string;
}

export interface addBalanceData {
  id: string;
  amount: number;
  pix_qr_code: string;
  createdAt: string;
}

export interface addBalanceResponse {
  data: addBalanceData;
}
