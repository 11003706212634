import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlayerStatus, PlayerStatusNamed } from "src/app/shared/player.enum";
import { Account } from "../../models/account.model";
import { SortEvent } from "src/app/components/ngbd-sortable-header/ngbd-sortable-header.component";

@Component({
  selector: 'app-game-accounts-list',
  templateUrl: './game-accounts-list.component.html',
  styleUrls: ['./game-accounts-list.component.scss']
})
export class GameAccountsListComponent implements OnInit {

  @Input('player') player: any;
  @Input('data') data: any;

  @Output() showCostomizeWithdraw: EventEmitter<any> = new EventEmitter();
  @Output() gameAccountSelect = new EventEmitter<any>();
  @Output() actionPerformed = new EventEmitter<any>();

  gameAccountsList: Account[] = [];

  accountsSkeletonLoading: boolean = true
  isCustomizeShown: boolean = false

  column: string = '';
  directions: { [key: string]: string } = {};

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadGameAccountList();
    }, 600);
  }

  loadGameAccountList() {
    if(this.data) {
      this.accountsSkeletonLoading = false;
    } else {
      this.accountsSkeletonLoading = false;
      this.data = [];
    }
  }

  public get playerStatus(): typeof PlayerStatus {
    return PlayerStatus;
  }

  public get playerStatusNamed(): typeof PlayerStatusNamed {
    return PlayerStatusNamed;
  }

  openCustomizeWithdraw() {
    this.isCustomizeShown = true;
    this.showCostomizeWithdraw.emit();
  }

  onDoubleCheckConfirmed(): void {
    this.loadGameAccountList();
    this.actionPerformed.emit();
  }

  selectGameAccount(item: any) {
    this.gameAccountSelect.emit(item);
  }

  onSort({ column, direction }: SortEvent) {
    if (this.column && this.column !== column) {
      this.directions[this.column] = '';
    }
    this.column = column;
    this.directions[column] = direction;
  }

  getDirection(column: string): string {
    return this.directions[column] || '';
  }
}