<div id="__layout">
  <div class="d-flex align-items-center min-vh-100 min-vw-100 bg-light p-0">
    <div class="container-fluid py-3">
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;" />
        <h2 class="mt-4 text-center font-weight-bold">Criar nova conta</h2>
        <p class="mt-2 text-center text-muted">
          Ou
          <a [routerLink]="['/login']" class="text-decoration-none font-weight-bolder">
            entrar na sua conta existente
          </a>
        </p>
      </div>
      <div class="container-fluid mt-4 mx-auto p-0 mw-100" style="width: 430px;">
        <form name="form" #signupForm="ngForm"
          (ngSubmit)="signupForm.form.valid && this.validPassword.result && onSubmit()"
          class="bg-white p-4 shadow-sm rounded-lg">
          <div class="form-group mt-4 mb-0">
            <label class="mb-1"> Nome do Clube </label>
            <input type="text" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="clubName" [(ngModel)]="login.name" #clubName="ngModel"
              [ngClass]="{ 'is-invalid': signupForm.submitted && clubName.invalid }" required clubName />
            <div *ngIf="signupForm.submitted && clubName.invalid" class="invalid-feedback">
              <div *ngIf="clubName.errors?.required">Nome do Clube necessário</div>
            </div>
          </div>
          <div class="form-group mt-4 mb-0">
            <label class="mb-1"> Clube ID </label>
            <input type="number" oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg" style="height: 40px;"
              name="gameClubId" [(ngModel)]="login.gameClubId" #gameClubId="ngModel"
              [ngClass]="{ 'is-invalid': signupForm.submitted && gameClubId.invalid }" required gameClubId />
            <div *ngIf="signupForm.submitted && gameClubId.invalid" class="invalid-feedback">
              <div *ngIf="gameClubId.errors?.required">Clube ID necessário</div>
            </div>
          </div>
          <div class="form-group mt-4 mb-0">
            <label class="mb-1"> E-mail </label>
            <input type="text" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="email" [(ngModel)]="login.email" #email="ngModel"
              [ngClass]="{ 'is-invalid': signupForm.submitted && email.invalid }" required email />
            <div *ngIf="signupForm.submitted && email.invalid " class="invalid-feedback">
              <div *ngIf="email.errors?.required">Email necessário</div>
              <div *ngIf="email.errors?.email">O e-mail deve ser válido</div>
            </div>
          </div>
          <div class="form-group mt-4 mb-0">
            <label class="mb-1"> Número do telefone </label>
            <input mask="(00) 0000-0000||(00) 0 0000-0000" [dropSpecialCharacters]="true" prefix="+55 "
              [showMaskTyped]="false" placeHolderCharacter="*" type="tel"
              class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg" style="height: 40px;"
              name="fullPhoneNumber" [(ngModel)]="login.fullPhoneNumber" #fullPhoneNumber="ngModel"
              [ngClass]="{ 'is-invalid': signupForm.submitted && fullPhoneNumber.invalid }" required minlength="11"
              fullPhoneNumber />
            <div *ngIf="fullPhoneNumber.invalid" class="invalid-feedback">
              <div *ngIf="fullPhoneNumber.errors?.required">Número do telefone necessário</div>
              <div *ngIf="fullPhoneNumber.errors?.minlength">O número do telefone deve ser válido</div>
            </div>
          </div>
          <div class="form-group mt-4 mb-0">
            <label class="mb-1"> Senha </label>
            <input type="password" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="password" [(ngModel)]="login.password" #password="ngModel"
              [ngClass]="{ 'is-invalid': signupForm.submitted && password.invalid }" required minlength="6" password
              (ngModelChange)="onPasswordChange($event)" (keydown.space)="$event.preventDefault();" />
            <div *ngIf="signupForm.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf="password.errors?.required">Senha necessária</div>
            </div>
            <div class='ml-1'>
              <small class="mb-2 mt-2"
                [ngClass]="this.validPassword.result !== true && signupForm.submitted ? 'text-danger' : ''">
                A senha deve conter:</small>
              <div>
                <ul class="list-unstyled d-flex flex-column">
                  <small [ngClass]="this.validPassword.validations?.minimum === true ? 'text-success' : 'text-muted'">
                    <i class="bi bi-check-circle-fill mr-1"></i>
                    No mínimo 6 dígitos</small>
                  <small [ngClass]="this.validPassword.validations?.special === true ? 'text-success' : 'text-muted'">
                    <i class="bi bi-check-circle-fill mr-1"></i>
                    Um caractere especial</small>
                  <small [ngClass]="this.validPassword.validations?.upperCase === true ? 'text-success' : 'text-muted'">
                    <i class="bi bi-check-circle-fill mr-1"></i>
                    Uma letra maíscula</small>
                  <small [ngClass]="this.validPassword.validations?.lowerCase === true ? 'text-success' : 'text-muted'">
                    <i class="bi bi-check-circle-fill mr-1"></i>
                    Uma letra minúscula</small>
                  <small [ngClass]="this.validPassword.validations?.number === true ? 'text-success' : 'text-muted'">
                    <i class="bi bi-check-circle-fill mr-1"></i>
                    Um número</small>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="validCredentials === false"
            class="rounded-0 border-0 alert alert-danger mt-4 mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>Já existe um usuário com esse E-mail, Clube ID e/ou Número do Telefone, verifique os dados e tente
              novamente.</small>
          </div>
          <div class="form-group mt-4 mb-0 custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" name="legalCheck" id="legalCheck"
              [(ngModel)]="login.legalCheck" #legalCheck="ngModel"
              [ngClass]="{ 'is-invalid': signupForm.submitted && legalCheck.invalid }" required>
            <label class="custom-control-label text-dark" for="legalCheck">
              Eu li e aceito os
              <a href="https://chippix.notion.site/TERMOS-DE-USO-f041ac87b8a44b6dbe5c188febd87932" target="_blank"
                class="text-dark"><ins>termos de uso</ins></a> e
              <a href="https://chippix.notion.site/POL-TICA-DE-PRIVACIDADE-2f8d2c68858745b6864bbdbab2d9b959"
                target="_blank" class="text-dark"><ins>política de privacidade</ins></a>
              do Chippix
            </label>
            <div class="invalid-feedback">
              <div>Esse campo é obrigatório</div>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>