<div class="off-canvas shadow-sm" *ngIf="isOpen" [ngClass]="{'active' : isOpen}">
	<div class="panel-content">
		<div class="panel-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onBack()">
				<span class="material-symbols-outlined">arrow_back</span>
			</button>
			<div class="item title-loader" *ngIf="isSkeletonLoading">
				<ngx-skeleton-loader [theme]="{'height': '30px', 'width': '300px'}" count="1" appearance="line"
					animation="progress">
				</ngx-skeleton-loader>
			</div>
			<div *ngIf="!mustShowOperationDetails && !isSkeletonLoading">
				<h5 class="panel-title modal-title">Informações da transação</h5>
			</div>
			<div *ngIf="mustShowOperationDetails && !isSkeletonLoading">
				<h5 class="panel-title modal-title">
					Operação {{operation.createdAt | date: 'd/M/yy'}} às {{operation.createdAt | date: 'HH:mm'}}
				</h5>
				<span class="text-muted text-break-all">ID: {{operation.e2EId}}</span>
			</div>
		</div>
		<div class="panel-body">
			<div *ngIf="isSkeletonLoading">
        <p class="mb-0">
          <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '50%'}" count="1" appearance="line"
            animation="progress">
          </ngx-skeleton-loader>
        </p>
        <div class="bg-light pt-3 pl-3 pr-3 text-dark rounded-lg">
          <ngx-skeleton-loader [theme]="{'height': '24px', 'background': '#d7d7d757 no-repeat'}" count="4"
            appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
      </div>
			<ng-container *ngIf="transaction.type === transactionType.WITHDRAW && !mustShowOperationDetails; else transferDetails">
				<div *ngIf="!isSkeletonLoading">
					<app-withdraw-details [transaction]="transaction" [isOpen]="isOpen" [isFinanceComponent]="false"></app-withdraw-details>
				</div>
			</ng-container>
			<ng-template #transferDetails>
				<ng-container *ngIf="!mustShowOperationDetails; else operationDetails">
					<div *ngIf="!isSkeletonLoading">
						<app-transfer-details [transaction]="transaction" [isOpen]="isOpen"></app-transfer-details>
					</div>
				</ng-container>
			</ng-template>
			<ng-template #operationDetails>
				<div *ngIf="!isSkeletonLoading">
					<app-operation-details [isOpen]="isOpen" [operation]="operation" (back)="onBack()"></app-operation-details>
				</div>
			  </ng-template>
		</div>
		<div class="panel-footer">
			<button type="button" class="btn btn-outline-primary" *ngIf="transaction && transaction.receipt && transaction.receipt.pixId"
				(click)="onGenereteCashoutReceipt(transaction.receipt.pixId)">Gerar
				comprovante</button>
			<button type="button" class="btn btn-primary" (click)="openOperationDetails(transaction._id)"
				*ngIf="!mustShowOperationDetails">Ver movimentação</button>
			<button type="button" class="btn btn-primary" (click)="goToTransactionsList()"
				*ngIf="mustShowOperationDetails">Ver todas</button>
		</div>
	</div>