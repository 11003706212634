<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <div class="d-flex align-content-start flex-column">
          <h5 class="font-weight-bold m-0">Efetuar resgate</h5>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body confirm-subtitle p-4">
        <label class="text-muted">Valor para resgate</label>
        <div class="input-group mb-2 mr-sm-2 mr-4">
          <div class="input-group-prepend">
            <div class="input-group-text">R$</div>
          </div>
          <input type="tel" name="price" id="price" class="form-control" [(ngModel)]="amount" name="currency"
            currencyMask />
        </div>
        <ng-container *ngIf="amount >= 5"> <!-- TODO: REFACT THIS AFTER FEE CHANGE  -->
          <label class="text-muted small font-weight-bold">Resumo do resgate</label>
          <div class="text-muted small">
            <div *ngFor="let item of getTransactionSummary()"
              class="text-muted d-flex justify-content-between align-items-center">
              <span [ngClass]="{
                          'font-weight-bold': item.label === 'Custo total da transação' || item.label === 'Valor que você recebe',
                          'text-dark': item.label === 'Custo total da transação',
                          'text-success': item.label === 'Valor que você recebe'
                        }">{{ item.label }}</span>
          
              <span [ngClass]="{
                          'text-danger': item.isNegative,
                          'text-dark': item.label === 'Custo total da transação',
                          'font-weight-bold': item.label === 'Custo total da transação' || item.label === 'Valor que você recebe',
                          'text-success': item.label === 'Valor que você recebe'
                        }">
                {{ item.value | currency:'BRL':'symbol':'1.2-2' }}
              </span>
            </div>
          </div>
        </ng-container>
        <div class="bg-light px-4 py-3 mt-4 text-dark rounded-lg">
          <div class="">
            <b>Nome:</b>
            {{ finance?.fullName }}
          </div>
          <div class="text-sm">
            <b>Documento:</b>
            {{ formatDoc(finance.doc) }}
          </div>
          <div class="" *ngIf="finance?.pixKey">
            <b>Chave PIX:</b>
            {{ finance?.pixKey }}
          </div>
          <div class="" *ngIf="finance?.pixKeyType">
            <b>Tipo de chave:</b>
            {{ pixKeyTypeNamed[finance.pixKeyType] }}
          </div>
        </div>
        <div class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
          <i class="bi bi-info-circle-fill mr-3"></i>
          <small>Nós cobramos uma taxa de R$1,50 por resgate.</small>
        </div>
      </div>
      <div class="modal-footer bg-light border-light d-flex justify-content-right">
        <button type="button" class="btn btn-outline-white border-primary text-primary" (click)="onCloseModal()">
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>
