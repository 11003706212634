import { NgModule } from "@angular/core";
import { ActionsComponent } from "./actions.component";
import { CommonModule } from "@angular/common";
import { DoubleCheckModalComponent } from "src/app/modals/double-check-modal/double-check-modal.component";
import { DoubleCheckModalModule } from "src/app/modals/double-check-modal/double-check-modal.module";

@NgModule({
  declarations: [
    ActionsComponent
  ],
  exports: [ActionsComponent],
  imports: [
    CommonModule,
    DoubleCheckModalModule
  ]
})
export class ActionsModule { }