<app-club-financials-modal [data]="fileType" #clubFinancialsModalComponent (eventClose)="closeClubFinancialsModal()"></app-club-financials-modal>
<div class="import-area">
  <div class="h4 font-weight-bold mb-0">Fechamento de contas</div>
  <div class="input-group import">
    <i class="bi bi-file-earmark-arrow-up"></i>
    <input type="file" class="input-area" id="inputFile" accept=".xlsx" (change)="onFileChange($event)" />
    <label class="input-label-import" for="inputFile">Importar</label>
  </div>
</div>
<div class="border border-0 bg-white">
  <div class="form-inline p-3 m-0 d-flex justify-content-between">
    <input id="searchPlayersInput" type="search" class="form-control" aria-label="Amount (to the nearest dollar)"
      placeholder="Buscar..." (input)="onSearchChange(criteria)" [(ngModel)]="criteria.searchTerm" (focus)="hasFocus = true" (blur)="hasFocus = false" />
  </div>
  <ng-container *ngIf="!isSkeletonLoading; else showSkeleton">
    <div *ngIf="rakebackList.length > 0; else isEmpty" class="table-responsive table table-striped">
      <table class="table table-borderless mb-0">
        <thead class="border-top border-bottom border-light">
          <tr class="text-muted">
            <th scope="col" class="w-30">Nome</th>
            <th scope="col">Status</th>
            <th scope="col">Progresso</th>
            <th scope="col" class="text-center">Criação</th>
            <th scope="col" class="text-center w-25">Período</th>
            <th scope="col" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-top border-bottom table-d1" *ngFor="let item of rakebackList">
            <td class="align-middle w-30">
              <ngb-highlight [result]="item?.fileName" [term]="criteria.searchTerm"></ngb-highlight>
            </td>
            <td [ngSwitch]="item?.status" class="font-weight-light align-middle">
              <span class="badge badge-pill badge-warning font-weight-normal"
                *ngSwitchCase='clubFinancialStatus.STARTED'>{{clubFinancialStatusNamed[clubFinancialStatus.STARTED]}}</span>
              <span class="badge badge-pill badge-success font-weight-normal"
                *ngSwitchCase='clubFinancialStatus.COMPLETED'>{{clubFinancialStatusNamed[clubFinancialStatus.COMPLETED]}}</span>
              <span class="badge badge-pill badge-info font-weight-normal"
                *ngSwitchCase='clubFinancialStatus.NOT_STARTED'>{{clubFinancialStatusNamed[clubFinancialStatus.NOT_STARTED]}}</span>
              <span class="badge badge-pill badge-warning font-weight-normal"
                *ngSwitchCase='clubFinancialStatus.COMPLETED_WITH_ERRORS'>{{clubFinancialStatusNamed[clubFinancialStatus.COMPLETED_WITH_ERRORS]}}</span>
            </td>
            <td class="align-middle">
              <div class="progress-container">
                <ngb-progressbar 
                  type="success" 
                  [max]="item.totalPlayers"
                  [value]="countClubFinancialsProgress(item.sentCountError, item.sentCountSuccess)">
                  <b>{{ countClubFinancialsProgress(item.sentCountError, item.sentCountSuccess) }} / {{ item.totalPlayers }}</b>
                </ngb-progressbar>
                <span *ngIf="countClubFinancialsProgress(item.sentCountError, item.sentCountSuccess) === 0" class="progress-text">
                  {{ countClubFinancialsProgress(item.sentCountError, item.sentCountSuccess) }} / {{ item.totalPlayers }}
                </span>
              </div>
            </td>
            <td class="align-middle text-center">{{ item?.createdAt | date: 'dd/MM/yyyy' }}</td>
            <td class="align-middle text-center">
              {{ item?.dateStart | date: 'dd/MM/yyyy' }}
              <span class="text-muted"> - </span>
              {{ item?.dateEnd | date: 'dd/MM/yyyy' }}
            </td>
            <td class="text-center align-middle w-auto">
              <a href="javascript:void(0)" class="text-primary font-weight-bold"
                (click)="openResumeSettings(item._id, item.fileName, item.status, item.totalPlayers)">Ver Detalhes</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-pag mt-4 px-4 d-flex justify-content-between">
        <p class="m-0 d-flex align-items-center">
          <small class="font-weight-light">
            Exibindo
            <span class="font-weight-normal">{{criteria.page}}</span>
            de
            <span class="font-weight-normal">{{criteria.pages}}</span>
            página
          </small>
        </p>
        <ngb-pagination class="mt-3 mx-3" [collectionSize]="criteria.total" [(page)]="criteria.page"
          [pageSize]="criteria.limit" [maxSize]="4" [boundaryLinks]="true" (pageChange)="jumpPagination($event)">
        </ngb-pagination>
      </div>
    </div>
    <ng-template #isEmpty>
      <div
        *ngIf="criteria.searchTerm"
        class="bg-white alert alert-light text-center mx-auto mb-0 d-flex align-items-center justify-content-center flex-column"
        role="alert">
        <span class="font-weight-bold">Não encontramos fechamentos com esta busca.</span>
        <span class="text-muted">Verifique se o nome do fechamento foi digitado corretamente e tente novamente.</span>
      </div>
      <div
        *ngIf="!criteria.searchTerm"
        class="bg-white alert alert-light text-center mx-auto mb-0 d-flex align-items-center justify-content-center flex-column pt-3 pb-4"
        role="alert">
        <span class="font-weight-bold">Não houve fechamentos até o momento.</span>
        <span class="text-muted">Para realizar um fechamento, clique no botão
          <b class="text-primary">Importar</b></span>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #showSkeleton>
    <div class="table-responsive table table-striped table-loader">
      <table class="table table-borderless mb-0">
        <thead class="border-top border-bottom border-light">
          <tr>
            <th scope="col" class="column-name w-30">
              <ngx-skeleton-loader
                [theme]="{'width': '60%', 'background': '#e5e4e4 no-repeat'}"
                appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </th>
            <th scope="col" class="column-status align-middle">
              <ngx-skeleton-loader
                [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </th>
            <th scope="col" class="column-progress align-middle text-center loader-center">
              <ngx-skeleton-loader
                [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </th>
            <th scope="col" class="column-createdAt align-middle text-center loader-center">
              <ngx-skeleton-loader
                [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </th>
            <th scope="col" class="column-period align-middle text-center loader-center">
              <ngx-skeleton-loader
                [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </th>
            <th scope="col" class="column-resume align-middle text-center loader-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-top border-bottom table-d1" *ngFor="let player of [0,0,0]">
            <td class="column-name align-middle w-30">
              <ngx-skeleton-loader [theme]="{'width': '85%', 'background': '#e5e4e4 no-repeat'}" appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </td>
            <td class="column-status align-middle">
              <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}" appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </td>
            <td class="column-progress align-middle text-center loader-center">
              <ngx-skeleton-loader
                [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line"
                animation="progress">
              </ngx-skeleton-loader>
            </td>
            <td class="column-createdAt align-middle text-center loader-center">
              <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line" animation="progress">
              </ngx-skeleton-loader>
            </td>
            <td class="column-period align-middle text-center loader-center">
              <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line" animation="progress">
              </ngx-skeleton-loader>
            </td>
            <td class="column-button align-middle text-center loader-center">
              <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}"
                appearance="line" animation="progress">
              </ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-pag w-100 align-content-center">
      <p class="m-0 w-25">
        <ngx-skeleton-loader
          [theme]="{'width': '100%', 'background': '#e5e4e4 no-repeat'}"
          appearance="line"
          animation="progress">
        </ngx-skeleton-loader>
      </p>
      <div class="text-right w-75">
        <ngx-skeleton-loader
          [theme]="{'height': '36px', 'width': '35%', 'background': '#e5e4e4 no-repeat'}"
          appearance="line"
          animation="progress">
        </ngx-skeleton-loader>
      </div>
    </div>
  </ng-template>
</div>