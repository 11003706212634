import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlComponent } from './control.component';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { customCurrencyMaskConfig } from 'src/app/config/custom-currency-mask.config';
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false
    }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
  ],
  declarations: [
    ControlComponent
  ],
  exports: [
    ControlComponent
  ],
  bootstrap: [
    ControlComponent
  ]
})
export class ControlModule { }
