import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { PlayerThermometerComponent } from './player-thermometer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GameAccountsListComponent } from './components/game-accounts-list/game-accounts-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask';
import { CustomizeWithdrawModule } from 'src/app/components/customize-withdraw/customize-withdraw.module';
import { ActionsModule } from 'src/app/components/actions/actions.module';
import { UserInfoModalModule } from 'src/app/modals/user-info-modal/user-info-modal.module';
import { NgbdSortableHeaderModule } from 'src/app/components/ngbd-sortable-header/ngbd-sortable-header.module';
import { PushNotificationModule } from 'src/app/modals/push-notification/push-notification.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    PlayerThermometerComponent,
    SlidePanelComponent,
    GameAccountsListComponent
  ],
  exports: [PlayerThermometerComponent],
  imports: [
    CommonModule,
    NgbModule,
    NgxSkeletonLoaderModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    CustomizeWithdrawModule,
    UserInfoModalModule,
    ActionsModule,
    NgbdSortableHeaderModule,
    PushNotificationModule,
    TooltipModule.forRoot(),
  ]
})
export class PlayerThermometerModule { }
