import { SocketIoService } from './../shared/socket-io.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import {
  Observable,
  interval as observableInterval,
  timer as observableTimer,
  throwError,
} from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { BASE_URL, REFRESH_TOKEN_URL } from '../config/settings';
import { Session, SessionData, Slot } from './auth.model';
import { Socket } from 'ngx-socket-io';
import { NotificationService } from '../shared/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthGuard implements CanActivate {
  private TOKEN = 'token';
  private REFRESH_TOKEN = 'refreshToken';
  private SLOT_TOKENS = 'slotTokens';
  private MAIN_TOKEN = 'mainToken';
  private CLUB_ID = '_id';
  private NAME = 'name';
  private refreshSubscription: any;
  private jwtHelper;
  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private socket: Socket,
    private notificationService: NotificationService,
    private socketIoService: SocketIoService,
    private spinner: NgxSpinnerService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  connectFromSocketIo(): void {
    this.socket.disconnect();
  }

  setSessionData(sessionData: SessionData): void {
    this.setToken(sessionData.token);
    this.setClubName(sessionData.name);
    this.setClubId(sessionData._id);
    this.setRefreshToken(sessionData.refreshToken);
    this.setMainToken(sessionData.token);
  }

  changeSlot(slot: Slot) {
    this.setToken(slot.token);
    this.setClubName(slot.name);
    this.setClubId(slot._id);
  }

  /**
   * This method is responsible to check if user is active
   * @param route activate route
   * @param state route state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  /**
   * This method is responsible to check if token is valid
   * @param url url to redirect
   */
  checkLogin(url: string): boolean {
    if (this.validateToken()) {
      this.socketIoService.connectToSocketIo();
      return true;
    }
    this.logout(url);
    return false;
  }

  /**
   * This method is responsible to validate token
   */
  validateToken(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  /**
   * This method is responsible to get token
   */
  public getToken(): string {
    if (!this.cookieService.check(this.TOKEN)) {
      return '';
    }
    return JSON.parse(this.cookieService.get(this.TOKEN));
  }

  /**
   * This method is responsible to get token
   */
  public refreshToken(): string {
    const rToken = JSON.parse(this.cookieService.get(this.REFRESH_TOKEN));
    return rToken;
  }

  /**
   * This method is responsible to set token
   * @param token token to validate
   */
  public setToken(token: string): void {
    return this.cookieService.set(this.TOKEN, JSON.stringify(token));
  }

  /**
   * This method is responsible to set club id
   * @param clubId club id
   */
  public setClubId(clubId: string): void {
    return this.cookieService.set(this.CLUB_ID, JSON.stringify(clubId));
  }

  /**
   * This method is responsible to set club id
   * @param name club name
   */
  public setClubName(name: string): void {
    return this.cookieService.set(this.NAME, JSON.stringify(name));
  }

  /**
   * This method is responsible to return club id from cookie
   */
  public getClubId(): string {
    return JSON.parse(this.cookieService.get(this.CLUB_ID));
  }

  /**
   * This method is responsible to set refresh token
   * @param token token to validate
   */
  public setRefreshToken(rToken: string): void {
    return this.cookieService.set(this.REFRESH_TOKEN, JSON.stringify(rToken));
  }

  /**
   * This method is responsible to set all club slots
   * @param token token to validate
   */
  public setClubSlots(slotTokens: [Slot]): void {
    return this.cookieService.set(this.SLOT_TOKENS, JSON.stringify(slotTokens));
  }

  /**
   * This method is responsible to set main club token
   * @param token token to validate
   */
  public setMainToken(rToken: string): void {
    return this.cookieService.set(this.MAIN_TOKEN, JSON.stringify(rToken));
  }

  /**
   * This method is responsible to set main club token
   * @param token token to validate
   */
  public getMainToken(): string {
    return JSON.parse(this.cookieService.get(this.MAIN_TOKEN));
  }

  /**
   * This method is responsible to get refresh token
   * @param token token to validate
   */
  public getRefreshToken(): string {
    return JSON.parse(this.cookieService.get(this.REFRESH_TOKEN));
  }

  /**
   * This method is responsible to remove token from user
   */
  public removeToken(): void {
    localStorage.removeItem(this.TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  /**
   * This method is responsible to get values of token
   */
  getTokenData(): string {
    const token: string = this.getToken();
    const data = this.jwtHelper.decodeToken(token);
    return data;
  }

  /**
   * Get delay time after that a new token
   * is generated
   * @param {*} token jwt token generated
   */
  public getDelayTokenTime(token: string): number {
    const jwtIat = this.jwtHelper.decodeToken(token).iat;
    const jwtExp = this.jwtHelper.decodeToken(token).exp;
    const iat = new Date(0);
    const exp = new Date(0);
    let delay = exp.setUTCSeconds(jwtExp) - iat.setUTCSeconds(jwtIat);
    if (delay - 1000 <= 0) {
      delay = 1;
    }
    return delay;
  }

  /**
   * The delay to generate in this case is the difference
   * between the current time and expiry time.
   * @param {*} token jwt token generated
   */
  public getDelayCurrentTime(token: string): number {
    const now: number = new Date().valueOf();
    const jwtExp: number = this.jwtHelper.decodeToken(token).exp;
    const exp: Date = new Date(0);
    exp.setUTCSeconds(jwtExp);
    // Token will be genereted 5 minutes before expiration
    let delay: number = exp.valueOf() - now;
    if (delay - 30000 <= 0) {
      delay = 10;
    } else {
      delay -= 30000;
    }
    return delay;
  }

  /**
   * If the user is authenticated, use the token stream
   * provided by angular2-jwt and flatMap the token
   * @param {*} token jwt token generated
   */
  public scheduleRefresh(token: string): void {
    const delay = this.getDelayTokenTime(token);
    const source = observableInterval(delay);
    this.refreshSubscription = source.subscribe(() => {
      this.getNewJwt();
    });
  }

  /**
   * If the user is authenticated, use the token stream
   * provided by angular2-jwt and flatMap the token
   * @param {*} token jwt token generated
   */
  public startupTokenRefresh(token: string): void {
    const delay = this.getDelayCurrentTime(token);
    const source = observableTimer(delay);
    this.refreshSubscription = source.subscribe(() => {
      this.getNewJwt();
      this.scheduleRefresh(token);
    });
  }

  /**
   * Method to generate new token with refresh token
   */
  public getNewJwt(): Observable<any> {
    return this.refreshTokenRequest(this.getRefreshToken());
  }

  /**
   * Method to unsubscribe fromt the refresh
   */
  public unscheduleRefresh(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  /**
   * Method to do a refresh token request
   * @param {*} refreshToken refresh token to generate new token
   */
  private refreshTokenRequest(rToken: string): Observable<any> {
    const tokenUrl = REFRESH_TOKEN_URL;
    this.spinner.show();
    return this.http.post(tokenUrl, { refreshToken: rToken }).pipe(
      tap((res: any) => {
        this.spinner.hide();
        // If the API returned a successful response, new token and
        // rToken will be generated
        if (res && res.data) {
          const response = res.data;
          const slots: [Slot] = response._slots;
          const mainSlot: Slot = {
            token: response.token,
            name: response.name,
            _id: response._id,
          };
          slots.push(mainSlot);
          this.setClubSlots(slots);
          this.saveSessionData(response);
        } else {
          this.logout();
        }
      }),
      catchError((error) => {
        this.spinner.hide();
        this.logout();
        return throwError(error);
      })
    );
  }

  /**
   * Method to save tokens on storage
   * @param {*} data data with user data to save on storage
   */
  private saveSessionData(data: any): void {
    this.setToken(data.token);
    this.setClubId(data._id);
    this.setRefreshToken(data.refreshToken);
  }

  /**
   * Clear token and refreshToken the storage
   */
  private clearStorage(): void {
    this.cookieService.deleteAll();
  }

  /**
   * Method to logout user
   */
  public logout(url?: string): void {
    this.unscheduleRefresh();
    this.clearStorage();
    this.connectFromSocketIo();
    this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
  }
}
