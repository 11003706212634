import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClubFinancialsService } from 'src/app/pages/club-financials/club-financials.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-club-percents-modal',
  templateUrl: './club-percents-modal.component.html',
  styleUrls: ['./club-percents-modal.component.scss']
})
export class ClubPercentsModalComponent implements OnInit {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;
  
  @Output() eventClose: EventEmitter<any> = new EventEmitter();

  percent: number = 0;
  rakebackPlayers: any = [];
  subscription: Subject<void> = new Subject();
  errorMessage: string | null = null;

  constructor(
    private notificationService: NotificationService,
    public spinner: NgxSpinnerService,
    private clubFinancialService: ClubFinancialsService,
  ) { }

  ngOnInit(): void {
  }

  onPercentChange(event: any): void {
    const value = event.target.value;
    const numValue = Number(value); 

    if (isNaN(numValue) || numValue < 1 || numValue > 100) {
        this.errorMessage = 'Valor inválido, verifique se está no intervalo correto de 1 a 100.';
    } else {
        this.percent = numValue;
        this.errorMessage = null;
    }
  }

  onSubmit(): void {
    if (this.percent < 1 || this.percent > 100 || this.percent === null){
      this.notificationService.showError('Não foi possível realizar esta ação, verifique se od valores estão corretos', 'Porcentagem inválida!');
    } else {
      this.errorMessage = null;
      this.spinner.show();
      const rakebackPlayerList = this.rakebackPlayers.map((rakebackPlayer: any) => {
        return {
          _gameAccount: rakebackPlayer._gameAccount,
          rakebackPercent: this.percent
        }
      })
      this.clubFinancialService.patchGameAccountRakebackPercent(rakebackPlayerList)
        .pipe(
          takeUntil(this.subscription.asObservable()),
          finalize(() => {
            this.spinner.hide();
          }))
        .subscribe(
          (response: any) => {
            // to do
            console.log(response)
          },
          (error: any) => {
            // to do
            console.log(error);
          }
        );
      this.notificationService.showSuccess('A porcentagem em massa foi aplicada', 'Porcentagem aplicada com sucesso!');
      this.closeModal();
    }
  }

  /**
 * Open modal
 */
  public openModal(rakebackPlayers: any): void {
    this.rakebackPlayers = rakebackPlayers.filter((rakebackPlayer: any) => rakebackPlayer.playerPercentage === null)
    this.autoShownModal.show()
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.autoShownModal.hide();
    this.eventClose.emit();
  }

}
