import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { TransferModalComponent } from 'src/app/modals/transfer-modal/transfer-modal.component';
import { UserInfoModalComponent } from 'src/app/modals/user-info-modal/user-info-modal.component';
import { WithdrawModalComponent } from 'src/app/modals/withdraw-modal/withdraw-modal.component';
import { TransactionStatus, TransactionStatusNamed, TransactionType, TransactionTypeNamed } from 'src/app/shared/transaction.enum';
import { Criteria, Pageable, Transaction } from '../transaction.model';
import { TransactionService } from '../transaction.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('transferModal', { static: true }) public transferModal!: TransferModalComponent;
  @ViewChild('withdrawModal', { static: true }) public withdrawModal!: WithdrawModalComponent;
  @ViewChild('userInfoModal', { static: true }) public userInfoModal!: UserInfoModalComponent;

  @Output() transactionSelected = new EventEmitter<any>();
  @Output() actionPerformed = new EventEmitter<any>();

  subscription: Subject<void> = new Subject();
  searchChanged: Subject<Criteria> = new Subject<Criteria>();

  criteria: Criteria = new Criteria();
  transactions: Transaction[] = [];
  filterByType: TransactionStatus = this.transactionStatus.ALL;
  openModal = false;
  transactionId!: string;

  selectedTransaction!: any;

  constructor(
    public service: TransactionService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.searchChanged.pipe(debounceTime(700))
      .subscribe(searchCriteria => this.updateTransactionList(searchCriteria));
  }

  ngOnInit(): void {
    this.updateTransactionList(this.criteria);
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.transactionId = params.transactionId;
      this.openModal = params.openModal;
    });
    if (this.openModal) {
      this.withdrawModal.openModal(this.transactionId);
    }
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.unsubscribe();
  }

  onSearchChange(criteria: Criteria): void {
    this.searchChanged.next(criteria);
  }

  updateTransactionList(criteria: Criteria): any {
    if(!criteria.searchTerm) {
      this.spinner.show();
    }
    this.criteria.listByType = criteria.listByType;
    this.criteria.listByStatus = criteria.listByStatus;
    this.criteria.page = criteria.page;
    this.service.listTransactions(this.criteria).pipe(
      takeUntil(this.subscription.asObservable()),
      finalize(() => {
        this.spinner.hide();
      })
    )
      .subscribe(
        (response: Pageable<Transaction>) => {
          this.transactions = response.data;
          this.criteria.totalDocs = response.totalDocs;
          this.criteria.page = response.page;
          this.criteria.limit = response.limit;
          this.criteria.totalPages = response.totalPages;
        });
  }

  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.updateTransactionList(this.criteria);
  }

  public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

  public get transactionTypeNamed(): typeof TransactionTypeNamed {
    return TransactionTypeNamed;
  }

  onFilterChange(event: TransactionStatus): void {
    this.criteria.listByStatus = event;
    this.updateTransactionList(this.criteria);
  }

  onPaymentRefounded(): void {
    this.updateTransactionList(this.criteria);
  }

  onPaymentWithdrawConfirmed(): void {
    this.updateTransactionList(this.criteria);
  }

  openTransferModal(playerTransaction: Transaction): void {
    this.transferModal.openModal(playerTransaction._id);
  }

  openWithdrawModal(playerTransaction: Transaction): void {
    this.withdrawModal.openModal(playerTransaction._id);
  }

  openUserInfoModal(playerTransaction: Transaction): void {
    this.userInfoModal.openModal(playerTransaction);
  }

  openTransactionSlidePanel(transaction: Transaction) {
    this.selectedTransaction = transaction
    this.selectTransaction(this.selectedTransaction);
  }

  selectTransaction(item: any) {
    this.transactionSelected.emit(item);
  }

  clearSelectedTransaction() {
    this.selectedTransaction = null;
  }
}
