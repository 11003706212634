import { CookieService } from 'ngx-cookie-service';
import { UserInfo } from '../../shared/user-info.model';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Slot } from 'src/app/auth/auth.model';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig], // add NgbDropdownConfig to the component providers
})
export class NavbarComponent implements OnInit {

  userInfo = {} as UserInfo;
  NAME = 'name';
  SLOT_TOKENS = 'slotTokens';
  clubName!: string;
  slots!: [Slot];

  constructor(
    public auth: AuthGuard,
    private route: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private scsd: ActivatedRoute,
    config: NgbDropdownConfig
  ) {
    // config.placement = 'bottom-start';
    // config.autoClose = true;
  }

  ngOnInit(): void {
    this.clubName = this.getClubName();
    this.slots = this.getSlots();
    this.auth.startupTokenRefresh(this.auth.getMainToken());
  }

  doLogout(): void {
    this.auth.logout();
  }

  onSlotSelected(slot: Slot): void {
    this.auth.changeSlot(slot);
    window.location.reload();
  }


  public getClubName(): string {
    return JSON.parse(this.cookieService.get(this.NAME));
  }

  public getSlots(): [Slot] {
    return JSON.parse(this.cookieService.get(this.SLOT_TOKENS));
  }


  getUserInfoCredentials(): void {
    this.authService.getUserInfo().subscribe(
      (userInfo: any) => {
        this.userInfo = userInfo;
        const navigationExtras: NavigationExtras = {
          queryParams: {
            ddd: this.userInfo.data.ddd,
            phoneNumber: this.userInfo.data.phoneNumber,
            insideChangePasswordComponent: true
          }
        };
        this.route.navigate(['', 'inside-change-password'], navigationExtras);
        this.cookieService.set('confirmUser', JSON.stringify(false));
      }, (error) => {
      });
  }

  onChangePassoword(): void {
    this.getUserInfoCredentials();
  }

}
