import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Login } from 'src/app/auth/auth.model';
import { AuthService } from 'src/app/auth/auth.service';
import Constants from 'src/app/shared/constants';
import { NotificationService } from 'src/app/shared/notification.service';
import { SignUpService } from './signup.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  login: Login = {} as Login;
  validCredentials = true;
  validPassword: any = {};

  constructor(
    public passwordService: SignUpService,
    private notificationService: NotificationService,
    private service: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSignup(): void {
    this.separatePhoneNumber();
    this.login.role = 'CLUB';
    this.service.registerRequest(this.login)
      .subscribe(() => {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            email: this.login.email,
            password: this.login.password,
            ddd: this.login.ddd,
            phoneNumber: this.login.phoneNumber
          }
        };
        this.notificationService.showSuccess('Conta criada com sucesso!', 'Cadastro');
        // this.router.navigate(['confirm'], navigationExtras);
        this.router.navigate(['wait-confirm'], navigationExtras);
      },
        (error) => {
          switch (error.status) {
            case 409:
              this.validCredentials = false;
              if (error.error.message.includes('email')) {
                this.notificationService.showError('E-mail preenchido já está em uso', '');
              } else if (error.error.message.includes('gameClubId')) {
                this.notificationService.showError('Club ID preenchido já está em uso', '');
              } else {
                this.notificationService.showError('Número do telefone preenchido já está em uso', '');
              }
              break;
            case 422:
              this.notificationService.showError('Número do telefone inexistente', '');
              break;
            default:
              this.notificationService.showError('Não foi possível criar a conta', '');
              break;
          }
        });
  }

  onSubmit(): void {
    this.onSignup();
  }

  separatePhoneNumber(): void {
    this.login.ddd = parseInt(this.login.fullPhoneNumber.substring(0, 2), 10);
    this.login.phoneNumber = parseInt(this.login.fullPhoneNumber.substring(2, 11), 10);
  }

  onPasswordChange(pass?: string): void {
    this.validPassword = this.passwordService.validatePassword(pass)
  }
}
