import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { ClaimbackPaymentType, ClaimbackPaymentTypeNamed } from "src/app/shared/control.enum";
import { NotificationService } from "src/app/shared/notification.service";
import { TransactionStatus, TransactionStatusNamed, AutoClaimbackErrorCode } from "src/app/shared/transaction.enum";
import { Transaction, TransactionResponse } from "../../transaction.model";
import { TransactionService } from "../../transaction.service";

@Component({
	selector: 'app-withdraw-details',
	templateUrl: './withdraw-details.component.html',
	styleUrls: ['./withdraw-details.component.scss']
})
export class WithdrawDetailsComponent implements OnInit, OnChanges {

	@Input('isOpen') isOpen: boolean = false;
	@Input('isFinanceComponent') isFinanceComponent: boolean = false;
	@Input('transaction') transaction: any;

	@Output() back = new EventEmitter<void>();

	title!: string;
	name!: string;
	email!: string;
	ddd!: string;
	phoneNumber!: string;
	playerAccountId!: string;
	pixAccountName!: string;
	pixKey!: string;
	data!: string;
	chips!: number;
	amount!: number;
	status!: TransactionStatus;
	errorCode!: number;

	isManualModal!: boolean;

	public get transactionStatus(): typeof TransactionStatus {
		return TransactionStatus;
	}

	public get transactionStatusNamed(): typeof TransactionStatusNamed {
		return TransactionStatusNamed;
	}

	public get claimbackPaymentTypeEnum(): typeof ClaimbackPaymentType {
		return ClaimbackPaymentType;
	}

	public get claimbackPaymentTypeNamedEnum(): typeof ClaimbackPaymentTypeNamed {
		return ClaimbackPaymentTypeNamed;
	}

	public get autoClaimbackErrorCodeEnum(): typeof AutoClaimbackErrorCode {
		return AutoClaimbackErrorCode;
	}

	isSkeletonLoading: boolean = false;

	constructor(
		public service: TransactionService,
		private notificationService: NotificationService,
		private clipboardService: ClipboardService,
	) { }

	ngOnInit(): void { }

	ngOnChanges(): void {
		if (!this.isOpen) {
			this.isSkeletonLoading = true;
			return;
		}

		let transactionID = this.isFinanceComponent ? this.transaction : this.transaction?._id
		this.getTransaction(transactionID);
	}

	getTransaction(transactionId: string) {
		this.service.getTransactionById(transactionId).subscribe(
			(transactionResponse: TransactionResponse) => {
				this.transaction = transactionResponse.data;
				this.name = this.transaction._user.name;
				this.email = this.transaction._user.email;
				this.ddd = this.transaction._user.ddd;
				this.phoneNumber = this.transaction._user.phoneNumber;
				this.pixAccountName = this.transaction._user.paymentData.pixAccountName;
				this.pixKey = this.transaction._user.paymentData.pixKey;
				this.playerAccountId = this.transaction._gameAccount.playerAccountId;
				this.data = this.transaction.createdAt;
				this.chips = this.transaction.amount;
				this.amount = this.transaction.amount;
				this.status = this.transaction.status;
				this.errorCode = this.transaction.errorCode;

				this.isSkeletonLoading = false;

				if (this.status === this.transactionStatus.WAITING_PAYMENT &&
					(this.transaction.claimbackPaymentType === this.claimbackPaymentTypeEnum.MANUALLY ||
						(this.claimbackPaymentTypeEnum.AUTOMATIC && this.transaction && this.transaction.autoClaimbackErrorCode))) {
					this.isManualModal = true;
				} else {
					this.isManualModal = false;
				}
				return this.transaction;
			}
		);
	}

	goBack() {
		this.back.emit();
	}
}