import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ClubFinancialStatus } from 'src/app/pages/club-financials/club-financials.model';

@Component({
  selector: 'app-club-financials-modal',
  templateUrl: './club-financials-modal.component.html',
  styleUrls: ['./club-financials-modal.component.scss']
})
export class ClubFinancialsModalComponent implements OnInit {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  @Output() eventClose: EventEmitter<any> = new EventEmitter();
  @Input('data') data!: any;

  progress: string = '0%';

  constructor(
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void { }

  fileProgress(rakebackSender: any) {
    let rakebackSendInfo = {
      rakebackSenderId: rakebackSender._id,
      fileName: rakebackSender.fileName,
      status: rakebackSender.status ? rakebackSender.status : ClubFinancialStatus.NOT_STARTED,
      totalPlayers: rakebackSender.totalPlayers
    }
    let index = 0;
    const interval = 30;

    const intervalId = setInterval(() => {
      this.progress = `${index}%`;

      if (index >= 100) {
        clearInterval(intervalId);
        this.notificationService.showSuccess('Fechamento disponível para configuração', 'Arquivo importado com sucesso!');
        this.openSettingsPage(rakebackSendInfo);
      }

      index++;
    }, interval);
  }

  openSettingsPage(rakebackSendData: any) {
    this.router.navigate(['club-financial/settings'], {
      queryParams: {
        clubFinancialsID: rakebackSendData.rakebackSenderId,
        clubFinancialsFilename: rakebackSendData.fileName,
        clubFinancialsStatus: rakebackSendData.status,
        clubFinancialsTotalPlayers: rakebackSendData.totalPlayers,
      }
    });
  }

  /**
   * Open modal
   */
  openModal(rakebackSender: any): void {    
    this.spinner.hide();
    this.autoShownModal.show();
    this.fileProgress(rakebackSender);
  }

  /**
    * Close modal
    */
  closeModal(): void {
    this.autoShownModal.hide();
    this.eventClose.emit();
  }
}
