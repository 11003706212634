import { UserInfoModalModule } from './../../modals/user-info-modal/user-info-modal.module';
import { UserInfoModalComponent } from './../../modals/user-info-modal/user-info-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { QRCodeModule } from 'angularx-qrcode';
import { WithdrawModalComponent } from './../../modals/withdraw-modal/withdraw-modal.component';
import { TransferModalComponent } from './../../modals/transfer-modal/transfer-modal.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionComponent } from './transaction.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TransactionSlidePanelComponent } from './components/transaction-slide-panel/transaction-slide-panel.component';
import { TransferDetailsModule } from './components/transfer-details/transfer-details.module';
import { WithdrawDetailsModule } from './components/withdraw-details/withdraw-details.module';
import { OperationDetailsModule } from '../finance/components/operation-details/operation-details.module';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
    QRCodeModule,
    ClipboardModule,
    NgxMaskModule,
    RouterModule,
    UserInfoModalModule,
    NgxSkeletonLoaderModule,
    TransferDetailsModule,
    WithdrawDetailsModule,
    OperationDetailsModule
  ],
  declarations: [
    TransactionListComponent,
    TransactionComponent,
    TransferModalComponent,
    WithdrawModalComponent,
    TransactionSlidePanelComponent,
  ],
  exports: [
    TransactionComponent
  ],
  bootstrap: [
    TransactionComponent
  ]
})
export class TransactionModule { }
