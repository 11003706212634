<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">
          Informação da transação
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body confirm-subtitle p-4">
        <h5>Dados do Jogador</h5>
        <div class="bg-light px-4 py-3 text-dark rounded-lg">
          <div class="">
            <b>Nome:</b>
            {{name}}
          </div>
          <div>
            <b>ID do jogador:</b>
            {{playerAccountId}}
          </div>
          <div>
            <b>E-mail:</b>
            {{email}}
          </div>
          <div>
            <b>Número do telefone:</b>
            {{ddd + phoneNumber | mask: '(00) 00000-0000'}}
          </div>
        </div>
        <h5 class="mt-4">Dados da Compra</h5>
        <div class="bg-light px-4 py-3 text-dark rounded-lg">
          <div class="">
            <b>Fichas:</b>
            {{amount / 100 | number}}
          </div>
          <div class="">
            <b>Valor:</b>
            {{amount / 100 | currency:'BRL':'symbol':'1.2-2'}}
          </div>
          <div class="">
            <b>Data:</b>
            {{data | date: 'd/M/yy'}}
          </div>
          <div class="">
            <b>Horário:</b>
            {{data | date: 'HH:mm'}}
          </div>
          <div [ngSwitch]="status">
            <b>Status:</b>
            <span class="badge badge-pill badge-info font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.PROCESSING'>{{transactionStatusNamed[transactionStatus.PROCESSING]}}</span>
            <span class="badge badge-pill badge-success font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.DONE'>{{transactionStatusNamed[transactionStatus.DONE]}}</span>
            <span class="badge badge-pill badge-dark font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.REFUNDED'>{{transactionStatusNamed[transactionStatus.REFUNDED]}}</span>
            <span class="badge badge-pill badge-warning text-wrap font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.WAITING_PAYMENT'>{{transactionStatusNamed[transactionStatus.WAITING_PAYMENT]}}</span>
            <span class="badge badge-pill badge-success font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.PAID'>{{transactionStatusNamed[transactionStatus.PAID]}}</span>
            <span class="badge badge-pill badge-danger text-wrap font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.ERROR'>{{transactionStatusNamed[transactionStatus.ERROR]}}</span>
            <span class="badge badge-pill badge-info text-wrap font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.WITHDRAWING_CHIPS'>{{transactionStatusNamed[transactionStatus.WITHDRAWING_CHIPS]}}</span>
            <span class="badge badge-pill badge-info text-wrap font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.SENDING_CHIPS'>{{transactionStatusNamed[transactionStatus.SENDING_CHIPS]}}</span>
            <span class="badge badge-pill badge-warning text-wrap font-weight-normal ml-2"
              *ngSwitchCase='transactionStatus.NOT_PAID'>{{transactionStatusNamed[transactionStatus.NOT_PAID]}}</span>
          </div>
        </div>
        <div *ngIf="status === transactionStatus.ERROR && errorCode" [ngSwitch]="errorCode"
          class="rounded-0 border-0 alert alert-danger mt-4 mb-0 d-flex align-items-center" role="alert">
          <i class="bi bi-x-circle-fill mr-3"></i>
          <small *ngSwitchCase="107">Esse jogador não pertence mais ao clube.</small>
          <small *ngSwitchCase="141">
            <p class="m-0">
              Não foi possível concluir a transação pois
              <strong>
                a conta Gestora do Chippix não possui saldo suficiente.
              </strong>
            </p>
            <p class="m-0">
              Por favor, sempre averigue se a conta Gestor está com fichas suficientes para que este erro não volte a
              ocorrer.
            </p>
            <p class="m-0">
              Confira se as fichas foram enviadas pelo Chippix antes de realizar o envio manual para o jogador.
              <strong>
                Não nos responsabilizamos pelo envio realizado manualmente.
              </strong>
            </p>
          </small>
          <small *ngSwitchCase="143">
            <p class="m-0">
              Não conseguimos confirmar se as fichas foram enviadas.
            </p>
            <p class="m-0">
              Confira se as fichas foram enviadas pelo Chippix antes de realizar o envio manual para o jogador.
              <strong>
                Não nos responsabilizamos pelo envio realizado manualmente.
              </strong>
            </p>
          </small>
          <small *ngSwitchDefault>
            <p class="m-0">
              Este erro não é comum. Comunique a equipe Chippix sobre este erro:
              {{errorCode}}.
            </p>
          </small>
        </div>
        <!-- <div *ngIf="status === transactionStatus.DONE"
          class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
          <i class="bi bi-info-circle-fill mr-3"></i>
          <small>
            Após <strong>Reembolsar transação</strong> o valor será reembolsado ao jogador. Porém as fichas terão de
            ser removidas manualmente caso necessário.
          </small>
        </div> -->
      </div>
      <!-- <div class="modal-footer bg-light border-light d-flex" *ngIf="status === transactionStatus.DONE">
        <button type="button" class="btn btn-primary" (click)="refundTransaction()">Reembolsar transação</button>
      </div> -->
    </div>
  </div>
</div>