import { FinanceAddFundsModalComponent } from './../../modals/finance-add-funds-modal/finance-add-funds-modal.component';
import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbTypeahead,
} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import Constants from 'src/app/shared/constants';
import {
  AccounTypeNamed,
  DropdownPeriodNamed,
  OperationPurpose,
  OperationPurposeNamed,
  OperationStatusNamed,
  OperationType,
  OperationTypeNamed,
} from 'src/app/shared/finance-account-type.enum';
import { NotificationService } from 'src/app/shared/notification.service';
import * as XLSX from 'xlsx';
import { FinanceWithdrawModalComponent } from './../../modals/finance-withdraw-modal/finance-withdraw-modal.component';
import {
  PixKeyType,
  DropdownPeriod,
  OperationStatus,
} from './../../shared/finance-account-type.enum';
import * as models from './finance.model';
import { Criteria, Operation, DatePicker } from './finance.model';
import { FinanceService } from './finance.service';
import { DateTime } from 'luxon';
import { TransactionService } from 'src/app/pages/transactions/transaction.service';
import { CashoutReceipt } from './../../shared/cashout-receipt.model';
import { OperationModalComponent } from 'src/app/modals/operation-modal/operation-modal.component';
import { FinanceSlidePanelComponent } from './components/finance-slide-panel/finance-slide-panel.component';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
})
export class FinanceComponent implements OnInit {

  @ViewChild('financeSlidePanel') public financeSlidePanel!: FinanceSlidePanelComponent;
  @ViewChild('financeWithdrawModal', { static: true })
  public financeWithdrawModal!: FinanceWithdrawModalComponent;
  @ViewChild('financeAddFundsModal', { static: true })
  public financeAddFundsModal!: FinanceAddFundsModalComponent;
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;

  @Output() createNewOrder = new EventEmitter();

  @ViewChild('operationModal', { static: true })

  public operationModal!: OperationModalComponent;

  amount!: number;

  finance: models.FinanceAccount = {} as models.FinanceAccount;
  createFinance: models.CreateFinanceAccount =
    {} as models.CreateFinanceAccount;
  updateFinance: models.UpdateFinanceAccount =
    {} as models.UpdateFinanceAccount;
  currentFinanceAccount: models.UpdateFinanceAccount =
    {} as models.UpdateFinanceAccount;
  bankAccount: models.BankAccount = {} as models.BankAccount;
  balance: models.Balance = new models.Balance();
  financeAccountCreated = true;
  financeAccountUpdated = false;
  whenFinanceAccountIsUpdated = false;

  balanceSkeletonLoading = true;
  operationSkeletonLoading = true;
  operationFlowSkeletonLoading = true;

  activeTab = 1;

  hoveredDate: NgbDate | null = null;

  model!: NgbDateStruct;
  date!: { year: number; month: number };

  fromDate!: NgbDate;
  toDate: NgbDate | null = null;

  subscription: Subject<void> = new Subject();
  criteria: Criteria = new Criteria();
  operationFlow: models.OperationFlow = new models.OperationFlow();
  operationHistory!: models.OperationHistory;
  operations: models.Operation[] = [];
  page = 1;
  pageSize = 15;
  collectionSize!: number;

  datePicker = {} as models.DatePicker;

  selectedPeriod: string = DropdownPeriodNamed[DropdownPeriod.LAST_WEEK];

  isSlidePanelOpen: boolean = false;
  isSkeletonLoading: boolean = false;
  selectedOperation!: any;

  maxSize = 3;
  rotatePagination = true;

  public get dropdownPeriod(): typeof DropdownPeriod {
    return DropdownPeriod;
  }

  public get pixKeyType(): typeof PixKeyType {
    return PixKeyType;
  }

  public get accounTypeNamed(): any {
    return AccounTypeNamed;
  }

  public get operationType(): typeof OperationType {
    return OperationType;
  }

  public get operationTypeNamed(): any {
    return OperationTypeNamed;
  }

  public get operationPurposeNamed(): any {
    return OperationPurposeNamed;
  }

  public get operationStatus(): typeof OperationStatus {
    return OperationStatus;
  }

  public get operationStatusNamed(): any {
    return OperationStatusNamed;
  }

  constructor(
    public service: FinanceService,
    public changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private router: Router,
    private cookieService: CookieService,
    private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    public transactionService: TransactionService,
    private sidebarControlService: SidebarService,
    private ngZone: NgZone
  ) {
    const financeAccountActiveTab = this.cookieService.get(
      'financeAccountActiveTab'
    );
    this.activeTab = financeAccountActiveTab
      ? JSON.parse(financeAccountActiveTab)
      : null;
    const financeAccountCreated = this.cookieService.get(
      'financeAccountCreated'
    );
    this.financeAccountCreated = financeAccountCreated
      ? JSON.parse(financeAccountCreated)
      : null;

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  public ngOnInit(): void {
    this.amount = 0;

    if (!this.financeAccountCreated) {
      this.updateFinance.pixKey = this.pixKeyType.CPF;
    }
    this.getBalance();
    this.onDropdownPeriod(this.dropdownPeriod.LAST_WEEK);
  }

  public onConfirmModal(): void {
    this.getBalance();
    this.onDropdownPeriod(this.dropdownPeriod.LAST_WEEK);
  }

  onTabChange(event: any): void {
    this.activeTab = event
    if (this.activeTab === 2) {
      this.getFinanceAccount();
    }
  }

  openFinanceWithdrawModal(): void {
    this.financeWithdrawModal.openModal();
  }

  openFinanceAddFundsModal(): void {
    this.financeAddFundsModal.openModal(this.amount);
  }

  openOperationModal(operation: any): void {
    this.operationModal.openModal(operation);
  }

  openFinanceSlidePanel(operation: any) {
    if(operation) {
      this.isSlidePanelOpen = true;
      this.isSkeletonLoading = true;
      this.selectedOperation = null; // Reset operation to trigger re-render on UI
      this.selectedOperation = null; // Ensure a reset
      this.ngZone.run(() => {
        this.selectedOperation = operation;
      });
      this.sidebarControlService.toggleSidebarVisibility(false);
    }
  }

  onLoadingComplete() {
    this.isSkeletonLoading = false;
  }

  closeFinanceSlidePanel() {
    this.isSlidePanelOpen = false;
    this.selectedOperation = null;
    this.sidebarControlService.toggleSidebarVisibility(true);
  }

  getBalance(): void {
    this.service.getBalance().subscribe(
      (response: models.BalanceResponse) => {
        this.balanceSkeletonLoading = false;
        this.balance = response.data;
      },
      (error) => {
        this.balanceSkeletonLoading = false;
        this.balance = new models.Balance();
      }
    );
  }

  getFinanceAccount(): void {
    this.service.getFinanceAccount().subscribe(
      (financeAccountResponse: models.FinanceAccountResponse) => {
        this.finance = financeAccountResponse.data;
        this.updateFinance.pixKey = this.finance.pixKey;
        this.updateFinance.pixKeyType =
          this.finance.pixKeyType || this.pixKeyType.CPF;
        this.currentFinanceAccount = { ...this.updateFinance };
      },
      (error) => {
        switch (error.status) {
          case 400:
            if (
              error.error.error_code === Constants.CREATE_FINANCE_ACCOUNT_FIRST
            ) {
              this.notificationService.showWarning(
                'Termine o cadastro para receber as transações e efetuar saques',
                ''
              );
            }
            break;
          default:
            this.notificationService.showError(
              'Não foi possível encontrar seus dados bancários',
              ''
            );
        }
      }
    );
  }

  // TODO: Put it into a service
  private fitToColumn(arrayOfArray: any): any {
    // get maximum character of each column
    return arrayOfArray[0].map((a: any, i: any) => ({
      wch: Math.max(
        ...arrayOfArray.map((a2: any) => (a2[i] ? a2[i].toString().length : 0))
      ),
    }));
  }

  // TODO: Put it into a service
  private exportExcel(
    data: any,
    workSheetColumnNames: any,
    workSheetName: string,
    fileName: string
  ): any {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(data);
    workSheet['!cols'] = this.fitToColumn(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, workSheetName);

    XLSX.writeFile(workBook, fileName); // initiate a file download in browser
  }

  // TODO: Put it into a service
  convertAmountToString(amount: number): string {
    const amountValue = amount.toString().length === 2 ? `0${amount}` : amount;
    return amountValue === 0
      ? '0.00'
      : `${amountValue.toString().slice(0, -2)},${amountValue
          .toString()
          .slice(-2)}`;
  }

  checkValueForGross(operation: any) {
    const amount = operation.amount / 100;
    if (operation.type === OperationType.CASH_OUT) {
      if (operation.purpose === OperationPurpose.WITHDRAW_BALANCE) {
        return amount + 1.5;
      } else if (operation.purpose === OperationPurpose.CLAIMBACK_PAYMENT) {
        return amount + 0.5;
      }
    }
    return amount;
  }

  checkValueForLiquid(operation: any) {
    const amount = operation.amount / 100;
    if (operation.type === OperationType.CASH_IN) {
      return amount - 0.5;
    }
    return amount;
  }

  exportOperations(): void {
    this.service.getListOperations(undefined, this.datePicker).subscribe(
      (operationHistory) => {
        {
          const columnName = [
            'Data',
            'Tipo',
            'Finalidade',
            'Entrada bruta',
            'Saida bruta',
            'Entrada liquida',
            'Saida liquida',
            'Integrante',
            'Taxa da operação',
            'Id Jogador',
            'Id da operação',
            'Id do pagamento',
          ];
          const data = operationHistory.data.map((operation: any) => {
            const amount = this.convertAmountToString(operation.amount);
            let integrant = '';
            if (operation.receiver || operation.payer)
              [
                (integrant = operation.receiver
                  ? operation.receiver.name
                  : operation.payer.name),
              ];

            return [
              formatDate(
                new Date(operation.createdAt),
                'dd-MM-yyyy HH:mm',
                'pt'
              ),
              (OperationTypeNamed as any)[operation.type],
              (OperationPurposeNamed as any)[operation.purpose],
              operation.type === OperationType.CASH_IN
                ? this.checkValueForGross(operation)
                : '-',
              operation.type === OperationType.CASH_OUT
                ? this.checkValueForGross(operation)
                : '-',
              operation.type === OperationType.CASH_IN
                ? this.checkValueForLiquid(operation)
                : '-',
              operation.type === OperationType.CASH_OUT
                ? this.checkValueForLiquid(operation)
                : '-',
              integrant,
              operation.fee / 100,
              operation.playerId,
              operation._id,
              operation.e2EId,
            ];
          });
          data.unshift(columnName);
          let startDate: DateTime | string = DateTime.fromISO(
            this.datePicker.startDate
          );
          startDate = startDate.isValid
            ? startDate.toFormat('dd-MM-yyyy')
            : 'todo_periodo';
          this.exportExcel(
            data,
            columnName,
            'Operações',
            `Chippix_extrato_${startDate}.xlsx`
          );
        }
      },
      (error) => {
        this.notificationService.showError(
          'Erro carregar listagem para geração do relatório',
          ''
        );
      }
    );
  }

  onCreateFinanceAccount(): void {
    this.createFinance.doc = this.finance.doc;
    this.createFinance.fullName = this.finance.fullName;
    this.service.postFinanceAccount(this.createFinance).subscribe(
      () => {
        this.financeAccountCreated = true;
        this.updateFinance.pixKeyType = this.pixKeyType.CPF;
        this.cookieService.set('financeAccountCreated', JSON.stringify(true));
        this.notificationService.showSuccess(
          'Documento atualizado com sucesso!',
          ''
        );
      },
      (error) => {
        this.notificationService.showError(
          'Erro ao atualizar os dados bancários',
          ''
        );
      }
    );
  }

  onPixKeyTypeChange(event: any): any {
    if (event.target.value === this.currentFinanceAccount.pixKeyType) {
      this.updateFinance = { ...this.currentFinanceAccount };
    } else {
      this.updateFinance.pixKey = '';
    }
    if (
      event.target.value === PixKeyType.PHONE &&
      !this.updateFinance.pixKey.includes('55')
    ) {
      this.updateFinance.pixKey = `55${this.updateFinance.pixKey}`;
    }
  }

  onUpdateFinanceAccount(): void {
    this.service.putFinanceAccount(this.updateFinance).subscribe(
      (bankAccountResponse) => {
        this.bankAccount = bankAccountResponse.data.bankData;
        this.financeAccountUpdated = true;
        this.whenFinanceAccountIsUpdated = true;
        this.notificationService.showSuccess(
          `Seus saques serão realizados para a conta ${this.bankAccount.conta}, no banco ${this.bankAccount.shortName}`,
          'Pix atualizado com sucesso!',
        );
      },
      (error) => {
        switch (error.error.error_code) {
          case Constants.INTEGRATION_PAYMENT_ERROR:
            this.notificationService.showError('Chave PIX inválida', '');
            break;
          case Constants.PIX_NOT_VALID:
            this.notificationService.showError(
              'Chave PIX não está vinculada com o Documento cadastrado',
              ''
            );
            break;
          default:
            this.notificationService.showError(
              'Erro ao cadastrar sua chave PIX',
              ''
            );
            break;
        }
      }
    );
  }

  updateOperationList(criteria: Criteria, date?: any): any {
    this.spinner.show();
    this.criteria.page = criteria.page;
    this.service
      .getListOperations(criteria, date)
      .pipe(
        takeUntil(this.subscription.asObservable()),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((response: models.Pageable<Operation>) => {
        this.operationSkeletonLoading = false;
        this.operations = response.data;
        /*      this.operations = response.data.map((operation) => ({
          ...operation,
          showMore: false,
        })); */
        this.criteria.total = response.total;
        this.criteria.page = response.page;
        this.criteria.limit = response.limit;
        this.criteria.pages = response.pages;
        this.spinner.hide();
      });
  }

  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.updateOperationList(this.criteria, this.datePicker);
  }

  getOperationFlow(date?: any): void {
    this.service.getOperationFlow(date).subscribe(
      (response: models.OperationFlowResponse) => {
        this.operationFlowSkeletonLoading = false;
        this.operationFlow = response.data;
      },
      (error) => {
        this.operationFlowSkeletonLoading = false;
        this.operationFlow = new models.OperationFlow();
      }
    );
  }

  convertDateTimeToNgbDate(date: DateTime): NgbDate {
    return new NgbDate(date.year, date.month, date.day);
  }

  onDropdownPeriod(period: string): void {
    this.criteria = new Criteria();
    let currentDate: NgbDate;

    switch (period) {
      case this.dropdownPeriod.ALL_PERIOD:
        this.selectedPeriod =
          DropdownPeriodNamed[this.dropdownPeriod.ALL_PERIOD];
        this.datePicker = new DatePicker();
        this.updateOperationList(this.criteria, undefined);
        this.getOperationFlow();
        break;
      case this.dropdownPeriod.TODAY:
        this.selectedPeriod = DropdownPeriodNamed[this.dropdownPeriod.TODAY];
        currentDate = this.convertDateTimeToNgbDate(DateTime.local());
        this.datePicker.startDate = this.convertToIsoLocalDate(currentDate);
        this.datePicker.endDate = this.addEndOfDayToDate(currentDate);
        this.updateOperationList(this.criteria, this.datePicker);
        this.getOperationFlow(this.datePicker);
        break;
      case this.dropdownPeriod.LAST_WEEK:
        this.selectedPeriod =
          DropdownPeriodNamed[this.dropdownPeriod.LAST_WEEK];
        const lastWeekDate = DateTime.local().minus({ days: 6 });
        currentDate = this.convertDateTimeToNgbDate(DateTime.local());
        this.datePicker.startDate = lastWeekDate.startOf('day').toISO();
        this.datePicker.endDate = undefined;
        this.updateOperationList(this.criteria, this.datePicker);
        this.getOperationFlow(this.datePicker);
        break;
      default:
        break;
    }
  }

  onSpecificDaySelected(event: NgbDate): void {
    this.criteria = new Criteria();
    this.selectedPeriod = this.convertToPtBrFormat(event);
    this.datePicker.startDate = this.convertToIsoLocalDate(event);
    this.datePicker.endDate = this.addEndOfDayToDate(event);

    this.updateOperationList(this.criteria, this.datePicker);
    this.getOperationFlow(this.datePicker);
  }

  onRangeDateSelect(date: NgbDate): void {
    this.criteria = new Criteria();
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.toDate) {
      this.selectedPeriod = `${this.convertToPtBrFormat(
        this.fromDate
      )} - ${this.convertToPtBrFormat(this.toDate)}`;
      this.datePicker.startDate = this.convertToIsoLocalDate(this.fromDate);
      this.datePicker.endDate = this.addEndOfDayToDate(this.toDate);
      this.updateOperationList(this.criteria, this.datePicker);
      this.getOperationFlow(this.datePicker);
    }
  }

  isHovered(date: NgbDate): any {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): any {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): any {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  convertToIsoLocalDate(date: NgbDate): string {
    return DateTime.local(date.year, date.month, date.day).toISO();
  }

  addEndOfDayToDate(date: NgbDate): string {
    return DateTime.local(date.year, date.month, date.day).endOf('day').toISO();
  }

  convertToPtBrFormat(date: NgbDate): string {
    return DateTime.local(date.year, date.month, date.day).toFormat(
      'dd/MM/yyyy'
    );
  }

  onAmountChange(event: any) {}

  onGenereteCashoutReceipt(pixId: string) {
    this.transactionService
      .getCashoutReceipt(pixId)
      .subscribe((cashoutReceipt: Blob) => {
        const fileURL = URL.createObjectURL(cashoutReceipt);

        window.open(fileURL, '_blank');

        const downloadAnchor = document.createElement('a');
        downloadAnchor.href = fileURL;
        downloadAnchor.download = 'Comprovante.pdf';

        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);

        URL.revokeObjectURL(fileURL);
      });
  }

  openDetailsInTransactions(transactionID: string) {
    this.router.navigate(['/transaction'], {
      queryParams: {
        transactionId: transactionID,
        openModal: 'true',
      },
    });
  }
}
