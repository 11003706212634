import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { DoubleCheckModalText, PlayerAction } from "src/app/shared/player.enum";
import { Player } from "src/app/pages/players/player.model";


@Component({
  selector: 'app-customize-withdraw',
  templateUrl: './customize-withdraw.component.html',
  styleUrls: ['./customize-withdraw.component.scss']
})
export class CustomizeWithdrawComponent implements OnInit, OnChanges {

  @Input('item') item!: any;
  @Input('player') player!: any;
  @Input('account') account: any;
  @Input('alertText') alertText!: string;
  @Input('isModal') isModal: boolean = false;
  @Input('withdrawSettings') withdrawSettings: any = {} as any;

  @Output() withdrawSettingsChange = new EventEmitter<any>();
  @Output() back = new EventEmitter<void>();

  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  action!: string;
  id!: string;

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  public get doubleCheckModalText(): typeof DoubleCheckModalText {
    return DoubleCheckModalText;
  }

  public get formattedCurrencyValue(): string {
    return this.withdrawSettings.maxValueToWithdraw.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  constructor() { }

  ngOnInit(): void {
    this.setPlayerData(this.account, this.playerAction.CUSTOMIZE_WITHDRAW);
  }

  ngOnChanges() {
    console.log(this.withdrawSettings)
  }

  goBack() {
    this.back.emit();
  }

  setPlayerData(account: Player, action: string) {
    this.withdrawSettings.playerWithdrawSettings = {
      maxValueToWithdrawActive: account.maxValueToWithdraw >= 0,
      maxWithdrawPerDayActive: account.maxWithdrawPerDay >= 0,
      maxValueToWithdraw: account.maxValueToWithdraw / 100 || 0,
      maxWithdrawPerDay: account.maxWithdrawPerDay || 0,
    };
    this.withdrawSettings.id = account._id;
    this.withdrawSettings.action = action;
    this.id = account._id;
    this.name = account._user?.name;
    this.email = account._user.email;
    this.ddd = account._user.ddd;
    this.phoneNumber = account._user.phoneNumber;
    this.playerAccountId = account.playerAccountId;
    this.action = action
    this.alertText = this.doubleCheckModalText.CUSTOMIZE_WITHDRAW;
  }

  onWithdrawSettingsChange() {
    this.withdrawSettingsChange.emit(this.withdrawSettings);
  }
}