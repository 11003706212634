<div class="invite-wrapper m-3">
  <div class="invite-header">
    <p class="font-weight-bold">Convide mais contas para Chippix</p>
  </div>
  <div class="invite-body">
    <div class="description-wrapper px-2 py-2 invite-info text-muted">
      <p>
        <span class="font-weight-bold">⚠ Atenção!</span> Foram encontrados 
        <span *ngIf="!isLoading" class="total-players">{{criteria.total}}</span>
        <ngx-skeleton-loader *ngIf="isLoading" appearance="line" animation="progress"
          [theme]="{'width': '5%', 'height': '15px', 'margin-bottom': '0', 'background': '#f9e1eb no-repeat'}">
        </ngx-skeleton-loader>
          jogadores não vinculados no seu fechamento, e eles não poderão receber fichas automaticamente pela Chippix.
      </p>
      <p>
        <span class="font-weight-bold">✅ Solução rápida</span>: Clique em "<span class="total-players">Convidar</span>",
          insira o telefone do jogador e envie o convite.
      </p>
      <p>
          📌 No próximo fechamento, após a aceitação, o jogador estará elegível para receber fichas automaticamente.
      </p>
      <p>🚀 Mais controle, menos trabalho! Convide seus jogadores agora e simplifique sua gestão com a Chippix.</p>
    </div>
    <div class="dropdown-divider"></div>
    <div class="unreleated-list">
      <ng-container *ngIf="!isLoading; else showUnreleatedSkeleton">
        <div *ngIf="rakebackPlayersUnreleated.length > 0" class="table-responsive mt-1">
          <table class="table text-nowrap table-borderless">
            <thead>
              <tr>
                <th class="text-left text-muted font-weight-bold w-50">Jogador ID</th>
                <th class="text-left text-muted font-weight-bold">Status</th>
                <th class="text-center text-muted font-weight-bold"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let unrelatedPlayer of rakebackPlayersUnreleated" class="player-item">
                <td class="text-left w-50">
                  {{unrelatedPlayer.playerId}}
                </td>
                <td class="text-left">
                  <a href="javascript:void(0)" class="text-primary font-weight-bold" (click)="onCheckInvitePlayerStatus()">Ver status</a>
                </td>
                <td class="text-right">
                  <div class="invite-btn-wrapper">
                    <button type="button" class="btn btn-primary" (click)="onInvitePlayer(unrelatedPlayer.playerId)">
                      <i class="bi bi-send"></i>
                      Convidar
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="px-2 d-flex justify-content-between align-items-center">
            <p class="mt-2 mb-0 text-muted">
              <small class="font-weight-light">
                Exibindo
                <span class="font-weight-normal">{{criteria.page}}</span>
                de
                <span class="font-weight-normal">{{criteria.pages}}</span>
                {{criteria.pages > 1 ? 'páginas': 'página'}}
              </small>
            </p>
            <ngb-pagination
              class="mt-3 mx-3"
              [collectionSize]="criteria.total"
              [(page)]="criteria.page"
              [pageSize]="criteria.limit"
              [maxSize]="maxSize"
              [rotate]="rotatePagination"
              [ellipses]="false"
              [boundaryLinks]="true"
              (pageChange)="jumpPagination($event)">
            </ngb-pagination>
          </div>
        </div>
      </ng-container>
      <ng-template #showUnreleatedSkeleton>
        <div class="table-responsive">
          <table class="table table-borderless mb-0">
            <thead>
              <tr>
                <th scope="col" class="column-id w-50">
                  <ngx-skeleton-loader
                    [theme]="{'width': '20%', 'background': '#f9e1eb no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th scope="col" class="column-percentage align-middle">
                  <ngx-skeleton-loader
                    [theme]="{'width': '30%', 'background': '#f9e1eb no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </th>
                <th scope="col" class="column-rake align-middle text-center loader-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="player-item" *ngFor="let player of [0,0,0]">
                <td class="column-id align-middle">
                  <ngx-skeleton-loader [theme]="{'width': '60%', 'background': '#f9e1eb no-repeat'}" appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td class="column-percentage align-middle">
                  <ngx-skeleton-loader [theme]="{'width': '60%','background': '#f9e1eb no-repeat'}" appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </td>
                <td class="column-rake align-middle text-center loader-center w-25">
                  <ngx-skeleton-loader
                    [theme]="{'width': '50%', 'background': '#f9e1eb no-repeat'}"
                    appearance="line"
                    animation="progress">
                  </ngx-skeleton-loader>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-pag w-100 align-content-center">
          <p class="m-0 w-25">
            <ngx-skeleton-loader
              [theme]="{'width': '100%', 'background': '#f9e1eb no-repeat'}"
              appearance="line"
              animation="progress">
            </ngx-skeleton-loader>
          </p>
          <div class="text-right w-75">
            <ngx-skeleton-loader
              [theme]="{'height': '36px', 'width': '35%', 'background': '#f9e1eb no-repeat'}"
              appearance="line"
              animation="progress">
            </ngx-skeleton-loader>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>