<div *ngIf="!isSkeletonLoading">
    <div *ngIf="status === transactionStatus.ERROR && errorCode" [ngSwitch]="errorCode" class="pb-3">
        <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small *ngSwitchCase="107">
                <p class="m-0">
                    Esse jogador não pertence mais ao clube.
                </p>
            </small>
            <small *ngSwitchCase="142">
                <p class="m-0">
                    O jogador não possui fichas suficientes para sacar.
                </p>
            </small>
            <small *ngSwitchDefault>
                <p class="m-0">
                    Este erro não é comum. Comunique a equipe Chippix sobre este erro:
                    {{errorCode}}.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.CLUB_HAS_NO_BALANCE"
        class="pb-2">
        <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    <strong>Clube não possui saldo</strong>. Não foi possível realizar o pagamento da solicitação de saque
                    de
                    forma <strong>Automática</strong>, realize o pagamento de forma <strong>Manual</strong>.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.TWO_STEP_NOT_CONFIRMED"
        class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    <strong>A segunda verificação de retiradas de fichas não foi concluída</strong>. Não foi possível
                    realizar o pagamento da
                    solicitação de saque de forma <strong>Automática</strong>. Por favor, verifique se as fichas foram
                    retiradas corretamente e realize o pagamento de forma <strong>Manual</strong>.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.VALUE_EXCEEDED"
        class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    As fichas foram retiradas e verificadas, porém o limite definido para saque automático foi excedido.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_REFUSED"
        class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    As fichas foram retiradas e verificadas, porém a instituição do jogador recusou o pagamento.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_NOT_STARTED ||
        transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_NOT_CONFIRMED"
        class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    Não foi possível realizar o pagamento da solicitação de saque de forma <strong>Automática</strong>.
                    Por favor, realize o pagamento de forma <strong>Manual</strong>.
                </p>
            </small>
        </div>
    </div>
    <div class="col-12"
    *ngIf="status === transactionStatus.WAITING_PAYMENT && transaction.claimbackPaymentType === claimbackPaymentTypeEnum.MANUALLY">
    <div class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-info mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-info-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    Sempre confira o nome da conta PIX em <strong>Dados do Jogador</strong> com o PIX a ser pago.
                </p>
                <p class="m-0">
                    Após marcar como <strong>Pago</strong> o status da transação será alterado.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.TWO_STEP_NOT_CONFIRMED"
        class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    <strong>A segunda verificação de retiradas de fichas não foi concluída</strong>. Por favor,
                    verifique se as fichas foram retiradas corretamente e realize o pagamento de forma
                    <strong>Manual</strong>.
                </p>
            </small>
        </div>
    </div>
</div>
<div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.MANUAL_WITHDRAW_STATE"
    class="px-4 pt-0 pb-4">
    <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
        <i class="bi bi-x-circle-fill mr-3"></i>
        <small>
            <p class="m-0">
                Esta transação está configurada para ser efetuada manualmente,
            </p>
            <p class="m-0">
                Após marcar como <strong>Pago</strong> o status da transação será alterado.
            </p>
        </small>
    </div>
</div>
<div class="col-12"
    *ngIf="status === transactionStatus.WAITING_PAYMENT && transaction.claimbackPaymentType === claimbackPaymentTypeEnum.AUTOMATIC">
    <div *ngIf="transaction.autoClaimbackErrorCode" class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-info mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-info-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    Sempre confira o nome da conta PIX em <strong>Dados do Jogador</strong> com o PIX a ser pago.
                </p>
                <p class="m-0">
                    Após marcar como <strong>Pago</strong> o status da transação será alterado.
                </p>
            </small>
        </div>
    </div>
    <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_DESTINATION_NOT_CREATED"
        class="px-4 pt-0 pb-4">
        <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
                <p class="m-0">
                    <strong>Chave PIX do jogador não encontrada</strong>. Não foi possível realizar o pagamento da
                    solicitação de saque de forma <strong>Automática</strong>, verifique a chave PIX do jogador para
                    realizar o pagamento de forma <strong>Manual</strong>.
                </p>
            </small>
        </div>
    </div>
</div>
</div>
<!-- transactions info -->
<div *ngIf="!isSkeletonLoading">
    <p class="text-muted font-weight-bold">Dados do jogador</p>
    <div class="bg-light p-3 text-dark rounded-lg">
        <div class="mb-3">
            <b>Nome:</b>
            {{name}}
        </div>
        <div class="mb-3">
            <b>ID do jogador:</b>
            {{playerAccountId}}
        </div>
        <div class="mb-3">
            <b>E-mail:</b>
            {{email}}
        </div>
        <div class="mb-3">
            <b>Número do telefone:</b>
            {{ddd + phoneNumber | mask: '(00) 00000-0000'}}
        </div>
        <div class="mb-3">
            <b>Nome da conta PIX:</b>
            {{pixAccountName}}
        </div>
        <div>
            <b>Chave PIX:</b>
            {{pixKey}}
        </div>
    </div>
    <p class="text-muted font-weight-bold pt-3">{{ (status ===
        transactionStatus.WAITING_PAYMENT)? 'Solicitação do Jogador' : 'Dados da solicitação de saque' }}</p>
    <div class="bg-light px-4 py-3 text-dark rounded-lg">
        <div class="mb-3">
            <b>Valor:</b>
            {{amount / 100 | currency:'BRL':'symbol':'1.2-2'}}
        </div>
        <div class="mb-3">
            <b>Data:</b>
            {{data | date: 'd/M/yy'}}
        </div>
        <div class="mb-3">
            <b>Horário:</b>
            {{data | date: 'HH:mm'}}
        </div>
        <div [ngSwitch]="status" class="mb-3">
            <b>Status:</b>
            <span class="badge badge-pill badge-info font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.PROCESSING'>{{transactionStatusNamed[transactionStatus.PROCESSING]}}</span>
            <span class="badge badge-pill badge-success font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.DONE'>{{transactionStatusNamed[transactionStatus.DONE]}}</span>
            <span class="badge badge-pill badge-dark font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.REFUNDED'>{{transactionStatusNamed[transactionStatus.REFUNDED]}}</span>
            <span class="badge badge-pill badge-warning text-wrap font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.WAITING_PAYMENT'>{{transactionStatusNamed[transactionStatus.WAITING_PAYMENT]}}</span>
            <span class="badge badge-pill badge-success font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.PAID'>{{transactionStatusNamed[transactionStatus.PAID]}}</span>
            <span class="badge badge-pill badge-danger text-wrap font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.ERROR'>{{transactionStatusNamed[transactionStatus.ERROR]}}</span>
            <span class="badge badge-pill badge-info text-wrap font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.WITHDRAWING_CHIPS'>{{transactionStatusNamed[transactionStatus.WITHDRAWING_CHIPS]}}</span>
            <span class="badge badge-pill badge-info text-wrap font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.SENDING_CHIPS'>{{transactionStatusNamed[transactionStatus.SENDING_CHIPS]}}</span>
            <span class="badge badge-pill badge-warning text-wrap font-weight-normal ml-2"
                *ngSwitchCase='transactionStatus.NOT_PAID'>{{transactionStatusNamed[transactionStatus.NOT_PAID]}}</span>
        </div>
        <div [ngSwitch]="transaction.claimbackPaymentType">
            <b>Tipo do saque:</b>
            <ng-container *ngSwitchCase='claimbackPaymentTypeEnum.AUTOMATIC'>
                {{claimbackPaymentTypeNamedEnum[claimbackPaymentTypeEnum.AUTOMATIC]}}</ng-container>
            <ng-container *ngSwitchCase='claimbackPaymentTypeEnum.MANUALLY'>
                {{claimbackPaymentTypeNamedEnum[claimbackPaymentTypeEnum.MANUALLY]}}</ng-container>
        </div>
    </div>
    <ng-container *ngIf="transaction.receipt">
        <p class="text-muted font-weight-bold pt-3">Verificação</p>
        <div class="bg-light px-4 py-3 text-dark rounded-lg">
            <div class="mb-3">
                <b>Nick do jogador:</b>
                {{transaction.receipt?.currentAccountName}}
            </div>
            <div *ngIf="transaction.receipt?.givenNickName != ''" class="mb-3">
                <b>Nick definido:</b>
                {{transaction.receipt?.givenNickName}}
            </div>
            <div class="mb-3">
                <b>ID do jogador:</b>
                {{transaction.receipt?.playerId}}
            </div>
            <div class="mb-3">
                <b>Saldo alterado:</b>
                {{transaction.receipt?.previousValue}}
                <i class="bi bi-arrow-right"></i>
                {{transaction.receipt?.currentValue}}
            </div>
            <div>
                <b>Fichas retiradas:</b>
                {{transaction.receipt?.differenceValue}}
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="isSkeletonLoading">
    <p class="mb-0">
        <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '50%'}" count="1" appearance="line" animation="progress">
        </ngx-skeleton-loader>
    </p>
    <div class="bg-light pt-3 pl-3 pr-3 text-dark rounded-lg">
        <ngx-skeleton-loader [theme]="{'height': '24px', 'background': '#d7d7d757 no-repeat'}" count="4" appearance="line" animation="progress">
        </ngx-skeleton-loader>
    </div>
    <p class="pt-3 mb-0">
        <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '65%'}" count="1" appearance="line" animation="progress">
        </ngx-skeleton-loader>
    </p>
    <div class="bg-light pt-3 pl-3 pr-3 text-dark rounded-lg">
        <ngx-skeleton-loader [theme]="{'height': '24px', 'background': '#d7d7d757 no-repeat'}" count="5" appearance="line" animation="progress">
        </ngx-skeleton-loader>
    </div>
    <div *ngIf="transaction?.receipt">
        <p class="pt-3 mb-0">
            <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '40%'}" count="1" appearance="line" animation="progress">
            </ngx-skeleton-loader>
        </p>
        <div class="bg-light pt-3 pl-3 pr-3 text-dark rounded-lg">
            <ngx-skeleton-loader [theme]="{'height': '24px', 'background': '#d7d7d757 no-repeat'}" count="4" appearance="line" animation="progress">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>