import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { Login } from 'src/app/auth/auth.model';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-sms-confirm',
  templateUrl: './sms-confirm.component.html',
  styleUrls: ['./sms-confirm.component.scss']
})
export class SmsConfirmComponent implements OnInit {

  login: Login = {} as Login;

  constructor(
    private notificationService: NotificationService,
    private service: AuthService,
    private authGuard: AuthGuard,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) { }

  ngOnInit(): void {
  }

  // changePassword(): void {
  //   const token = this.activatedRoute.snapshot.queryParams.token;
  //   this.authGuard.setToken(token);
  //   this.service.changePassword(this.login).subscribe(
  //     () => {
  //       this.notificationService.showSuccess('Login efetuado com sucesso', 'Email');
  //     });
  // }

  onConfirm(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.login.email = params.email;
      this.login.password = params.password;
      this.login.ddd = params.ddd;
      this.login.phoneNumber = params.phoneNumber;
    });
    this.login.role = 'CLUB';
    this.service.confirmUserBySms(this.login).pipe(
      mergeMap(() => this.service.authenticate(this.login))
    ).subscribe(
      () => {
        this.router.navigate(['']);
        this.notificationService.showSuccess('Conta criada com sucesso!', '');
      },
      (error) => {
        if (error.status === 401) {
          // this.notificationService.showError('Usuário não autorizado', 'Autorização negada');
          this.notificationService.showError('Código inválido', '');
        } else {
          this.notificationService.showError('Não foi possível criar a conta', '');
        }
      });
  }

  onSubmit(): void {
    this.onConfirm();
  }
}
