<div id="__layout">
  <div class="d-flex align-items-center min-vh-100 min-vw-100 bg-light p-0">
    <div class="container">
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;" *ngIf="!confirmUser">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h2 class="mt-4 text-center font-weight-bold">Alterar senha</h2>
          <p class="text-center text-muted">
            Ou
            <a [routerLink]="['/login']" class="text-decoration-none font-weight-bolder">
              voltar para área de login
            </a>
          </p>
        </div>
        <p class="mt-2 text-center text-muted">
          Você receberá um código para redefinir a senha
        </p>
      </div>
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;" *ngIf="confirmUser">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Confirmar usuário
        </h2>
        <p class="mt-2 text-center text-muted">
          Você receberá um código para confimar o usuário
      </div>
      <div class="container-fluid mt-4 mx-auto p-0 mw-100 bg-white p-4 shadow-sm rounded-lg" style="width: 430px;">
        <form #recoveryForm="ngForm" (ngSubmit)="recoveryForm.form.valid && recoveryPassword()">
          <div class="form-group mb-0">
            <label class="mb-1">
              Número do telefone
            </label>
            <input mask="(00) 0000-0000||(00) 0 0000-0000" [dropSpecialCharacters]="true" prefix="+55 "
              [showMaskTyped]="false" placeHolderCharacter="*" type="tel"
              class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg" style="height: 40px;"
              name="fullPhoneNumber" [(ngModel)]="login.fullPhoneNumber" #fullPhoneNumber="ngModel"
              [ngClass]="{ 'is-invalid': recoveryForm.submitted && fullPhoneNumber.invalid }" required minlength="11"
              fullPhoneNumber />
            <div *ngIf="fullPhoneNumber.invalid" class="invalid-feedback">
              <div *ngIf="fullPhoneNumber.errors?.required">Número do telefone necessário</div>
              <div *ngIf="fullPhoneNumber.errors?.minlength">O número do telefone deve ser válido</div>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>