<div class="contents-wrapper">
  <div class="main-content" [class.shifted]="isSlidePanelOpen">
    <div class="d-flex justify-content-between align-items-center">
      <div class="h4 font-weight-bold">Transações</div>
      <button type="button" class="btn btn-light border d-flex align-items-center text-primary"
        (click)="resetTabData(activeTab)">
        <i class="bi bi-arrow-clockwise mr-2"></i>
        Atualizar
      </button>
    </div>
    <div class="bg-white mt-4">
      <div class="view-tabs-wrapper">
        <div class="text-muted">Visualizar transações por:</div>
        <div class="btn-group" role="group" aria-label="radio toggle button group">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-filter active m-0">
              <input type="radio" name="options" id="PIX" autocomplete="off" (change)="onTabChange('PIX')"
                checked>Entradas
            </label>
            <label class="btn btn-filter m-0">
              <input type="radio" name="options" id="WITHDRAW" autocomplete="off"
                (change)="onTabChange('WITHDRAW')">Saídas
            </label>
          </div>
        </div>
      </div>
      <app-transaction-list #transactionList (transactionSelected)="onTransactionSelect($event)"></app-transaction-list>
    </div>
  </div>
  <div class="slide-content">
    <app-transaction-slide-panel #transactionSlidePanel [isOpen]="isSlidePanelOpen" [transaction]="selectedTransaction"
      (eventClose)="closeTransactionSlidePanel()"></app-transaction-slide-panel>
  </div>
</div>