import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { InvitedPlayer } from "./invite-new-players.model";
import { GAME_ACCOUNT_INVITE_URL } from '../../config/settings';

@Injectable({
	providedIn: 'root'
})
export class InviteNewPlayersService {

	constructor(
		private http: HttpClient,
	) { }

	inviteNewPlayer(invitedGameAccount: InvitedPlayer) {
		return this.http.post(`${GAME_ACCOUNT_INVITE_URL}`, invitedGameAccount);
	}

	deleteInvitedPlayer(invitedGameAccount: InvitedPlayer) {
		return this.http.delete(`${GAME_ACCOUNT_INVITE_URL}`, { body: invitedGameAccount });
	}
}