import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
		config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    config.backdrop = 'static';
		config.keyboard = false;
  }

  openModal(component: any): void {
    if (this.modalService.hasOpenModals()) {
      return;
    } else {
      this.modalService.open(component);
    }
  }
}
