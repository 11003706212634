import { NgModule } from "@angular/core";
import { DoubleCheckModalComponent } from "./double-check-modal.component";
import { CommonModule } from "@angular/common";
import { CustomizeWithdrawModule } from "src/app/components/customize-withdraw/customize-withdraw.module";
import { NgxMaskModule } from "ngx-mask";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "ngx-bootstrap/modal";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { QRCodeModule } from "angularx-qrcode";
import { ClipboardModule } from "ngx-clipboard";
import { RouterModule } from "@angular/router";
import { NgxCurrencyModule } from "ngx-currency";
import { customCurrencyMaskConfig } from "src/app/config/custom-currency-mask.config";

@NgModule({
  declarations: [
    DoubleCheckModalComponent
  ],
  exports: [DoubleCheckModalComponent],
  imports: [
    CommonModule,
    NgxMaskModule,
    NgbModule,
    ModalModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
    QRCodeModule,
    ClipboardModule,
    NgxMaskModule,
    RouterModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    CustomizeWithdrawModule
  ]
})
export class DoubleCheckModalModule { }