<div class="p-3">
  <div *ngIf="!isModal">
    <h5>Dados do Jogador</h5>
    <div class="bg-light px-4 py-3 text-dark rounded-lg">
      <div class="">
        <b>Nome:</b>
        {{name}}
      </div>
      <a href="mailto: {{email}}" class="d-flex align-items-center">
        <i class="bi bi-envelope-fill mr-2"></i>
        {{email}}
        <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
      </a>
      <a href="https://wa.me/+55{{ddd + phoneNumber}}" target="_blank" class="d-flex align-items-center">
        <i class="bi bi-whatsapp mr-2"></i>
        {{ddd + phoneNumber | mask: '(00) 00000-0000'}}
        <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
      </a>
    </div>
  </div>
  <h5 class="pt-4">Personalizar saque na conta
    <b>{{playerAccountId}}</b>
  </h5>
  <div class="bg-light px-4 py-3 text-dark rounded-lg mt-2">
    <form #playerControlForm="ngForm" class="form" (ngSubmit)="onWithdrawSettingsChange()">
      <div class="form-group row align-items-center">
        <div class="col-lg-12 d-flex align-items-center justify-content-between">
          <label class="pl-0 col-lg-8 col-form-label" for="maxValueToWithdraw">Valor máximo de saque automático</label>
          <div class="col-lg-4 text-right custom-control custom-switch pr-0">
            <input type="checkbox" class="custom-control-input" id="maxValueToWithdrawActive"
              [(ngModel)]="withdrawSettings.playerWithdrawSettings.maxValueToWithdrawActive" name="maxValueToWithdrawActive">
            <label class="custom-control-label" for="maxValueToWithdrawActive">
              <span *ngIf="withdrawSettings.playerWithdrawSettings.maxValueToWithdrawActive; else showDisabled"
                class="custom-switch-label">Ativado</span>
              <ng-template #showDisabled>
                <span class="custom-switch-label">Desativado</span>
              </ng-template>
            </label>
          </div>
        </div>
        <div class="col-lg-12 pt-2">
          <div *ngIf="withdrawSettings.playerWithdrawSettings.maxValueToWithdrawActive" class="input-group mb-0 mr-sm-2 mr-4">
            <div class="input-group-prepend">
              <div class="input-group-text">R$</div>
            </div>
            <input type="tel" autocomplete="off" class="form-control" name="maxValueToWithdraw"
              [(ngModel)]="withdrawSettings.playerWithdrawSettings.maxValueToWithdraw" #maxValueToWithdraw="ngModel"
              [ngClass]="{ 'is-invalid': playerControlForm.submitted && maxValueToWithdraw.invalid }" required
              maxValueToWithdraw currencyMask />
            <div class="invalid-feedback">
              <div>Esse campo é obrigatório</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <div class="col-lg-12 d-flex align-items-center justify-content-between">
          <label class="pl-0 col-lg-8 col-form-label" for="maxWithdrawPerDay">Limite de saques diário</label>
          <div class="col-lg-4 text-right custom-control custom-switch pr-0">
            <input type="checkbox" class="custom-control-input" id="maxWithdrawPerDayActive"
              [(ngModel)]="withdrawSettings.playerWithdrawSettings.maxWithdrawPerDayActive" name="maxWithdrawPerDayActive">
            <label class="custom-control-label" for="maxWithdrawPerDayActive">
              <span *ngIf="withdrawSettings.playerWithdrawSettings.maxWithdrawPerDayActive; else showDisabled"
                class="custom-switch-label">Ativado</span>
              <ng-template #showDisabled>
                <span class="custom-switch-label">Desativado</span>
              </ng-template>
            </label>
          </div>
        </div>
        <div *ngIf="withdrawSettings.playerWithdrawSettings.maxWithdrawPerDayActive" class="col-lg-12 pt-2">
          <input type="number" autocomplete="off" maxlength="3" min="0" class="form-control" name="maxWithdrawPerDay"
            [(ngModel)]="withdrawSettings.playerWithdrawSettings.maxWithdrawPerDay" #maxWithdrawPerDay="ngModel"
            [ngClass]="{ 'is-invalid': playerControlForm.submitted && maxWithdrawPerDay.invalid }" required
            maxWithdrawPerDay inputmode="numeric" oninput="this.value = this.value.replace(/\D+/g, '')" />
          <div class="invalid-feedback">
            <div>Esse campo é obrigatório</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!isModal" class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
    <i class="bi bi-info-circle-fill mr-3"></i>
    <small>
      {{alertText}}
    </small>
  </div>
</div>