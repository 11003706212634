<app-navbar></app-navbar>

<div class="sidebar">
  <app-sidebar></app-sidebar>
</div>
<div class="main">
      <div class="container mx-auto">
        <router-outlet></router-outlet>
      </div>
</div>
