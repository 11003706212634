import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'sort' })
export class SortPipe implements PipeTransform {
  transform(items: any[], column: string, direction: string): any[] {
    if (!column || direction === '') {
      return items;
    }

    return [...items].sort((a, b) => {
      const aValue = this.resolveNestedProperty(a, column);
      const bValue = this.resolveNestedProperty(b, column);

      let res: number;
      if (this.isDate(aValue) && this.isDate(bValue)) {
        res = new Date(aValue).getTime() - new Date(bValue).getTime();
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        res = aValue - bValue;
      } else {
        res = aValue.localeCompare(bValue);
      }
      return direction === 'asc' ? res : -res;
    });
  }

  private resolveNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((o, p) => o && o[p], obj);
  }

  private isDate(value: any): boolean {
    return !isNaN(Date.parse(value));
  }
}