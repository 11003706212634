export enum PlayerStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
  WITHDRAW_BLOCKED = 'WITHDRAW_BLOCKED',
  WITHDRAW_MANUAL = 'WITHDRAW_MANUAL',
  WITHDRAW_AUTOMATIC = 'WITHDRAW_AUTOMATIC',
  BLOCKED = 'BLOCKED',
  INVITED = 'INVITED',
}

export enum PlayerStatusNamed {
  ALL = 'Todos',
  ACTIVE = 'Ativo',
  NOT_ACTIVE = 'Inativo',
  WITHDRAW_BLOCKED = 'Saque bloqueado',
  WITHDRAW_MANUAL = 'Saque manual',
  WITHDRAW_AUTOMATIC = 'Saque automático',
  BLOCKED = 'Bloqueado'
}

export enum PlayerAction {
  ACTIVATE = 'ACTIVATE',
  RESET_ATTEMPS = 'RESET_ATTEMPS',
  RESET_WITHDRAW = 'RESET_WITHDRAW',
  BLOCK_WITHDRAW = 'BLOCK_WITHDRAW',
  UNBLOCK_WITHDRAW = 'UNBLOCK_WITHDRAW',
  BLOCK = 'BLOCK',
  WITHDRAW_MANUAL = 'WITHDRAW_MANUAL',
  WITHDRAW_AUTOMATIC = 'WITHDRAW_AUTOMATIC',
  CUSTOMIZE_WITHDRAW = 'CUSTOMIZE_WITHDRAW',
}

export enum DoubleCheckModalText {
  ACTIVATE_ALERT_TEXT = 'Sempre confira os dados com os do jogador no qual você quer Ativar. O Chippix não se reponsabiliza pela ativação manual.',
  RESET_ATTEMPS_ALERT_TEXT = 'As tentativas de ativação de conta do jogador serão resetadas, portanto o jogador poderá realizar novas tentativas.',
  RESET_WITHDRAW_ALERT_TEXT = 'A quantidade de saques do jogador será resetada, assim o jogador poderá realizar novas solicitações de saque durante o dia.',
  BLOCK_WITHDRAW_ALERT_TEXT = 'O saque do jogador será bloqueado, portanto esse jogador ficará impossibilitado de realizar solicitações saques.',
  UNBLOCK_WITHDRAW_ALERT_TEXT = 'O saque do jogador será desbloqueado, portanto esse jogador poderá realizar solicitações saques.',
  BLOCK_ALERT_TEXT = 'O jogador será bloqueado, portanto esse jogador ficará impossibilitado de realizar solicitações de compras e saques.',
  WITHDRAW_MANUAL_ALERT_TEXT = 'Todos os saques desse jogador será feito manualmente pelo gestor do clube',
  WITHDRAW_AUTOMATIC_ALERT_TEXT = 'Todos os saques desse jogador será feito automaticamente pelo Chippix',
  CUSTOMIZE_WITHDRAW = 'Os valores máximos de saque e a quantidade de saques será alterada apenas para este jogador'
}

export enum Temperature {
  HOT = 'HOT',
  WARM = 'WARM',
  COLD = 'COLD'
}
