import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PlayerStatus } from 'src/app/shared/player.enum';
import { CLUB_DASHBOARD_DATA_URL, GAME_ACCOUNT_LIST_URL } from '../../config/settings';
import { DashboardData, Pageable, PatchgameAccountStatusPayload, Player, PlayerResponse } from './player.model';
import { WithdrawSettings } from 'src/app/shared/player.interface';

@Injectable({ providedIn: 'root' })
export class PlayerService {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }

  getDashboardData(): Observable<DashboardData> {
    return this.http.get<DashboardData>(CLUB_DASHBOARD_DATA_URL)
  }

  patchGameAccountStatus(gameAccountId: any, action: string, withdrawSettings?: WithdrawSettings): Observable<PlayerResponse> {
    const payload = this.handleWithdrawSettingsPayload(action, withdrawSettings)
    return this.http.patch<PlayerResponse>(`${GAME_ACCOUNT_LIST_URL}/${gameAccountId}`, payload)
      .pipe(finalize(() => this.spinner.hide()));
  }

  handleWithdrawSettingsPayload(action: string, withdrawSettings?: WithdrawSettings): PatchgameAccountStatusPayload {
    const payload: PatchgameAccountStatusPayload = { action };
    withdrawSettings?.maxValueToWithdrawActive && 
      (payload.maxValueToWithdraw = this.fixAmountFormat(withdrawSettings?.maxValueToWithdraw));
    withdrawSettings?.maxWithdrawPerDayActive && 
      (payload.maxWithdrawPerDay = withdrawSettings?.maxWithdrawPerDay);
    return payload;
  }

  private fixAmountFormat(amount: number): number {
    if (!amount) return amount;
    const formatString = amount.toString().split('.');
    if (!formatString[1]) {
      formatString[1] = '';
    }
    formatString[1] = formatString[1].padEnd(2, '0');
    return parseInt(formatString.join(''), 10);
  }

  listPlayers(criteria: any): Observable<Pageable<Player>> {
    const params = {
      listByStatus: criteria.listByStatus,
      searchTerm: criteria.searchTerm,
      page: criteria.page.toString(),
      totalPages: criteria.totalPages,
      filter: criteria.tab.toUpperCase(),
    };
    if (params.listByStatus === PlayerStatus.ALL) {
      delete params.listByStatus;
    }
    if (params.filter === 'ALL') {
      delete params.filter;
    }
    return this.http.get<Pageable<Player>>(GAME_ACCOUNT_LIST_URL, { params });
  }
}
