
<div class="border border-0 bg-white">
  <div class="form-inline m-0 d-flex justify-content-end bg-light">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn btn-light border text-primary" id="listDropdown" ngbDropdownToggle>{{selectedPeriod}}</button>
      <button type="button" class="btn btn-light border align-items-center text-primary ml-3" (click)="refreshCharts()">
        <i class="bi bi-arrow-clockwise mr-1"></i>
        Atualizar
      </button>
      <div ngbDropdownMenu aria-labelledby="lostDropdow">
        <button ngbDropdownItem (click)="onDropdownPeriod(dropdownPeriod.LAST_WEEK)">Últimos 7 dias</button>
        <button ngbDropdownItem (click)="onDropdownPeriod(dropdownPeriod.TODAY)">Hoje</button>
        <button ngbDropdownItem (click)="onDropdownPeriod(dropdownPeriod.ALL_PERIOD)">Todo o período</button>
        <div class="dropdown-divider"></div>
        <form class="p-2">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-white" id="datePickerDropdown" ngbDropdownToggle>Dia específico</button>
            <div ngbDropdownMenu aria-labelledby="datePickerDropdown" class="p-0">
              <ngb-datepicker #dp (navigate)="date = $event.next" (dateSelect)="onSpecificDaySelected($event)"></ngb-datepicker>
            </div>
          </div>
        </form>
        <form class="p-2">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-white" id="datePickerDropdown" ngbDropdownToggle>Período personalizado</button>
            <div ngbDropdownMenu aria-labelledby="datePickerDropdown" class="p-0">
              <form class="">
                <ngb-datepicker #dp (dateSelect)="onRangeDateSelect($event)" [displayMonths]="1" [dayTemplate]="t"
                  outsideDays="hidden">
                </ngb-datepicker>
                <ng-template #t let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </form>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div>
    <div class="dashboard border border-0">
      <div class="row row-cols-1 row-cols-md-2 justify-content-between m-0 bg-light">
        <div class="col-md-6 p-0">
          <div class="p-4 mt-4 my-md-4 mr-0 mr-md-2 rounded-lg bg-white shadow-sm d-flex align-items-center"
            style="height: 110px;">
            <i class="bi bi-arrow-down-right mr-4" style="font-size: 1.5rem;"></i>
            <div class="mb-4 mb-sm-0 p-0">
              <dt class="text-muted">Total de entradas</dt>
              <div class="item mt-2" *ngIf="operationFlowSkeletonLoading">
                <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '100px'}" count="1" appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </div>
              <div class="h5" *ngIf="!operationFlowSkeletonLoading">
                {{ operationFlow.cashIn/100 | currency: 'R$' }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <div class="p-4 my-2 my-md-4 ml-0 ml-md-2 rounded-lg bg-white shadow-sm d-flex align-items-center"
            style="height: 110px;">
            <i class="bi bi-arrow-up-right mr-4" style="font-size: 1.5rem;"></i>
            <div class="mb-4 mb-sm-0 p-0">
              <dt class="text-muted">Total de saídas</dt>
              <div class="item mt-2" *ngIf="operationFlowSkeletonLoading">
                <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '100px'}" count="1" appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </div>
              <div class="h5" *ngIf="!operationFlowSkeletonLoading">
                {{ operationFlow.cashOut/100 | currency: 'R$' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isInDashboardUrl">
  <div class="dashboard border border-0 bg-white">
    <div class="row row-cols-1 row-cols-md-2 justify-content-between m-0 bg-light">
      <div class="col-md-12 rounded-lg bg-white shadow-sm mb-4" #containerRef>
          <app-deposit-chart
            class="m-0"
            [view]="[containerRef.clientWidth - 24, 300]" 
            [chartData]="depositChartData">
          </app-deposit-chart>
      </div>
      <div class="col-md-12 p-2 rounded-lg bg-white shadow-sm mb-4 pt-4" #containerRef>
        <app-operation-chart
          [view]="[containerRef.clientWidth - 24, 300]"
          [chartData]="withdrawChartData">
        </app-operation-chart>
      </div>
    </div>
  </div>
</div>
