import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DropdownPeriod, DropdownPeriodNamed } from './dashboard.enum';
import { DashboardService } from './dashboard.service';
import { Subject } from 'rxjs';
import { ChartData, ChartDataSingle, DatePicker, OperationResult } from '../dashboard/dashboard.model';
import { DateTime } from 'luxon';
import { Criteria, OperationFlow, OperationFlowResponse } from './dashboard.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private readonly dashboardService: DashboardService,
    private calendar: NgbCalendar,
    public changeDetector: ChangeDetectorRef,
  ) {}
  depositChartData = new Subject<ChartDataSingle[]>();
  withdrawChartData = new Subject<ChartData[]>();
  operationFlow: OperationFlow = new OperationFlow();
  totalWithdraw!: number;
  totalDeposits!: number;
  operationFlowSkeletonLoading = true;

  criteria !: Criteria;
  selectedPeriod: string = DropdownPeriodNamed[DropdownPeriod.LAST_WEEK];
  hoveredDate: NgbDate | null = null;
  model!: NgbDateStruct;
  date!: { year: number; month: number };
  fromDate!: NgbDate;
  toDate: NgbDate | null = null;
  datePicker = {} as DatePicker;
  isInDashboardUrl = true;

  public get dropdownPeriod(): typeof DropdownPeriod {
    return DropdownPeriod;
  }

  ngOnInit(): void {
    this.onDropdownPeriod(this.dropdownPeriod.LAST_WEEK);
    this.operationFlowSkeletonLoading = false;
  }

  ngOnDestroy(): void {
    this.isInDashboardUrl = false;
  }

  formatDate(date: string) {
    var parts = date.split('/');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }
  
  refreshCharts() {
    this.loadCharts(this.datePicker);
  }

  loadCharts(interval: DatePicker) {
    this.loadOperationChartData(interval);
    this.loadFlowData(interval);
  }

  loadFlowData(interval: DatePicker) {
    this.operationFlowSkeletonLoading = true;
    this.dashboardService.getOperationFlow(interval)
      .subscribe((response: OperationFlowResponse) => {
        this.operationFlow = response.data;
        this.operationFlowSkeletonLoading = false;
      });
  }

  loadOperationChartData(interval: DatePicker) {
    this.dashboardService.getOperationChartData(interval.startDate, interval.endDate || '')
    .subscribe(data => {
      this.withdrawChartData.next(this.parseHistoryToChartData(data.data));
      this.depositChartData.next(this.calculateTotalInOut(data.data));
    });
  }

  calculateTotalInOut(history: OperationResult[]): ChartDataSingle[] {
    const singleChartData: ChartDataSingle[] = [
      { name: 'Depósitos', value: 0 },
      { name: 'Saques', value: 0 },
      { name: 'Saques manuais', value: 0 },
      { name: 'Falha em Depósitos', value: 0 },
      { name: 'Falha em Saques', value: 0 },
    ];
    const [totalCashIn, totalCashOut, manualCashOut, cashInErrors, cashOutErrors] = history.reduce((acc: any, curr: any) => {
      acc[0] += curr.cashIn;
      acc[1] += curr.cashOut;
      acc[2] += curr.manualCashOut;
      acc[3] += curr.cashInErrors;
      acc[4] += curr.cashOutErrors;
      return acc;
    }, [0, 0, 0, 0, 0]);
    singleChartData[0].value = totalCashIn;
    singleChartData[1].value = totalCashOut;
    singleChartData[2].value = manualCashOut;
    singleChartData[3].value = cashInErrors;
    singleChartData[4].value = cashOutErrors;
    return singleChartData;
  }

  parseHistoryToChartData(history: OperationResult[]): ChartData[] {
    return history.map((registry: OperationResult) => {
      return ({
        name: this.formatDate(registry._id.date),
        series: [
          {
            name: 'Depósitos',
            value: registry.cashIn,
          },
          {
            name: 'Saques',
            value: registry.cashOut,
          },
          {
            name: 'Saques manuais',
            value: registry.manualCashOut,
          },
          {
            name: 'Falhas em Depósitos',
            value: registry.cashInErrors,
          },
          {
            name: 'Falhas em Saques',
            value: registry.cashOutErrors,
          },
        ]
      });
    });
  }

  onDropdownPeriod(period: string): void {
    let currentDate: NgbDate;
    switch (period) {
      case this.dropdownPeriod.ALL_PERIOD:
        this.selectedPeriod =
          DropdownPeriodNamed[this.dropdownPeriod.ALL_PERIOD];
        this.datePicker = new DatePicker();
        currentDate = this.convertDateTimeToNgbDate(DateTime.local());
        const startDate = new NgbDate(2020, 1, 1)
        this.datePicker.startDate = this.convertToIsoLocalDate(startDate);
        this.datePicker.endDate = this.addEndOfDayToDate(currentDate);
        this.loadCharts(this.datePicker);
        break;
      case this.dropdownPeriod.TODAY:
        this.datePicker = new DatePicker();
        this.selectedPeriod = DropdownPeriodNamed[this.dropdownPeriod.TODAY];
        currentDate = this.convertDateTimeToNgbDate(DateTime.local());
        this.datePicker.startDate = this.convertToIsoLocalDate(currentDate);
        this.datePicker.endDate = this.addEndOfDayToDate(currentDate);
        this.loadCharts(this.datePicker);
        break;
      case this.dropdownPeriod.LAST_WEEK:
        this.datePicker = new DatePicker();
        this.selectedPeriod =
          DropdownPeriodNamed[this.dropdownPeriod.LAST_WEEK];
        const lastWeekDate = DateTime.local().minus({ days: 6 });
        currentDate = this.convertDateTimeToNgbDate(DateTime.local());
        this.datePicker.startDate = lastWeekDate.startOf('day').toISO();
        this.datePicker.endDate = this.addEndOfDayToDate(currentDate);
        this.loadCharts(this.datePicker);
        break;
      default:
        break;
    }
  }

  onSpecificDaySelected(event: NgbDate): void {
    this.selectedPeriod = this.convertToPtBrFormat(event);
    this.datePicker.startDate = this.convertToIsoLocalDate(event);
    this.datePicker.endDate = this.addEndOfDayToDate(event);
    this.loadCharts(this.datePicker);
  }

  onRangeDateSelect(date: NgbDate): void {
    this.criteria = new Criteria();
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.toDate) {
      this.selectedPeriod = `${this.convertToPtBrFormat(
        this.fromDate
      )} - ${this.convertToPtBrFormat(this.toDate)}`;
      this.datePicker.startDate = this.convertToIsoLocalDate(this.fromDate);
      this.datePicker.endDate = this.addEndOfDayToDate(this.toDate);
      this.loadCharts(this.datePicker);
    }
  }

  convertDateTimeToNgbDate(date: DateTime): NgbDate {
    return new NgbDate(date.year, date.month, date.day);
  }

  convertToIsoLocalDate(date: NgbDate): string {
    return DateTime.local(date.year, date.month, date.day).toISO();
  }

  addEndOfDayToDate(date: NgbDate): string {
    return DateTime.local(date.year, date.month, date.day).endOf('day').toISO();
  }

  convertToPtBrFormat(date: NgbDate): string {
    return DateTime.local(date.year, date.month, date.day).toFormat(
      'dd/MM/yyyy'
    );
  }

  isHovered(date: NgbDate): any {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): any {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): any {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
}
