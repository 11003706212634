<div class="off-canvas shadow-sm" *ngIf="isOpen" [ngClass]="{'active' : isOpen}">
  <div class="panel-content">
    <div class="panel-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onBack()">
        <span class="material-symbols-outlined">arrow_back</span>
      </button>
      <div>
        <h5 class="panel-title modal-title">{{errorTitle}}</h5>
      </div>
    </div>
    <div class="panel-body">
      <div *ngIf="player?._jaimeTask"
        class="rounded-0 border-0 alert alert-danger mb-3 d-flex align-items-center" role="alert">
        <i class="bi bi-x-circle-fill mr-3"></i>
        <small>
          <p class="m-0" [innerHTML]="errorMessage"></p>
        </small>
      </div>
      <div class="form-group mt-4 mb-0 custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" name="{{player._id+'-'+'playerCheck'}}"
          id="{{player._id+'-'+'playerCheck'}}" [(ngModel)]="isSentManually" (change)="onCheckChange($event)" [disabled]="player.sentManually">
        <label class="custom-control-label" for="{{player._id+'-'+'playerCheck'}}">
          Confirmar o envio de forma manual.
        </label>
      </div>
      <div *ngIf="player.sentManually" class="form-group mt-4 mb-5">
        <p class="text-muted font-weight-bold">Informações</p>
        <div class="bg-light px-4 py-3 text-dark rounded-lg mb-3">
          <p class="text-muted">
            <span>
              Confirmado o envio manual das fichas para o jogador ID {{player?.playerId}}
            </span>
            <span>
              em 
            </span>
            <strong>{{ player.sentManuallyAt | date: 'dd/MM/yy' }}</strong>
            <span> às </span>
            <strong>{{ player.sentManuallyAt | date: 'HH:mm:ss' }}</strong>
            <span>, pelo usuário </span>
            <strong>{{ player.sentManuallyBy?.name }}</strong>.
          </p>
          <p class="mb-0 text-muted">
            Se tiver alguma dúvida ou precisar de ajuda, nossa
              equipe de suporte está à disposição!
          </p>
        </div>
      </div>
    </div>
    <div class="panel-footer justify-content-end">
      <button (click)="onSave()" type="button" class="btn btn-primary text-right" [disabled]="!isSentManually || player.sentManually">Enviar</button>
    </div>
  </div>
</div>