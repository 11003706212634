import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthGuard } from './auth.guard';
import { REFRESH_TOKEN_URL } from '../config/settings';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthGuard) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: String = this.auth.getToken();
    if (request.url === REFRESH_TOKEN_URL) {
      token = this.auth.getMainToken();
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });
    return next.handle(request);
  }
}
