export enum DropdownPeriod {
    ALL_PERIOD = 'ALL_PERIOD',
    TODAY = 'TODAY',
    LAST_WEEK = 'LAST_WEEK',
}

export enum DropdownPeriodNamed {
    ALL_PERIOD = 'Todo período',
    TODAY = 'Hoje',
    LAST_WEEK = 'Últimos 7 dias',
}