<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">Detalhes</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body confirm-subtitle p-4">
        <h5>Dados da operação</h5>
        <div class="bg-light px-4 py-3 text-dark rounded-lg">
          <div *ngIf="operation.createdAt">
            <b>Data e hora:</b>
            {{operation.createdAt | date: 'd/M/yy'}}
            -
            {{operation.createdAt | date: 'HH:mm'}}
          </div>
          <div *ngIf="operation.type">
            <b>Tipo:</b>
            {{operationTypeNamed[operation.type]}}
          </div>
          <div *ngIf="operation.purpose">
            <b>Finalidade:</b>
            {{operationPurposeNamed[operation.purpose]}}
          </div>
          <div *ngIf="operation.type === 'CASH_IN'">
            <b>Entrada bruta:</b>
            {{ checkValueForGross(operation) | currency:'BRL':'symbol':'1.2-2' }}
          </div>
          <div *ngIf="operation.type === 'CASH_OUT'">
            <b>Saida bruta:</b>
            {{ checkValueForGross(operation) | currency:'BRL':'symbol':'1.2-2' }}
          </div>
          <div *ngIf="operation.type === 'CASH_IN'">
            <b>Entrada liquida:</b>
            {{ checkValueForLiquid(operation) | currency:'BRL':'symbol':'1.2-2' }}
          </div>
          <div *ngIf="operation.type === 'CASH_OUT'">
            <b>Saida liquida:</b>
            {{ checkValueForLiquid(operation) | currency:'BRL':'symbol':'1.2-2' }}
          </div>
          <div *ngIf="operation.playerId">
            <b>ID Jogador:</b>
            {{operation?.playerId}}
          </div>
          <div *ngIf="operation.payer">
            <b>Pagador:</b>
            {{operation.payer?.name}}
          </div>
          <div *ngIf="operation.receiver">
            <b>Recebedor:</b>
            {{operation.receiver?.name}}
          </div>
          <div *ngIf="operation.fee && operation.purpose != 'SERVICE_CHARGE'">
            <b>Taxa da operação:</b>
            {{ convertAmountToString(operation.fee) }}
          </div>
          <div *ngIf="operation.status">
            <b>Status:</b>
            <span class="ml-1 badge badge-pill font-weight-normal" [ngClass]="{
                'badge-info': operation.status === 'PROCESSING',
                'badge-success': operation.status === 'COMPLETED',
                'badge-dark': operation.status === 'REJECTED',
                'badge-warning': operation.status === 'WAITING_PAYMENT',
                'badge-danger': operation.status === 'ERROR'
              }">
              {{operationStatusNamed[operation.status]}}
            </span>
          </div>
          <div *ngIf="operation.e2EId">
            <b>ID do pagamento:</b>
            {{operation.e2EId}}
          </div>
          <div *ngIf="operation.type === 'CASH_OUT'">
            <a href="javascript:void(0)" class="d-flex align-items-center"
              (click)="onGenereteCashoutReceipt(operation.pixId)">
              Gerar comprovante
              <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
            </a>
          </div>
          <div
            *ngIf="operation.purpose != 'SERVICE_CHARGE' && operation.purpose != 'WITHDRAW_BALANCE' && operation.purpose != 'ADD_BALANCE'">
            <a href="javascript:void(0)" class="d-flex align-items-center"
              (click)="openDetailsInTransactions(operation._transaction)">
              Mais detalhes em transação
              <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-body confirm-subtitle p-4">
      </div>
    </div>
  </div>
</div>