import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AUTHENTICATE_URL, CHANGE_PASSWORD_URL, CONFIRM_USER_URL, RECOVERY_PASSWORD_URL, USER_URL, USER_INFO_URL } from '../config/settings';
import { AuthGuard } from './auth.guard';
import { Login, Session, SessionData, Slot } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private authGuard: AuthGuard,
  ) { }

  authenticate(login: Login): Observable<SessionData> {
    this.spinner.show();
    return this.http.post<Session>(AUTHENTICATE_URL, login)
      .pipe(
        map((session: Session) => session.data),
        tap((sessionData: SessionData) => {
          const slots: [Slot] = sessionData._slots;
          const mainSlot: Slot = {
            token: sessionData.token,
            name: sessionData.name,
            _id: sessionData._id,
          };
          slots.push(mainSlot);
          this.authGuard.setClubSlots(slots);
          this.authGuard.setSessionData(sessionData);
        }),
        finalize(() => this.spinner.hide())
      );
  }

  /**
   * This method is responsible to make a recovery password requisition
   * @param login user data to login
   */
  recoveryPassword(login: Login): Observable<any> {
    this.spinner.show();
    return this.http.post(RECOVERY_PASSWORD_URL, login).pipe(map((result: any) => {
      const response: any = result.data;
      return response;
    }),
      finalize(() => this.spinner.hide())
    );
  }

  changePassword(login: Login): Observable<any> {
    this.spinner.show();
    return this.http.post(CHANGE_PASSWORD_URL, login).pipe(map((result: any) => {
      const response: any = result.data;
      return response;
    }),
      finalize(() => this.spinner.hide())
    );
  }

  registerRequest(login: Login): Observable<any> {
    this.spinner.show();
    return this.http.post(USER_URL, login).pipe(map((result: any) => {
      const response: any = result.data;
      return response;
    }),
      finalize(() => this.spinner.hide())
    );
  }
  confirmUserBySms(login: Login): Observable<any> {
    this.spinner.show();
    return this.http.post(CONFIRM_USER_URL, login).pipe(map((result: any) => {
      const response: any = result.data;
      return response;
    }),
      finalize(() => this.spinner.hide())
    );
  }

  getUserInfo(): Observable<any> {
    this.spinner.show();
    return this.http.get<any>(USER_INFO_URL)
      .pipe(finalize(() => this.spinner.hide())
      );
  }
}
