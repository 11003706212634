import { Injectable } from "@angular/core";
import { ClubFinancial, ImportedPlayer, RakebackPayment, GameAccountRakebackData } from "./club-financials.model";
import { Observable, of } from "rxjs";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';
import { CLUB_FINANCIALS_DELETE_URL, CLUB_FINANCIAL_PAYMENT_URL, CLUB_FINANCIALS_POST_URL, CLUB_FINANCIALS_RAKEBACK_LIST_URL, CLUB_FINANCIALS_RAKEBACK_PERCENT_URL, CLUB_FINANCIALS_RAKEBACK_SENDER_URL, CLUB_FINANCIALS_URL } from "src/app/config/settings";
import { Criteria } from "./club-financials.model";

@Injectable({
  providedIn: 'root'
})
export class ClubFinancialsService {

  //Remove this after api implementation
  clubFinancialData: ClubFinancial[] = [];

	constructor(
		private http: HttpClient,
		private spinner: NgxSpinnerService,
	) { }

  getImportedPlayersData(): Observable<ImportedPlayer[]> {
    const players = this.clubFinancialData
    .map((entry) => entry.players)
    .reduce((acc, playersArray) => acc.concat(playersArray), []);
     return of(players);
  }

	patchGameAccountRakebackPercent(payload: GameAccountRakebackData): Observable<any> {
    return this.http.patch(`${CLUB_FINANCIALS_RAKEBACK_PERCENT_URL}`, payload)
      .pipe(finalize(() => this.spinner.hide()));
  }
  
  getClubFinancialsData(criteria: Criteria): Observable<any> {
  const params = { page: criteria.page, searchTerm: criteria.searchTerm };
  return this.http.get<any>(CLUB_FINANCIALS_RAKEBACK_SENDER_URL, { params });
}

  getListRakebackPlayers(criteria: any, rakebackSenderId: string): Observable<any> {
    const params = {
      status: criteria.status,
      rakebackSenderId: rakebackSenderId,
      searchTerm: criteria.searchTerm,
      page: criteria.page.toString(),
      totalPages: criteria.totalPages,
      filterBy: criteria.filterBy,
    };
    return this.http.get<any>(CLUB_FINANCIALS_RAKEBACK_LIST_URL, { params })
  }
  getDeleteRakebackPlayer(rakebackPlayerId: string, rakebackSenderId: string): Observable<any> {
    this.spinner.show();
    const body = {
      rakebackPlayerId,
      rakebackSenderId,
    };
    return this.http.delete(`${CLUB_FINANCIALS_DELETE_URL}`, { body })
      .pipe(
        finalize(() => this.spinner.hide())
      );
  }

  postRakeback(file: File): Observable<any> {
    this.spinner.show();
      
    const formData = new FormData();
    formData.append('sheetFile', file);
    
    return this.http.post(`${CLUB_FINANCIALS_POST_URL}`, formData, {}).pipe(  
        map((result: any) => {
          const response: any = result;
          return response;
        }),
        finalize(() => this.spinner.hide())
    );
  }

  createRakebackPayment(data: Array<RakebackPayment>): Observable<any> {
    this.spinner.show();
    return this.http.post<any>(CLUB_FINANCIAL_PAYMENT_URL, data)
      .pipe(map((result: any) => {
        const response: any = result;
        return response;
      }), finalize(() => this.spinner.hide()));
  }

  updateRakebackPlayer(rakebackPlayerId: string, rakebackSenderId: string, isSentManually: Boolean, sentManuallyBy: string, sentManuallyAt: string ): Observable<any> {
    const params = {
      rakebackPlayerId: rakebackPlayerId,
      rakebackSenderId: rakebackSenderId,
      isSentManually: isSentManually,
      sentManuallyBy: sentManuallyBy,
      sentManuallyAt: sentManuallyAt,
    };
    console.log("updatedRakebackPlayer: ", params)
    return this.http.patch(`${CLUB_FINANCIALS_URL}/${rakebackPlayerId}/manually-update`, params );
  }
}
