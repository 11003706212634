<p class="text-muted font-weight-bold">Dados da operação</p>
<div class="bg-light p-3 text-dark rounded-lg">
	<div *ngIf="operation?.createdAt">
		<div *ngIf="operation?.type" class="mb-3 text-break-all">
			<b>Tipo:</b>
			{{operationTypeNamed[operation.type]}}
		</div>
		<div *ngIf="operation?.purpose" class="mb-3 text-break-all">
			<b>Finalidade:</b>
			{{operationPurposeNamed[operation.purpose]}}
		</div>
		<div *ngIf="operation?.type === 'CASH_IN'" class="mb-3 text-break-all">
			<b>Entrada bruta:</b>
			{{ checkValueForGross(operation) | currency:'BRL':'symbol':'1.2-2' }}
		</div>
		<div *ngIf="operation?.type === 'CASH_OUT'" class="mb-3 text-break-all">
			<b>Saída bruta:</b>
			{{ checkValueForGross(operation) | currency:'BRL':'symbol':'1.2-2' }}
		</div>
		<div *ngIf="operation?.type === 'CASH_IN'" class="mb-3 text-break-all">
			<b>Entrada líquida:</b>
			{{ checkValueForLiquid(operation) | currency:'BRL':'symbol':'1.2-2' }}
		</div>
		<div *ngIf="operation?.type === 'CASH_OUT'" class="mb-3 text-break-all">
			<b>Saída líquida:</b>
			{{ checkValueForLiquid(operation) | currency:'BRL':'symbol':'1.2-2' }}
		</div>
		<div *ngIf="operation?.playerId" class="mb-3 text-break-all">
			<b>ID Jogador:</b>
			{{operation?.playerId}}
		</div>
		<div *ngIf="operation?.payer" class="mb-3 text-break-all">
			<b>Pagador:</b>
			{{operation?.payer?.name}}
		</div>
		<div *ngIf="operation?.receiver" class="mb-3 text-break-all">
			<b>Recebedor:</b>
			{{operation?.receiver?.name}}
		</div>
		<div *ngIf="operation?.fee && operation?.purpose != 'SERVICE_CHARGE'" class="mb-3 text-break-all">
			<b>Taxa da operação:</b>
			{{ convertAmountToString(operation.fee) }}
		</div>
		<div *ngIf="operation?.status" class="text-break-all">
			<b>Status:</b>
			<span class="ml-1 badge badge-pill font-weight-normal" [ngClass]="{
                'badge-info': operation?.status === 'PROCESSING',
                'badge-success': operation?.status === 'COMPLETED',
                'badge-dark': operation?.status === 'REJECTED',
                'badge-warning': operation?.status === 'WAITING_PAYMENT',
                'badge-danger': operation?.status === 'ERROR'
              }">
				{{operationStatusNamed[operation.status]}}
			</span>
		</div>
	</div>
</div>