import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { Transaction, TransactionResponse } from 'src/app/pages/transactions/transaction.model';
import { TransactionService } from 'src/app/pages/transactions/transaction.service';
import { ClaimbackPaymentType, ClaimbackPaymentTypeNamed } from 'src/app/shared/control.enum';
import { NotificationService } from 'src/app/shared/notification.service';
import { AutoClaimbackErrorCode, TransactionStatus, TransactionStatusNamed } from 'src/app/shared/transaction.enum';
import { CashoutReceipt } from './../../shared/cashout-receipt.model';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.scss']
})
export class WithdrawModalComponent {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;
  @Output() paymentConfirmed = new EventEmitter();

  title!: string;
  transaction = {} as Transaction;
  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  pixAccountName!: string;
  pixKey!: string;
  data!: string;
  chips!: number;
  amount!: number;
  status!: TransactionStatus;
  errorCode!: number;

  isManualModal!: boolean;

  public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

  public get claimbackPaymentTypeEnum(): typeof ClaimbackPaymentType {
    return ClaimbackPaymentType;
  }

  public get claimbackPaymentTypeNamedEnum(): typeof ClaimbackPaymentTypeNamed {
    return ClaimbackPaymentTypeNamed;
  }

  public get autoClaimbackErrorCodeEnum(): typeof AutoClaimbackErrorCode {
    return AutoClaimbackErrorCode;
  }

  constructor(
    public service: TransactionService,
    private notificationService: NotificationService,
    private clipboardService: ClipboardService,
  ) { }

  /**
   * Open modal
   */
  public openModal(transactionId: string): void {
    this.service.getTransactionById(transactionId).subscribe(
      (transactionResponse: TransactionResponse) => {
        this.transaction = transactionResponse.data;
        this.name = this.transaction._user.name;
        this.email = this.transaction._user.email;
        this.ddd = this.transaction._user.ddd;
        this.phoneNumber = this.transaction._user.phoneNumber;
        this.pixAccountName = this.transaction._user.paymentData.pixAccountName;
        this.pixKey = this.transaction._user.paymentData.pixKey;
        this.playerAccountId = this.transaction._gameAccount.playerAccountId;
        this.data = this.transaction.createdAt;
        this.chips = this.transaction.amount;
        this.amount = this.transaction.amount;
        this.status = this.transaction.status;
        this.errorCode = this.transaction.errorCode;

        if (this.status === this.transactionStatus.WAITING_PAYMENT &&
          (this.transaction.claimbackPaymentType === this.claimbackPaymentTypeEnum.MANUALLY ||
            (this.claimbackPaymentTypeEnum.AUTOMATIC && this.transaction && this.transaction.autoClaimbackErrorCode))) {
          this.isManualModal = true;
        } else {
          this.isManualModal = false;
        }

        this.autoShownModal.show();
        return this.transaction;
      }
    );
  }

  public updateAsPaid(): void {
    this.service.patchWithdrawTransactionById(this.transaction._id)
      .subscribe(
        () => {
          this.closeModal();
          this.status = this.transaction.status;
          this.paymentConfirmed.emit();
          this.notificationService.showSuccess('', 'Pagamento confirmado');
        },
        () => {
          this.notificationService
            .showError('Não foi possível realizar o pagamento, tente novamente mais tarde', 'Erro ao pagar');
        });
  }

  /**
   * Handle event of the confirm button
   */
  public onConfirm(): void {
    this.updateAsPaid();
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.autoShownModal.hide();
  }

  copyContent(): void {
    this.clipboardService.copyFromContent(this.transaction.pixPaymentCode);
  }

  copied(event: any): void {
    if (event.isSuccess === true) {
      this.notificationService.showSuccess('', 'Código copiado!');
    }
  }

  onGenereteCashoutReceipt(pixId: string) {
    this.service.getCashoutReceipt(pixId)
    .subscribe(
      (cashoutReceipt: Blob) => {
        const fileURL = URL.createObjectURL(cashoutReceipt);
        
        window.open(fileURL, '_blank');
        
        const downloadAnchor = document.createElement('a');
        downloadAnchor.href = fileURL;
        downloadAnchor.download = 'Comprovante.pdf';
        
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
        
        URL.revokeObjectURL(fileURL);
      }
    );
  }
}
