import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { Transaction, TransactionResponse } from "../../transaction.model";
import { TransactionService } from "../../transaction.service";
import { NotificationService } from "src/app/shared/notification.service";
import { TransactionStatus, TransactionStatusNamed } from "src/app/shared/transaction.enum";

@Component({
	selector: 'app-transfer-details',
	templateUrl: './transfer-details.component.html',
	styleUrls: ['./transfer-details.component.scss']
})
export class TransferDetailsComponent implements OnInit, OnChanges {

	@Input('isOpen') isOpen: boolean = false;
	@Input('transaction') transaction: Transaction = {} as Transaction;

	title!: string;
  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  data!: string;
  chips!: number;
  amount!: number;
  status!: TransactionStatus;
  errorCode!: number;

	isSkeletonLoading: boolean = false;

	constructor(
		public service: TransactionService,
    private notificationService: NotificationService,
	) { }

	public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

	ngOnInit(): void { }

	ngOnChanges(): void {
		if (this.isOpen) {
			this.getTransaction(this.transaction?._id);
		} else {
			this.isSkeletonLoading = true;
		}
	}

	getTransaction(transactionId: string) {
		this.service.getTransactionById(transactionId).subscribe(
      (transactionResponse: TransactionResponse) => {
        this.transaction = transactionResponse.data;
        this.name = this.transaction._user.name;
        this.email = this.transaction._user.email;
        this.ddd = this.transaction._user.ddd;
        this.phoneNumber = this.transaction._user.phoneNumber;
        this.playerAccountId = this.transaction._gameAccount.playerAccountId;
        this.data = this.transaction.createdAt;
        this.chips = this.transaction.amount;
        this.amount = this.transaction.amount;
        this.status = this.transaction.status;
        this.errorCode = this.transaction.errorCode;

        return this.transaction;
      }
    );
	}

}