export default class Constants {
  public static readonly LOADING_MAX_TIME = 15000;

  // Http error status
  public static readonly UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS';
  public static readonly NOT_CONFIRMED_ACCESS = 'NOT_CONFIRMED_ACCESS';
  public static readonly ENTITY_ON_BODY_NOT_FOUND = 'ENTITY_ON_BODY_NOT_FOUND';
  public static readonly ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND';
  public static readonly ENTITY_ALREADY_USED = 'ENTITY_ALREADY_USED';
  public static readonly INVALID_ACTIVATION_CODE = 'INVALID_ACTIVATION_CODE';
  public static readonly INVALID_REQUEST_PARAMETER = 'INVALID_REQUEST_PARAMETER';
  public static readonly ACTIVATION_ATTEMPTS_EXCEEDED = 'ACTIVATION_ATTEMPTS_EXCEEDED';
  public static readonly CREATE_FINANCE_ACCOUNT_FIRST = 'CREATE_FINANCE_ACCOUNT_FIRST';

  public static readonly INTEGRATION_PAYMENT_ERROR = 'INTEGRATION_PAYMENT_ERROR';
  public static readonly PIX_NOT_VALID = 'PIX_NOT_VALID';

  public static readonly ACCOUNT_HAS_NO_BALANCE = 'ACCOUNT_HAS_NO_BALANCE';
}
