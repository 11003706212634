import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { ClubFinancialStatus, ClubFinancialStatusNamed, ImportedPlayer, Pageable } from "../../club-financials.model";
import { NotificationService } from "src/app/shared/notification.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { DeleteRakeModalComponent } from "src/app/modals/delete-rake-modal/delete-rake-modal.component";
import { ClubFinancialsService } from "../../club-financials.service";
import { Criteria } from "../../club-financials.model";
import { Subject } from 'rxjs';
import { finalize, retry, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-financial-import-list',
	templateUrl: './financial-import-list.component.html',
	styleUrls: ['./financial-import-list.component.scss'],
})
export class FinancialImportListComponent implements OnInit {

	@ViewChild('percentageInput', { static: false }) percentageInput!: ElementRef;
	@ViewChild('deleteRakeModalComponent', { static: true }) public deleteRakeModalComponent!: DeleteRakeModalComponent;

	@Output() playerPercentageUpdated = new EventEmitter<any>();
	@Output() playerSelected = new EventEmitter<any>();
	@Output() rakebackPlayerDeleted = new EventEmitter<any>();
	@Output() getRakebackPlayerList = new EventEmitter<any>();

	@Input('rakebackPlayers') rakebackPlayers: ImportedPlayer[] = [];
	@Input('criteria') criteria: Criteria = new Criteria();
	@Input('selectedFinancialID') selectedFinancialID: string = '';

	editing = false;
	editingPlayerId: string | null = null;
	errorMessage: string | null = null;
	selectedPlayer!: any;
	maxSize = 3;  
	rotatePagination = true;

	public get clubFinancialStatus(): typeof ClubFinancialStatus {
		return ClubFinancialStatus;
	}

	public get clubFinancialStatusNamed(): typeof ClubFinancialStatusNamed {
		return ClubFinancialStatusNamed;
	}

	constructor(
		private cdr: ChangeDetectorRef,
		private notificationService: NotificationService,
	) { }

	ngOnInit(): void { }
	
  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
		this.criteria.page = paginationEvent;
		this.getRakebackPlayerList.emit(this.criteria);
  }

	editPercentage(player: ImportedPlayer) {
		if(player.status === this.clubFinancialStatus.NOT_STARTED) {
			if (this.editing && this.errorMessage) {
				if (this.editingPlayerId === player._id) {
					return;
				} else {
					this.notificationService.showError('Preencha o campo da porcentagem corretamente', 'Erro ao editar porcentagem');
					return;
				}
			}
	
			this.editing = true;
			this.editingPlayerId = player._id;
			this.errorMessage = null;
	
			setTimeout(() => {
				this.percentageInput?.nativeElement.focus();
			}, 0);
		} else {
			return;
		}
		this.cdr.detectChanges();
	}

	validatePercentage(player: ImportedPlayer) {
		if (player.rakebackPercent === null || player.rakebackPercent < 1 || player.rakebackPercent > 100) {
			this.errorMessage = 'Porcentagem precisa ser entre 1 e 100.';
		} else {
			this.errorMessage = null;
			this.editing = false;
			this.editingPlayerId = null;

			if (player.status === 'NO_PERCENTAGE_DEFINED') {
				player.status = 'NOT_STARTED';
				player.rake = 300;
			}
			this.notificationService.showSuccess('O valor da porcentagem foi alterado', 'Porcentagem alterada com sucesso!');
			this.playerPercentageUpdated.emit([{ _gameAccount: player._gameAccount, rakebackPercent: player.rakebackPercent }]);
		}
		this.cdr.detectChanges();
	}

	getFinancialStatus(countSuccess: number = 0, countErrors: number = 0, totalPlayers: number = 0): string {
		const totalProcessed = countSuccess + countErrors;

		if (totalProcessed === 0) return "NOT_STARTED";
		if (totalProcessed < totalPlayers) return "STARTED";
		if (countErrors > 0) return "COMPLETED_WITH_ERROR";

		return "COMPLETED";
	}

	openDetailedPanel(playerSelected: any) {
		this.selectedPlayer = playerSelected;
		this.setSelectedPlayer(this.selectedPlayer);
	}

	setSelectedPlayer(player: any) {
		this.playerSelected.emit(player);
	}

	clearPlayerSelected() {
		this.selectedPlayer = null;
	}

	openDeleteRakeModal(rakebackPlayer: any): void {
		this.deleteRakeModalComponent.rakebackPlayer = rakebackPlayer;
		this.deleteRakeModalComponent.openModal();
	}

	onPlayerDelete(isRakebackPlayerDelete: any): void {
		this.rakebackPlayerDeleted.emit(isRakebackPlayerDelete);
		this.cdr.detectChanges();
	}

	closeClubPercentsModal(): void {
		this.deleteRakeModalComponent.closeModal();
	}
}