import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { InviteNewPlayersComponent } from "./invite-new-players.component";
import { NgxMaskModule } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
  declarations: [
    InviteNewPlayersComponent
  ],
  exports: [
    InviteNewPlayersComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ]
})
export class InviteNewPlayersModule { }