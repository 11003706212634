<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" [ngClass]="{ 'modal-lg': isManualModal}"
    role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">{{ (status ===
          transactionStatus.WAITING_PAYMENT)? 'Pagar jogador' : 'Informações da transação' }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body row no-gutters p-0">
        <div class="p-0" [ngClass]="(isManualModal)? 'col-lg-6' : 'col-12'">
          <div class="px-4 pt-4 pb-0 h-100" *ngIf="isManualModal">
            <h5>Pagar com PIX</h5>
            <p class="text-muted m-0">
              Escaneie o QR Code, ou copie o código PIX para pagar o jogador.
            </p>
            <div class="d-flex justify-content-center">
              <qrcode [qrdata]="this.transaction.pixPaymentCode" [width]="350" colorDark [level]="'M'"></qrcode>
            </div>
            <div class="input-group has-validation">
              <input disabled type="text" id="disabledTextInput" class="form-control border"
                [placeholder]="this.transaction.pixPaymentCode">
              <div class="input-group-append">
                <button class="btn btn-outline-white border text-muted" ngxClipboard *ngxClipboardIfSupported
                  [cbContent]="'target string'" (cbOnSuccess)="copied($event)" (click)="copyContent()"
                  type="button">Copiar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="p-0" [ngClass]="isManualModal? 'col-lg-6' : 'col-12'">
          <div class="p-4">
            <h5>Dados do Jogador</h5>
            <div class="bg-light px-4 py-3 text-dark rounded-lg">
              <div>
                <b>Nome:</b>
                {{name}}
              </div>
              <div>
                <b>ID do jogador:</b>
                {{playerAccountId}}
              </div>
              <div>
                <b>E-mail:</b>
                {{email}}
              </div>
              <div>
                <b>Número do telefone:</b>
                {{ddd + phoneNumber | mask: '(00) 00000-0000'}}
              </div>
              <div>
                <b>Nome da conta PIX:</b>
                {{pixAccountName}}
              </div>
              <div>
                <b>Chave PIX:</b>
                {{pixKey}}
              </div>
            </div>
            <h5 class="mt-4">{{ (status ===
              transactionStatus.WAITING_PAYMENT)? 'Solicitação do Jogador' : 'Dados da solicitação de saque' }}
            </h5>
            <div class="bg-light px-4 py-3 text-dark rounded-lg">
              <div>
                <b>Valor:</b>
                {{amount / 100 | currency:'BRL':'symbol':'1.2-2'}}
              </div>
              <div>
                <b>Data:</b>
                {{data | date: 'd/M/yy'}}
              </div>
              <div>
                <b>Horário:</b>
                {{data | date: 'HH:mm'}}
              </div>
              <div [ngSwitch]="status">
                <b>Status:</b>
                <span class="badge badge-pill badge-info font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.PROCESSING'>{{transactionStatusNamed[transactionStatus.PROCESSING]}}</span>
                <span class="badge badge-pill badge-success font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.DONE'>{{transactionStatusNamed[transactionStatus.DONE]}}</span>
                <span class="badge badge-pill badge-dark font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.REFUNDED'>{{transactionStatusNamed[transactionStatus.REFUNDED]}}</span>
                <span class="badge badge-pill badge-warning text-wrap font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.WAITING_PAYMENT'>{{transactionStatusNamed[transactionStatus.WAITING_PAYMENT]}}</span>
                <span class="badge badge-pill badge-success font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.PAID'>{{transactionStatusNamed[transactionStatus.PAID]}}</span>
                <span class="badge badge-pill badge-danger text-wrap font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.ERROR'>{{transactionStatusNamed[transactionStatus.ERROR]}}</span>
                <span class="badge badge-pill badge-info text-wrap font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.WITHDRAWING_CHIPS'>{{transactionStatusNamed[transactionStatus.WITHDRAWING_CHIPS]}}</span>
                <span class="badge badge-pill badge-info text-wrap font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.SENDING_CHIPS'>{{transactionStatusNamed[transactionStatus.SENDING_CHIPS]}}</span>
                <span class="badge badge-pill badge-warning text-wrap font-weight-normal ml-2"
                  *ngSwitchCase='transactionStatus.NOT_PAID'>{{transactionStatusNamed[transactionStatus.NOT_PAID]}}</span>
              </div>
              <div [ngSwitch]="transaction.claimbackPaymentType">
                <b>Tipo do saque:</b>
                <ng-container *ngSwitchCase='claimbackPaymentTypeEnum.AUTOMATIC'>
                  {{claimbackPaymentTypeNamedEnum[claimbackPaymentTypeEnum.AUTOMATIC]}}</ng-container>
                <ng-container *ngSwitchCase='claimbackPaymentTypeEnum.MANUALLY'>
                  {{claimbackPaymentTypeNamedEnum[claimbackPaymentTypeEnum.MANUALLY]}}</ng-container>
              </div>
            </div>
            <ng-container *ngIf="transaction.receipt">
              <h5 class="mt-4">Verificação</h5>
              <div class="bg-light px-4 py-3 text-dark rounded-lg">
                <div>
                  <b>Nick do jogador:</b>
                  {{transaction.receipt?.currentAccountName}}
                </div>
                <div *ngIf="transaction.receipt?.givenNickName != ''">
                  <b>Nick definido:</b>
                  {{transaction.receipt?.givenNickName}}
                </div>
                <div>
                  <b>ID do jogador:</b>
                  {{transaction.receipt?.playerId}}
                </div>
                <div>
                  <b>Saldo alterado:</b>
                  {{transaction.receipt?.previousValue}}
                  <i class="bi bi-arrow-right"></i>
                  {{transaction.receipt?.currentValue}}
                </div>
                <div>
                  <b>Fichas retiradas:</b>
                  {{transaction.receipt?.differenceValue}}
                </div>
                <div *ngIf="transaction && transaction.receipt && transaction.receipt.pixId">
                  <a href="javascript:void(0)" class="d-flex align-items-center"
                    (click)="onGenereteCashoutReceipt(transaction.receipt.pixId)">
                    Gerar comprovante
                    <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-12" *ngIf="status === transactionStatus.ERROR && errorCode" [ngSwitch]="errorCode">
          <div class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small *ngSwitchCase="107">
                <p class="m-0">
                  Esse jogador não pertence mais ao clube.
                </p>
              </small>
              <small *ngSwitchCase="142">
                <p class="m-0">
                  O jogador não possui fichas suficientes para sacar.
                </p>
              </small>
              <small *ngSwitchDefault>
                <p class="m-0">
                  Este erro não é comum. Comunique a equipe Chippix sobre este erro:
                  {{errorCode}}.
                </p>
              </small>
            </div>
          </div>
        </div>
        <div class="col-12"
          *ngIf="status === transactionStatus.WAITING_PAYMENT && transaction.claimbackPaymentType === claimbackPaymentTypeEnum.MANUALLY">
          <div class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-info mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-info-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  Sempre confira o nome da conta PIX em <strong>Dados do Jogador</strong> com o PIX a ser pago.
                </p>
                <p class="m-0">
                  Após marcar como <strong>Pago</strong> o status da transação será alterado.
                </p>
              </small>
            </div>
          </div>
          <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.TWO_STEP_NOT_CONFIRMED"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  <strong>A segunda verificação de retiradas de fichas não foi concluída</strong>. Por favor,
                  verifique se as fichas foram retiradas corretamente e realize o pagamento de forma
                  <strong>Manual</strong>.
                </p>
              </small>
            </div>
          </div>
        </div>
        <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.MANUAL_WITHDRAW_STATE"
          class="px-4 pt-0 pb-4">
          <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
              <p class="m-0">
                Esta transação está configurada para ser efetuada manualmente,
              </p>
              <p class="m-0">
                Após marcar como <strong>Pago</strong> o status da transação será alterado.
              </p>
            </small>
          </div>
        </div>
        <div class="col-12"
          *ngIf="status === transactionStatus.WAITING_PAYMENT && transaction.claimbackPaymentType === claimbackPaymentTypeEnum.AUTOMATIC">
          <div *ngIf="transaction.autoClaimbackErrorCode" class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-info mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-info-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  Sempre confira o nome da conta PIX em <strong>Dados do Jogador</strong> com o PIX a ser pago.
                </p>
                <p class="m-0">
                  Após marcar como <strong>Pago</strong> o status da transação será alterado.
                </p>
              </small>
            </div>
          </div>
          <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_DESTINATION_NOT_CREATED"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  <strong>Chave PIX do jogador não encontrada</strong>. Não foi possível realizar o pagamento da
                  solicitação de saque de forma <strong>Automática</strong>, verifique a chave PIX do jogador para
                  realizar o pagamento de forma <strong>Manual</strong>.
                </p>
              </small>
            </div>
          </div>
          <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.CLUB_HAS_NO_BALANCE"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  <strong>Clube não possui saldo</strong>. Não foi possível realizar o pagamento da solicitação de saque
                  de
                  forma <strong>Automática</strong>, realize o pagamento de forma <strong>Manual</strong>.
                </p>
              </small>
            </div>
          </div>
          <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.TWO_STEP_NOT_CONFIRMED"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  <strong>A segunda verificação de retiradas de fichas não foi concluída</strong>. Não foi possível
                  realizar o pagamento da
                  solicitação de saque de forma <strong>Automática</strong>. Por favor, verifique se as fichas foram
                  retiradas corretamente e realize o pagamento de forma <strong>Manual</strong>.
                </p>
              </small>
            </div>
          </div>
          <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.VALUE_EXCEEDED"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  As fichas foram retiradas e verificadas, porém o limite definido para saque automático foi excedido.
                </p>
              </small>
            </div>
          </div>
          <div *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_REFUSED"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  As fichas foram retiradas e verificadas, porém a instituição do jogador recusou o pagamento.
                </p>
              </small>
            </div>
          </div>
          <div
            *ngIf="transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_NOT_STARTED || transaction.autoClaimbackErrorCode === autoClaimbackErrorCodeEnum.PIX_NOT_CONFIRMED"
            class="px-4 pt-0 pb-4">
            <div class="rounded-0 border-0 alert alert-danger mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-x-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  Não foi possível realizar o pagamento da solicitação de saque de forma <strong>Automática</strong>.
                  Por favor, realize o pagamento de forma <strong>Manual</strong>.
                </p>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-light border-light d-flex justify-content-right"
        *ngIf="status === transactionStatus.WAITING_PAYMENT && (transaction.claimbackPaymentType === claimbackPaymentTypeEnum.MANUALLY ||
        (transaction.claimbackPaymentType === claimbackPaymentTypeEnum.AUTOMATIC && transaction.autoClaimbackErrorCode))">
        <button type="button" class="btn btn-primary" (click)="onConfirm()">Pago</button>
      </div>
      <!-- <div class="modal-footer bg-light border-light d-flex justify-content-right">
        <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ (status ===
          transactionStatus.WAITING_PAYMENT)? 'Confirmar pagamento' : 'Salvar alterações' }}</button>
      </div> -->
    </div>
  </div>
</div>