import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { OperationDetailsComponent } from "./operation-details.component";

@NgModule({
	imports: [
		BrowserModule,
		CommonModule,
		NgbModule,
		RouterModule,
		NgxSkeletonLoaderModule,
		NgxMaskModule
	],
	declarations: [
		OperationDetailsComponent
	],
	exports: [
		OperationDetailsComponent
	],
	bootstrap: [
		OperationDetailsComponent
	]
})
export class OperationDetailsModule { }