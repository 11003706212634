import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Transaction } from 'src/app/pages/transactions/transaction.model';
import { TransactionService } from 'src/app/pages/transactions/transaction.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { TransactionStatus, TransactionStatusNamed } from 'src/app/shared/transaction.enum';
import { TransactionResponse } from './../../pages/transactions/transaction.model';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss']
})
export class TransferModalComponent {

  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  title!: string;
  transaction!: Transaction;
  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  data!: string;
  chips!: number;
  amount!: number;
  status!: TransactionStatus;
  errorCode!: number;

  @Output() paymentRefounded = new EventEmitter();

  constructor(
    public service: TransactionService,
    private notificationService: NotificationService,
  ) { }

  public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

  /**
   * Open modal
   */
  public openModal(transactionId: string): void {
    this.autoShownModal.show();
    this.service.getTransactionById(transactionId).subscribe(
      (transactionResponse: TransactionResponse) => {
        this.transaction = transactionResponse.data;
        this.name = this.transaction._user.name;
        this.email = this.transaction._user.email;
        this.ddd = this.transaction._user.ddd;
        this.phoneNumber = this.transaction._user.phoneNumber;
        this.playerAccountId = this.transaction._gameAccount.playerAccountId;
        this.data = this.transaction.createdAt;
        this.chips = this.transaction.amount;
        this.amount = this.transaction.amount;
        this.status = this.transaction.status;
        this.errorCode = this.transaction.errorCode;

        return this.transaction;
      }
    );
  }

  public refundTransaction(): void {
    this.service.postRefundTransaction(this.transaction._id, { refundStatus: true })
      .subscribe(() => {
        this.status = this.transaction.status;
        this.closeModal();
        this.notificationService.showSuccess('A transação foi revertida com sucesso', 'Transação revertida');
        this.paymentRefounded.emit();
      }, (error) => {
        switch (error.status) {
          case 400:
            if (error.error.message.errors[0].message.includes('Saldo insuficiente.')) {
              this.notificationService.showError('Não foi possivel realizar o reembolso', 'Saldo insuficiente');
            }
            break
          default:
            this.notificationService.showError('Não foi possivel realizar o reembolso', 'Reembolso negado');
            break
        }
      });
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.autoShownModal.hide();
  }
}
