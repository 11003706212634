import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NotificationService } from "src/app/shared/notification.service";
import { InviteNewPlayersService } from "./invite-new-players.service";
import { InvitedPlayer, UnrelatedPlayerStatus, UnrelatedPlayerStatusNamed } from "./invite-new-players.model";
import { Criteria } from "src/app/pages/players/player.model";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-invite-new-players',
  templateUrl: './invite-new-players.component.html',
  styleUrls: ['./invite-new-players.component.scss']
})
export class InviteNewPlayersComponent implements OnInit, OnChanges {

  @ViewChild('phoneNumberInput', { static: false }) phoneNumberInput!: ElementRef;

  @Input("data") data!: any[];
  @Input("criteria") criteria: Criteria = new Criteria();
  @Output() onCriteriaChange = new EventEmitter();
  @Output() onInvitedPlayerModified = new EventEmitter();
  @Input("type") type!: any;
  @Input() alertText!: string;
  @Input("newPlayerId") newPlayerId!: any;
  @Input("isNewPlayer") isNewPlayer!: boolean;

  playerData: any = [];

  hasPhoneInputFocus: boolean = false;

  newPlayerPhoneNumber: string = '';
  errorMessage: string | null = null;
  editingPlayerId: string | undefined = undefined;
  
  editingState = new Map<string | undefined, { isEditing: boolean; isInvited: boolean, phoneInput: string }>();

  maxSize = 3;
  rotatePagination = true;
  
  constructor(
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private inviteService: InviteNewPlayersService,
    private spinner: NgxSpinnerService,
  ) { }

  public get unrelatedPlayerStatus(): typeof UnrelatedPlayerStatus {
    return UnrelatedPlayerStatus;
  }

  public get unrelatedPlayerStatusNamed(): typeof UnrelatedPlayerStatusNamed {
    return UnrelatedPlayerStatusNamed;
  }

  ngOnInit(): void {
    this.setFormatPhoneNumber(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    const newPlayerIdChange = changes.newPlayerId?.currentValue;

    if (newPlayerIdChange && this.isPlayerAlreadyInvited(newPlayerIdChange)) {
      this.isNewPlayer = false;
      this.newPlayerId = '';
      this.notificationService.showError(
        'Não foi possível realizar esta ação, pois o usuário já existe. Convide outro jogador.',
        'Usuário já existe'
      );
    }
  }

  setFormatPhoneNumber(data: InvitedPlayer[]) {
    data.forEach((data: InvitedPlayer) => {
      this.editingState.set(data._id, { isEditing: false, isInvited: data._inviter ? true : false, phoneInput: '' });
    });
  }

  onInvitePlayer(player: InvitedPlayer) {
    const state = this.editingState.get(player._id);

    if (this.isValidPhone(player.phoneNumber)) {
      if (state) {
        const { ddd, phoneNumber } = this.extractPhoneDetails(state.phoneInput);
        const playerData = {
          playerAccountId: player.playerAccountId,
          ddd: ddd || player.ddd,
          phoneNumber: phoneNumber || player.phoneNumber,
          platform: 'SUPREMA',
          accountAlias: ''
        };
        
        if (playerData.ddd && playerData.phoneNumber) {
          state.isEditing = false;
          state.isInvited = true;
          this.sendInvitation(playerData);
        } else {
          this.notificationService.showError('Para enviar o convite, é necessário preencher o campo Telefone', 'Telefone obrigatório');
        }
      }
    } else {
      this.notificationService.showError('Para enviar o convite, é necessário preencher o campo Telefone', 'Telefone obrigatório');
    }
  }

  sendInvitation(playerData: InvitedPlayer) {
    this.spinner.show();
    this.inviteService.inviteNewPlayer(playerData)
      .subscribe(
        () => {
          this.onInvitedPlayerModified.emit(this.criteria);
          this.notificationService.showSuccess('Seu convite foi enviado com sucesso para o novo jogador!', 'Convite enviado com sucesso!');
        },
        (error) => {
          switch (error.status) {
            case 403:
              this.spinner.hide();
              this.notificationService.showError('Não foi possivel realizar esta ação, o usuário selecionado está banido', 'Usuário Banido');
              break;
            case 409:
              this.spinner.hide();
              this.notificationService.showError('Não foi possivel realizar esta ação, pois o usuário já existe', 'Usuário já existe');
              break;
            default:
              this.spinner.hide();
              this.notificationService.showError('Algum erro ocorreu durante o processo', 'Erro');
              break;
          }
        }
      );
  }

  deleteInvitation(playerData: InvitedPlayer) {
    this.spinner.show();
    this.inviteService.deleteInvitedPlayer(playerData)
      .subscribe(
        (response) => {
          this.onInvitedPlayerModified.emit(this.criteria);
          this.notificationService.showSuccess('O convite para o novo jogador foi cancelado com sucesso!', 'Convite cancelado com sucesso!');
        },
        (error) => {
          this.notificationService.showError('Algum erro ocorreu durante o processo', 'Erro');
        }
      );
  }

  onAddNewPlayer() {
    this.isNewPlayer = !this.isNewPlayer
    if (!this.isNewPlayer) {
      this.newPlayerId = '';
      this.newPlayerPhoneNumber = '';
    }
  }
  
  saveNewPlayer() {
    let newPlayer: InvitedPlayer = {
      playerAccountId: this.newPlayerId,
      ddd: this.newPlayerPhoneNumber.substring(0, 2),
      phoneNumber: this.newPlayerPhoneNumber.substring(2),
      platform: 'SUPREMA',
      accountAlias: '',
    };

    if (newPlayer) {

      if (!newPlayer.ddd || !newPlayer.phoneNumber) {
        this.notificationService.showError('Para adicionar um novo jogador, é necessário preencher os campos Jogador ID e Telefone', 'Campos obrigatórios');
      } else {
        this.isNewPlayer = false;
        this.newPlayerId = '';
        this.newPlayerPhoneNumber = '';
        this.sendInvitation(newPlayer);
        if (this.criteria.tab !== 'invited'){
          this.notificationService.showSuccess('O novo jogador foi adicionado com sucesso', 'Jogador adicionado com sucesso!');
        }
      }
      this.setFormatPhoneNumber(this.data);
      this.cdr.detectChanges();
    }
  }

  onEditPhoneNumber(player: InvitedPlayer) {
    const state = this.editingState.get(player._id);
    if (player._inviter) {
      if (state) {
        state.isEditing = false;
      }

      this.errorMessage = null;
      this.editingPlayerId = undefined;
      return;
    }

    if (this.errorMessage && this.editingPlayerId && this.editingPlayerId !== player._id) {
      this.notificationService.showError(
        'Preencha o campo de telefone corretamente',
        'Erro ao editar telefone'
      );
      return;
    }

    this.editingPlayerId = player._id

    if (state) {
      state.isEditing = true;
      state.phoneInput = player.phoneNumber ? '+55 (' + player.ddd + ') ' + this.formatPhoneNumber(player.phoneNumber) : '';
      this.editingPlayerId = player._id;
      this.errorMessage = null;
    }

    this.setFocusOnPhoneNumberInput()
    this.cdr.detectChanges();
  }

  setFocusOnPhoneNumberInput() {
    setTimeout(() => {
      this.phoneNumberInput?.nativeElement.focus();
      this.cdr.detectChanges();
    }, 0);
  }

  validatePhoneNumber(player: InvitedPlayer) {
    const state = this.editingState.get(player._id);
    if (state) {
      const regex = /^\+55\s\(\d{2}\)\s?\d\s\d{4}-\d{4}$/;

      if (regex.test(state.phoneInput)) {
        state.isEditing = false;
        const phoneDetails = this.extractPhoneDetails(state.phoneInput);
        player.ddd = phoneDetails.ddd;
        player.phoneNumber = phoneDetails.phoneNumber;
        this.errorMessage = null;
      } else {
        this.errorMessage = "O número do telefone deve ser válido.";
      }
    }
  }

  updatePhoneInput(event: any, player: InvitedPlayer) {
    const value = event.target.value
    const state = this.editingState.get(player._id);
    if (state) {
      state.phoneInput = value; 
      this.validatePhoneNumber(player);
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber.length === 9) {
      return phoneNumber.charAt(0) + ' ' + phoneNumber.substring(1, 5) + '-' + phoneNumber.substring(5);
    } else if (phoneNumber.length === 8) {
      return phoneNumber.substring(0, 4) + '-' + phoneNumber.substring(4);
    }
    return phoneNumber;
  }

  extractPhoneDetails(fullPhone: string) {
    const parts = fullPhone.match(/\d+/g);
    if (!parts || parts.length < 3) {
      this.errorMessage = "O número do telefone deve ser válido.";
      return { ddd: '', phoneNumber: '' };
    }

    const ddd = parts[1];
    let phoneNumber = parts.slice(2).join('');

    if (phoneNumber.length === 9) {
      phoneNumber = phoneNumber.charAt(0) + ' ' + phoneNumber.substring(1, 5) + '-' + phoneNumber.substring(5);
    } else if (phoneNumber.length === 8) {
      phoneNumber = phoneNumber.substring(0, 4) + '-' + phoneNumber.substring(4);
    }

    return { ddd, phoneNumber };
  }

  isValidPhone(phoneNumber: string): boolean {
    return phoneNumber.length === 9;
  }

  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.onCriteriaChange.emit(this.criteria.page);
  }

  isPlayerAlreadyInvited(playerId: any): boolean {
    return this.data.some(player => player.playerAccountId === playerId);
  }
}