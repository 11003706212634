<nav class="navbar navbar-expand-xxl navbar-dark sticky-top bg-primary flex-xl-nowrap p-2 shadow">
  <div class="d-flex align-items-center">
    <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#sidebarMenu"
      aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="position-relative club-wrapper">
      <div class="border border-dark btn-group btn-group-toggle d-flex align-items-center justify-content-start" data-toggle="buttons">
        <button class="btn btn-primary text-left d-flex align-items-center justify-content-between" type="button"
          data-toggle="collapse" data-target="#slotCollapse" aria-expanded="false" aria-controls="slotCollapse"
          style="width: 184px; height: 50px;">
          <span class="d-inline-block text-truncate" style="max-width: 150px;">
            {{clubName}}
          </span>
          <i class="bi bi-caret-down-fill"></i>
        </button>
      </div>
      <div class="collapse position-absolute shadow" id="slotCollapse">
        <div class="list-group">
          <button type="button" class="text-pink-chippix-dark list-group-item list-group-item-action text-truncate"
            disabled style="width: 239px;">
            <i class="bi bi-check-circle mr-3" style="font-size: 1.0rem;"></i>
            {{clubName}}
          </button>
          <button type="button" class="list-group-item list-group-item-action text-truncate" *ngFor="let slot of slots"
            [hidden]="slot.name === clubName" (click)="onSlotSelected(slot)" style="width: 239px;">
            <i class="bi bi-circle mr-3" style="font-size: 1.0rem;"></i>
            {{slot.name}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <a class="btn btn-primary p-0 m-0  d-none d-lg-block d-md-none" href="">
      <img src="assets/x-chip-logo.png" alt="Workflow" class="d-flex p-2" style="width: 55px;">
    </a>
  </div>
  <div>
    <a href="javascript:void(0)" class="navbar-nav navbar-toggler mr-2" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class="bi bi-person-circle text-white" style="font-size: 1.5rem;"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
      <button class="dropdown-item d-flex align-items-center" type="button" (click)="onChangePassoword()">
        <i class="bi bi-key-fill mr-3 text-pink-chippix-dark" style="font-size: 1.5rem;"></i>Trocar senha
      </button>
      <button class="dropdown-item d-flex align-items-center" type="button" (click)="doLogout()">
        <i class="bi bi-box-arrow-right mr-3 text-pink-chippix-dark" style="font-size: 1.5rem;"></i>Sair
      </button>
    </div>
  </div>

  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <div class="btn-group">
          <a href="javascript:void(0)" class="mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bi bi-person-circle text-white" style="font-size: 1.5rem;"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <button class="dropdown-item d-flex align-items-center" type="button" (click)="onChangePassoword()">
              <i class="bi bi-key-fill mr-3 text-pink-chippix-dark" style="font-size: 1.5rem;"></i>Trocar senha
            </button>
            <button class="dropdown-item d-flex align-items-center" type="button" (click)="doLogout()">
              <i class="bi bi-box-arrow-right mr-3 text-pink-chippix-dark" style="font-size: 1.5rem;"></i>Sair
            </button>
          </div>
        </div>
    </ul>
  </div>
</nav>