<div class="h4 font-weight-bold">Ajuda</div>
<div class="container-fluid bg-light">
  <div class="row row-cols-1 row-cols-md-2 justify-content-between">
    <div class="col-md-4 mt-5 p-0">
      <h5>Fale conosco</h5>
      <!-- <p>
        Entre em contato conosco
      </p> -->
    </div>
    <div class="col-md-7 mt-5 p-4 rounded-lg bg-white">
      <a href="https://www.chippix.com.br" target="_blank" class="d-flex align-items-center">
        <i class="bi bi-globe2 mr-2"></i>
        www.chippix.com.br
        <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
      </a>
      <a href="mailto: chippix@chippix.com" target="_blank" class="mt-2 d-flex align-items-center">
        <i class="bi bi-envelope-fill mr-2"></i>
        contato@chippix.com.br
        <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
      </a>
      <a href="https://wa.me/+5535991523525" target="_blank" class="mt-2 d-flex align-items-center">
        <i class="bi bi-whatsapp mr-2"></i>
        (35) 99152-3525
        <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
      </a>
      <a href="https://wa.me/+5535997686501" target="_blank" class="mt-2 d-flex align-items-center">
        <i class="bi bi-whatsapp mr-2"></i>
        (35) 99768-6501
        <i class="bi bi-arrow-up-right-square-fill ml-2"></i>
      </a>
    </div>
  </div>
</div>
