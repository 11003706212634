import { NgModule } from "@angular/core";
import { NgxCurrencyModule } from "ngx-currency";
import { CustomizeWithdrawComponent } from "./customize-withdraw.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { customCurrencyMaskConfig } from "src/app/config/custom-currency-mask.config";

  @NgModule({
    declarations: [
      CustomizeWithdrawComponent,
    ],
    exports: [CustomizeWithdrawComponent],
    imports: [
      CommonModule,
      NgbModule,
      NgxSkeletonLoaderModule,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      NgxMaskModule,
      NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ]
  })
  export class CustomizeWithdrawModule { }