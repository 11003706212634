import { ClubResponse, ClubInfo } from './control.model';
import { CLUB_URL } from '../../config/settings';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ControlService {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }

  getClubInfo(): Observable<ClubResponse> {
    this.spinner.show();
    return this.http.get<ClubResponse>(CLUB_URL)
      .pipe(finalize(() => this.spinner.hide()));
  }

  putClubSettings(clubInfo: ClubInfo): Observable<ClubInfo> {
    this.spinner.show();
    return this.http.put<ClubInfo>(CLUB_URL, clubInfo)
      .pipe(finalize(() => this.spinner.hide()),
    );
  }
}
