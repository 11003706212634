import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TransactionService } from "src/app/pages/transactions/transaction.service";
import { OperationTypeNamed, OperationPurposeNamed, OperationStatusNamed, OperationType, OperationPurpose } from "src/app/shared/finance-account-type.enum";

@Component({
	selector: 'app-operation-details',
	templateUrl: './operation-details.component.html',
	styleUrls: ['./operation-details.component.scss']
})
export class OperationDetailsComponent implements OnInit, OnChanges {
    @Input('isOpen') isOpen: boolean = false;
	@Input('operation') operation: any;

	@Output() eventClose: EventEmitter<any> = new EventEmitter();
	@Output() actionConfirmed = new EventEmitter();
	@Output() back = new EventEmitter<void>();

	isTitleSkeletonLoading: boolean = false;

    constructor(
		public transactionService: TransactionService,
		private router: Router
	) { }

	public get operationTypeNamed(): any {
		return OperationTypeNamed;
	}

	public get operationPurposeNamed(): any {
		return OperationPurposeNamed;
	}

	public get operationStatusNamed(): any {
		return OperationStatusNamed;
	}

	ngOnInit(): void { }

	ngOnChanges(): void { }

	onBack() {
		this.isOpen = false;
		this.eventClose.emit();
	}

	convertAmountToString(amount: number): string {
		const amountValue = amount.toString().length === 2 ? `0${amount}` : amount;
		return amountValue === 0
			? 'R$ 0.00'
			: `R$ ${amountValue.toString().slice(0, -2)},${amountValue
				.toString()
				.slice(-2)}`;
	}

	checkValueForLiquid(operation: any) {
		const amount = operation.amount / 100;
		if (operation.type === OperationType.CASH_IN) {
			return amount - 0.5;
		}
		return amount;
	}

	checkValueForGross(operation: any) {
		const amount = operation.amount / 100;
		if (operation.type === OperationType.CASH_OUT) {
			if (operation.purpose === OperationPurpose.WITHDRAW_BALANCE) {
				return amount + 1.5;
			} else if (operation.purpose === OperationPurpose.CLAIMBACK_PAYMENT) {
				return amount + 0.5;
			}
		}
		return amount;
	}

	goBack() {
		this.back.emit();
	}
}