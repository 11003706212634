import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardComponent } from './../../pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { OperationChartComponent } from './charts/operation/operationChart.component';
import { TotalOperationChartComponent } from './charts/totalOperation/totalOperationChart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgbModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    DashboardComponent,
    OperationChartComponent,
    TotalOperationChartComponent,
  ],
  exports: [
    DashboardComponent
  ],
  bootstrap: [
    DashboardComponent
  ]
})

export class DashboardModule { }
