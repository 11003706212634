import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClubFinancialsComponent } from './club-financials.component';
import { ClubFinancialsModalModule } from 'src/app/modals/club-financials-modal/club-financials-modal.module';
import { FinancialSettingsComponent } from './components/financial-settings/financial-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClubPercentsModalModule } from 'src/app/modals/club-percents-modal/club-percents-modal.module';
import { FinancialImportListComponent } from './components/financial-import-list/financial-import-list.component';
import { FinancialSlidePanelComponent } from './components/financial-slide-panel/financial-slide-panel.component';
import { ConfirmManuallyModalModule } from 'src/app/modals/confirm-manually-modal/confirm-manually-modal.module';
import { DeleteRakeModalModule } from 'src/app/modals/delete-rake-modal/delete-rake-modal.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FinancialUnreleatedListComponent } from './components/financial-unreleated-list/financial-unreleated-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  declarations: [
    ClubFinancialsComponent,
    FinancialSettingsComponent,
    FinancialImportListComponent,
    FinancialSlidePanelComponent,
    FinancialUnreleatedListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClubFinancialsModalModule,
    ClubPercentsModalModule,
    ConfirmManuallyModalModule,
    DeleteRakeModalModule,
    NgbModule,
    TooltipModule.forRoot(),
    NgxSkeletonLoaderModule,
  ]
})
export class ClubFinancialsModule { }
