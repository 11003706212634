import { environment } from 'src/environments/environment';

export const BASE_URL = environment.baseUrl;
export const BASE_SOCKET_URL = environment.baseSocketUrl;

export const USER_URL = `${BASE_URL}/user`;
export const PLAYER_URL = `${BASE_URL}/player`;
export const GAME_ACCOUNT_PLAYER_LIST_URL = `${PLAYER_URL}/game-account`;
export const REFRESH_TOKEN_URL = `${USER_URL}/refresh-token`;
export const RECOVERY_PASSWORD_URL = `${USER_URL}/recovery-password`;
export const AUTHENTICATE_URL = `${USER_URL}/authenticate`;
export const CHANGE_PASSWORD_URL = `${USER_URL}/change-password`;
export const CONFIRM_USER_URL = `${USER_URL}/confirmation`;
export const USER_INFO_URL = `${USER_URL}/info`;

export const TRANSACTION_URL = `${BASE_URL}/transaction`;

export const FINANCE_URL = `${BASE_URL}/finance`;
export const BALANCE_URL = `${FINANCE_URL}/balance`;
export const FINANCE_ACCOUNT_URL = `${FINANCE_URL}/account`;
export const FINANCE_OPERATION_URL = `${FINANCE_URL}/operation`;
export const FINANCE_OPERATION_FLOW_URL = `${FINANCE_OPERATION_URL}/flow`;
export const FINANCE_TRANSFER_REQUEST_URL = `${FINANCE_URL}/transfer`;
export const FINANCE_ADD_BALANCE = `${FINANCE_URL}/add-balance`;

export const CLUB_URL = `${BASE_URL}/club`;
export const GAME_ACCOUNT_URL = `${BASE_URL}/game-account`;
export const GAME_ACCOUNT_INVITE_URL = `${GAME_ACCOUNT_URL}/invite`;
export const GAME_ACCOUNT_LIST_URL = `${CLUB_URL}/game-account`;
export const CLUB_DASHBOARD_DATA_URL = `${CLUB_URL}/dashboard`;

export const CLUB_PAYMENT_CASH_OUT_URL = `${BASE_URL}/payment/cashout`

export const OPERATION_CHART_URL = `${BASE_URL}/transaction-history`;

export const CLUB_FINANCIALS_URL = `${BASE_URL}/club-financials`;
export const CLUB_FINANCIALS_RAKEBACK_PERCENT_URL = `${CLUB_FINANCIALS_URL}/game-account`
export const CLUB_FINANCIALS_RAKEBACK_SENDER_URL = `${CLUB_FINANCIALS_URL}/rakeback`;
export const CLUB_FINANCIALS_RAKEBACK_LIST_URL = `${CLUB_FINANCIALS_URL}/rakeback-players`;
export const CLUB_FINANCIALS_DELETE_URL = `${CLUB_FINANCIALS_URL}/delete-rakeback`;
export const CLUB_FINANCIALS_POST_URL = `${CLUB_FINANCIALS_URL}/rakeback/upload`;
export const CLUB_FINANCIAL_PAYMENT_URL = `${CLUB_FINANCIALS_URL}/payment`;

export const PUSH_NOTIFICATION_URL = `${BASE_URL}/user/notification`;
