<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">Adicionar % em massa</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="row">
            <span class="percents-alert pt-0 text-dark">O valor definido será aplicado para todos os jogadores
              <span class="without-percents"> sem % definida</span>
            </span>
          </div>
          <div class="form-group">
            <label for="percent" class="col-form-label">% para jogadores</label>
            <div class="input-group my-2">
              <input type="number" class="title form-control" id="percent" placeholder="Ex. 10%"
                (blur)="onPercentChange($event)" [ngClass]="{'is-invalid': errorMessage}">
                <div class="invalid-feedback" *ngIf="errorMessage">{{errorMessage}}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button id="cancel-percent-button" type="button" class="percent-btn btn btn-light" data-dismiss="modal"
            (click)="closeModal()">Cancelar</button>
          <button type="submit" class="btn btn-primary" id="percentApply" [disabled]="percent === 0">Enviar</button>
        </div>
      </form>
    </div>
  </div>
</div>