import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import * as settings from './../../config/settings';
import { OPERATION_CHART_URL } from "src/app/config/settings";
import { ChartData, OperationFlowResponse } from "./dashboard.model";

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }

  getOperationChartData(
    initialDate: string, 
    finalDate: string
  ): Observable<any> {
    this.spinner.show();
    const params = {
      initialDate,
      finalDate,
      zone: this.getLocalTimeZone(),
    };
    return this.http.get(OPERATION_CHART_URL, { params })
      .pipe(finalize(() => this.spinner.hide()));;
  }

  getLocalTimeZone():string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getOperationFlow(date?: any): Observable<OperationFlowResponse> {
    const params: any = {};
    if (date && date.startDate) {
      params.startDate = date.startDate;
    }
    if (date && date.endDate) {
      params.endDate = date.endDate;
    }
    return this.http.get<OperationFlowResponse>(settings.FINANCE_OPERATION_FLOW_URL, { params })
      .pipe();
  }

  sumValuesByMonthYear(data: ChartData[]): ChartData[] {
    const totals: Record<string, ChartData> = {};
  
    data.forEach(item => {
      const [day, month, year] = item.name.split('/');
      const monthYear = `${month}/${year}`;
  
      if (!totals[monthYear]) {
        totals[monthYear] = {
          name: monthYear,
          series: item.series.map(serie => ({ name: serie.name, value: 0 }))
        };
      }
  
      item.series.forEach((serie, i) => {
        totals[monthYear].series[i].value += serie.value;
      });
    });
  
    return Object.values(totals);
  };
}