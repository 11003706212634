import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConfirmManuallyModalComponent } from "./confirm-manually-modal.component";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    ConfirmManuallyModalComponent
  ],
  exports: [
    ConfirmManuallyModalComponent
  ],
  bootstrap: [
    ConfirmManuallyModalComponent
  ]
})

export class ConfirmManuallyModalModule { }