export interface InvitedPlayer {
  _id?: string;
  platform: string;
  wasActivated?: boolean;
  validationAttempt?: number;
  withdrawLimitUsed?: number;
  playerAccountId: string;
  ddd: string;
  phoneNumber: string;
  _club?: string;
  clubName?: string;
  gameClubId?: number;
  _inviter?: string;
  accountAlias: string;
  restrictionStatus?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface InvitedPlayerResponse {
  data: InvitedPlayer;
};

export enum UnrelatedPlayerStatus {
	SENT = 'SENT',
	LINKED = 'LINKED',
}

export enum UnrelatedPlayerStatusNamed {
	SENT = 'Enviado',
	LINKED = 'Vinculado',
}