<div id="__layout">
  <div class="d-flex align-items-center min-vh-100 min-vw-100 bg-light p-0">
    <div class="container">
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Confirmar nova conta
        </h2>
        <p class="mt-2 text-center text-muted">
          Insira o código que você recebeu para confirmar a conta
        </p>
      </div>
      <div class="container-fluid mt-4 mx-auto p-0 mw-100 bg-white p-4 shadow-sm rounded-lg" style="width: 430px;">
        <form #confirmForm="ngForm" (ngSubmit)="confirmForm.form.valid && onSubmit()">
          <div class="form-group mb-0">
            <label class="mb-1">
              Código
            </label>
            <input type="tel" pattern="[0-9]*" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="verificationCode" [(ngModel)]="login.verificationCode" #verificationCode="ngModel"
              [ngClass]="{ 'is-invalid': confirmForm.submitted && verificationCode.invalid }" required minlength="5" maxlength="5" verificationCode>
            <div *ngIf="confirmForm.submitted && verificationCode.invalid" class="invalid-feedback">
              <div *ngIf="verificationCode.errors?.minlength">O código enviado deve conter 5 digítos</div>
              <div *ngIf="verificationCode.errors?.required">Esse campo é obrigatório</div>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
              Confirmar conta
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
