import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {
  private CLUB_ID = '_id';

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private socket: Socket,
    private notificationService: NotificationService,
    private cookieService: CookieService,
  ) { }

  connectToSocketIo(): void {
    const clubId = this.getClubId();
    this.socket.on(`${clubId}-transaction-paid`, (transactionId: string) => {
      this.notificationService.showSuccess('Nova compra recebida', 'Compra');
    });
    this.socket.on(`${clubId}-withdraw-request`, (transactionId: string) => {
      this.notificationService.showInfoWithTapEvent('Clique aqui para visualizar', 'Nova solicitação de saque')
        .pipe(take(1))
        .subscribe(() => this.router.navigate(['transaction'], {
          fragment: 'transaction',
          queryParams: {
            openModal: true,
            transactionId: transactionId,
            activeTab: 'WITHDRAW',
          }
        }));
    });
  }

  public getClubId(): string {
    return JSON.parse(this.cookieService.get(this.CLUB_ID));
  }
}
