import { DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerStatus } from 'src/app/shared/player.enum';
import { Criteria } from './player.model';

import { PlayerListComponent } from './players/player-list.component';


@Component({
  selector: 'app-player-component',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  providers: [DecimalPipe]
})
export class PlayerComponent implements OnInit {
  @ViewChild('playerList', { static: true }) public playerList!: PlayerListComponent;

  activeTab!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(){
    this.activeTab = 'PLAYER';
  }

  onTabChange(): void {
    this.resetTabData();
  }

  resetTabData(): void {
    const criteria = new Criteria();
    if(this.playerList.activeTab === 2) criteria.tab = 'custom';
    criteria.listByStatus = PlayerStatus.ALL;
    this.playerList.updatePlayerList(criteria);
    this.playerList.getDashboardData();
    this.playerList.filterByType = this.playerList.playerStatus.ALL;
  }
}
