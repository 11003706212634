import { Component, Input, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Observable } from 'rxjs';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-deposit-chart',
  templateUrl: './totalOperationChart.component.html',
  styleUrls: ['./totalOperationChart.component.scss']
})
export class TotalOperationChartComponent implements OnInit{
  @Input() view!: [number, number];
  @Input() chartData!: Observable<any>;
  multi: any[] = [];

  // options
  animations = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Depósitos';
  legendTitle: string = 'Depósitos';
  yAxisTickFormatting = this.axisFormat;
  

  colorScheme = {
    domain: [
      '#18bc9c',
      '#342b4f',
      '#f39c12',
      '#d00158',
      '#8b0000',
    ]
  };

  constructor(
    private readonly dashboardService: DashboardService,
  ) { }
  
  ngOnInit(): void {
    console.log(this.view)
    this.chartData.subscribe(data => {
      if (data && data.length > 60) {
        this.multi = this.dashboardService.sumValuesByMonthYear(data)
        return;
      }
      this.multi = data;
    });
  }

  axisFormat(val: number) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

  percFormat(value:number): string {
    const str = value.toFixed(1) ;
    return str;
  }
}