import { FinanceTransferAmount, FinanceAccountResponse, FinanceAccount } from './../../pages/finance/finance.model';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FinanceService } from './../../pages/finance/finance.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationService } from 'src/app/shared/notification.service';
import Constants from 'src/app/shared/constants';
import { PixKeyTypeNamed } from 'src/app/shared/finance-account-type.enum';

@Component({
  selector: 'app-finance-withdraw-modal',
  templateUrl: './finance-withdraw-modal.component.html',
  styleUrls: ['./finance-withdraw-modal.component.scss'],
})
export class FinanceWithdrawModalComponent {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  finance: FinanceAccount = {} as FinanceAccount;
  // bankAccount: BankAccount = {} as BankAccount;
  transferAmount: FinanceTransferAmount = new FinanceTransferAmount();
  amount = 0;

  public get pixKeyTypeNamed(): any {
    return PixKeyTypeNamed;
  }

  @Output() confirm = new EventEmitter();
  @Output() deny = new EventEmitter();

  constructor(public service: FinanceService, private notificationService: NotificationService) { }

  public openModal(): void {
    this.amount = 0;
    this.transferAmount = new FinanceTransferAmount();
    this.autoShownModal.show();
    this.service.getFinanceAccount().subscribe(
      (financeAccountResponse: FinanceAccountResponse) => {
        this.finance = financeAccountResponse.data;
      }
    );
  }

  /**
   * Handle event of the confirm button
   */
  public onConfirm(): void {
    if (this.amount < 5) {
      this.notificationService.showError('O valor de saque mínimo é de R$ 5,00.', 'Não foi possível realizar saque');
      return;
    }
    this.transferAmount.amount = this.fixAmountFormat(this.amount);
    this.service.postTransferRequest(this.transferAmount).subscribe(() => {
      this.autoShownModal.hide();
      this.confirm.emit();
      this.notificationService.showSuccess('Solicitação de saque confirmado com sucesso!', '');
    }, (error) => {
      switch (error.error.error_code) {
        case Constants.CREATE_FINANCE_ACCOUNT_FIRST:
          this.notificationService.showError('Por favor, cadastre uma chave PIX', 'Não foi possível realizar o resgate');
          break;
        case Constants.ACCOUNT_HAS_NO_BALANCE:
          this.notificationService.showError('Verifique se o valor disponível é suficiente', 'Não foi possível realizar o resgate');
          break;
        default:
          if (this.amount > 5){
            this.notificationService.showError('', 'Não foi possível realizar saque');
          }
      }
    });
  }

  private fixAmountFormat(amount: number): number {
    const formatString = amount.toString().split('.');
    if (!formatString[1]) {
      formatString[1] = '';
    }
    formatString[1] = formatString[1].padEnd(2, '0');
    return parseInt(formatString.join(''), 10);
  }

  /**
   * Close modal
   */
  public onCloseModal(): void {
    this.autoShownModal.hide();
    this.deny.emit();
  }

  formatDoc(doc: string): string {
    if (!doc) return '';
  
    return doc.length > 11
      ? doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      : doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  
  getTransactionSummary() {
    return [
      { label: 'Valor solicitado', value: this.amount, isNegative: false },
      { label: 'Taxa de transação', value: -0.5, isNegative: true },
      { label: 'Taxa Chippix', value: 1.0, isNegative: false },
      { label: 'Custo total da transação', value: this.amount + 1, isNegative: false },
      { label: 'Valor que você recebe', value: this.amount - 0.5, isNegative: false },
    ];
  }
}
