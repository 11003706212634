import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { ClubFinancialErrorJaimeTask, ImportedPlayer } from "../../club-financials.model";
import { AuthService } from "src/app/auth/auth.service";
import { NotificationService } from "src/app/shared/notification.service";
import { ConfirmManuallyModalComponent } from "src/app/modals/confirm-manually-modal/confirm-manually-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClubFinancialsService } from "../../club-financials.service";

@Component({
  selector: 'app-financial-slide-panel',
  templateUrl: './financial-slide-panel.component.html',
  styleUrls: ['./financial-slide-panel.component.scss']
})
export class FinancialSlidePanelComponent implements OnInit, OnChanges {

  @Input('isOpen') isOpen: boolean = false;
  @Input('player') player: any;

  @Output() eventClose: EventEmitter<any> = new EventEmitter();
  @Output() actionConfirmed = new EventEmitter();
  @Output() playerUpdated = new EventEmitter<ImportedPlayer>();

  @ViewChild('confirmManuallyModalComponent', { static: true }) public confirmManuallyModalComponent!: ConfirmManuallyModalComponent;

  isSkeletonLoading: boolean = false;
  isSentManually: boolean = false;
  sentManuallyAt!: any;
  sentManuallyBy!: any;

  errorTitle!: string;
  errorMessage!: string;
  prevPlayer!: string;

  currentUser!: any;

  public get clubFinancialErrorJaimeTask(): typeof ClubFinancialErrorJaimeTask {
    return ClubFinancialErrorJaimeTask;
  }

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private clubFinancialsService: ClubFinancialsService,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['player']) {
			const prevPlayer = changes['player'].previousValue; 
			const currentPlayer = changes['player'].currentValue;

      this.prevPlayer = prevPlayer;

			this.getErrorJaimeTask(currentPlayer?._jaimeTask?.error, currentPlayer?._id);
      this.isSentManually = this.player?.sentManually
		}  
  }

  onBack() {
    this.isOpen = false;
    this.eventClose.emit();
  }

  getErrorJaimeTask(error: any, id: any) {
    switch(error?.errorName) {
      case this.clubFinancialErrorJaimeTask.MEMBER_NOT_FOUND:
        this.errorTitle = "Jogador não encontrado";
        this.errorMessage = "Esse jogador não pertence mais ao clube."
        break
      case this.clubFinancialErrorJaimeTask.THERE_IS_NO_BALANCE_TO_SEND:
        this.errorTitle = "Clube sem saldo";
        this.errorMessage = `Clube sem saldo suficiente para realizar o envio de fichas de forma automática, realize o envio de forma manual para o jogador ID <strong>${id}</strong>`;
        break
      case this.clubFinancialErrorJaimeTask.TRANSACTION_NOT_CONFIRMED_BY_SCREEN:
        this.errorTitle = "Transação não confirmada";
        this.errorMessage = "A tela de contagem não foi aberta após a transação'";
        break
      default:
        this.errorTitle = "Este erro não é comum";
        this.errorMessage = `Comunique a equipe Chippix sobre este erro: Código - <strong>${error?.errorCode}</strong>`;
        break
    }
  }

  onCheckChange(event: any) {
    this.isSentManually = event.target.checked
  }

  getUserInfoCredentials(): void {
    this.authService.getUserInfo().subscribe(
      (userInfo: any) => {
        this.sentManuallyBy = userInfo.data._id
        
        this.updateRakebackPlayerManually();
      }, (error) => {
        this.notificationService.showError(
          "Não foi possível validar o usuário para confirmação do envio de forma manual, tente novamente.",
          "Erro ao validar usuário");

        this.resetManualUpdate();
      });
  }

  onSave() {
    if(this.isSentManually) {
      const modalRef = this.modalService.open(ConfirmManuallyModalComponent, { centered: true });

      modalRef.componentInstance.player = this.player;

      modalRef.result
        .then((confirmed) => {
          if (confirmed) {
            this.player.sentManually = true;
            this.sentManuallyAt = new Date().toISOString();

            if(confirmed) {
              this.getUserInfoCredentials();
            } 
           
          } else {
            this.isSentManually = false;
          }
        })
        .catch(() => {
          this.isSentManually = false;
        });
    }
  }

  updateRakebackPlayerManually() {
    this.isSkeletonLoading = true;
    if (!this.player._id) {
      this.notificationService.showError(
        "Este jogador ainda não existe.",
        "Jogador não encontrada!");

      this.isSkeletonLoading = false;
      return;
    }
    this.clubFinancialsService.updateRakebackPlayer(
      this.player._id,
      this.player._rakebackSender,
      this.player.sentManually,
      this.sentManuallyBy,
      this.sentManuallyAt)
      .subscribe((updatedRakebackPlayer: ImportedPlayer) => {
        this.notificationService.showSuccess(
          "Jogador confimado envio de forma manual.",
          "Jogador atualizada com sucesso!");
          
         this.resetManualUpdate();

        this.isSkeletonLoading = false;
          
        this.playerUpdated.emit(this.player);
      },
      (error) => {
        console.error("error: ", error)
        this.notificationService.showError(
          "Erro ao confirmar envio de forma manual",
          "Erro ao atualizar jogador");
        this.resetManualUpdate();
        this.isSkeletonLoading = false;
      }
    );
  }

  resetManualUpdate() {
    this.isSentManually = false;
    this.player.sentManually = false;
    this.player.sentManuallyAt = undefined;
    this.player.sentManuallyBy = undefined;
  }
}