import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface SortEvent {
  column: string;
  direction: string;
}

@Component({
  selector: 'app-ngbd-sortable-header',
  templateUrl: './ngbd-sortable-header.component.html',
  styleUrls: ['./ngbd-sortable-header.component.scss']
})
export class NgbdSortableHeaderComponent {
  @Input() sortable: string = '';
  @Input() direction: string = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = this.direction === 'asc' ? 'desc' : this.direction === 'desc' ? '' : 'asc';
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }

  get iconName(): string {
    if (this.direction === 'asc') {
      return 'keyboard_arrow_up';
    } else if (this.direction === 'desc') {
      return 'keyboard_arrow_down';
    } else {
      return 'unfold_more';
    }
  }
}