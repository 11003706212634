<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">Processando</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="file-name">
            <span class="material-symbols-outlined">description </span>{{data}}</div>
        </div>
        <div class="d-flex align-items-center p-12">
          <div class="w-100">
            <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar" id="progress-bar"
                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': progress}"></div>
            </div>
          </div>
        <span class="ml-2">{{progress}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button id="import-cancel-button" type="button" class="canc-btn btn btn-light" data-dismiss="modal"
        (click)="closeModal()">Cancelar</button>
      </div>
    </div>
  </div>
</div>
