export enum AccounTypeNamed {
  'ContaCorrente' = 'Conta Corrente',
  'Poupanca' = 'Conta Poupança',
  'ContaSalario' = 'Conta Salario',
  'ContaPagamento' = 'Conta de Pagamento',
  'Virtual' = 'Virtual',
}

export enum PixKeyType {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  RANDOM_KEY = 'RANDOM_KEY',
}

export enum PixKeyTypeNamed {
  CPF = 'Cpf',
  CNPJ = 'Cnpj',
  PHONE = 'Telefone',
  EMAIL = 'E-mail',
  RANDOM_KEY = 'Aleatória',
}

export enum OperationType {
  CASH_IN = 'CASH_IN',
  CASH_OUT = 'CASH_OUT',
}

export enum OperationTypeNamed {
  CASH_IN = 'Entrada',
  CASH_OUT = 'Saída',
}

export enum OperationPurposeNamed {
  ADD_BALANCE = 'Credito',
  CLAIMBACK_PAYMENT = 'Pagamento',
  WITHDRAW_BALANCE = 'Saque',
  CHIP_PAYMENT = 'Recebimento',
  SERVICE_CHARGE = 'Serviço',
}

export enum OperationPurpose {
  ADD_BALANCE = 'ADD_BALANCE',
  CLAIMBACK_PAYMENT = 'CLAIMBACK_PAYMENT',
  WITHDRAW_BALANCE = 'WITHDRAW_BALANCE',
  CHIP_PAYMENT = 'CHIP_PAYMENT',
  SERVICE_CHARGE = 'SERVICE_CHARGE',
}

export enum OperationStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  FRAUD = 'FRAUD',
  REJECTED = 'REJECTED',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export enum OperationStatusNamed {
  PROCESSING = 'Processando',
  COMPLETED = 'Concluído',
  ERROR = 'Erro',
  FRAUD = 'Fraude',
  REJECTED = 'Rejeitado',
  WAITING_PAYMENT = 'Aguardando pgt',
}

export enum DropdownPeriod {
  ALL_PERIOD = 'ALL_PERIOD',
  TODAY = 'TODAY',
  LAST_WEEK = 'LAST_WEEK',
}

export enum DropdownPeriodNamed {
  ALL_PERIOD = 'Todo período',
  TODAY = 'Hoje',
  LAST_WEEK = 'Últimos 7 dias',
}
