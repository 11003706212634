import { Component, OnInit } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { getAnalytics } from "firebase/analytics";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'chippix';

  // Initialize Firebase
  app = initializeApp(environment.firebase);

  constructor(private AuthGuard: AuthGuard) {}

  ngOnInit(): void {
    // Initialize Analytics and get a reference to the service
    const analytics = getAnalytics(this.app);
    if (analytics) {
      console.log(analytics)
    }
    // If the user is authenticated, start schedule for refresh token before expires
    const token = this.AuthGuard.getToken();
    if (token) {
      this.AuthGuard.scheduleRefresh(token);
    }
  }
}
