<app-club-percents-modal #clubPercentsModalComponent ></app-club-percents-modal>

<div class="contents-wrapper">
  <div class="main-content" [class.shifted]="isSlidePanelOpen">
    <div class="page-header">
      <div class="d-flex w-auto align-items-center">
        <button type="button" class="close" aria-label="Close" (click)="onBack()">
          <span class="my-2 material-symbols-outlined">arrow_back</span>
        </button>
        <div class="h4 font-weight-bold mt-2 ml-2">{{ selectedFileName }}</div>
      </div>
      <div class="ml-auto">
        <button type="button" class="btn btn-light border align-items-center text-primary mr-3"
          (click)="onRefreshPage()">
          <i class="bi bi-arrow-clockwise mr-1"></i>
          Atualizar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onSendRakeback()"
          [disabled]="isSendRakebackDisabled()">
          <i class="bi bi-file-earmark-play justify-content-between"></i>
          Enviar fichas
        </button>
      </div>
    </div>
    <div *ngIf="!isVisible" class="view-tabs-wrapper w-100 my-5">
      <div class="w-100 text-muted text-center view-tabs-text">Aqui você encontra um resumo do status do seu fechamento</div>
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-filter active m-0">
          <input type="radio" name="options" id="resume" autocomplete="off" (change)="onSettingsViewChange('resume')" checked />Resumo
        </label>
        <label class="btn btn-filter m-0">
          <input type="radio" name="options" id="details" autocomplete="off" (change)="onSettingsViewChange('details')" />Detalhes
        </label>
      </div>
    </div>
    <!-- Resume view -->
    <div *ngIf="!isVisible" class="resume-cards">
      <div *ngFor="let item of resumeData" class="resume-card-wrapper">
        <div class="resume-card">
          <div class="d-flex flex-row w-100">
            <div class="icon">
              <img src="{{ item.icon }}" />
            </div>
            <div class="info ml-3">
              <dt class="text-muted small font-weight-bold">{{ item.title }}</dt>
              <div class="info-value font-weight-bolder">
                <span>{{ item.number }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./ Resume view -->
    <div *ngIf="pageView === 'details' || isNewImport">
      <div class="border border-0 bg-white mt-5 rounded pb-3">
        <div class="header-options p-3">
          <div class="text-muted text-center pb-3">Visualizar contas por:</div>
          <div class="btn-group" role="group" aria-label="radio toggle button group">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-filter m-0" [ngClass]="{'active' : criteria.filterBy === clubFinancialStatus['WITH_PERCENTS']}">
                <input type="radio" name="options" id="view-with-porcentage" autocomplete="off" (change)="onAccountViewChange(clubFinancialStatus['WITH_PERCENTS'])">Com %
              </label>
              <label class="btn btn-filter m-0" [ngClass]="{'active' : criteria.filterBy === clubFinancialStatus['WITHOUT_PERCENTS']}">
                <input type="radio" name="options" id="view-no-porcentage" autocomplete="off" (change)="onAccountViewChange(clubFinancialStatus['WITHOUT_PERCENTS'])">Sem %
              </label>
              <label class="btn btn-filter m-0" [ngClass]="{'active' : criteria.filterBy === clubFinancialStatus['UNRELEATED']}">
                <input type="radio" name="options" id="view-unrelated" autocomplete="off" (change)="onAccountViewChange(clubFinancialStatus['UNRELEATED'])">Não vinculados
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="currentView !== 'UNRELEATED'" class="form-inline p-3 m-0 d-flex justify-content-between">
          <div class="input-group">
            <input class="form-control border-right-0" type="text" placeholder="Buscar..." name="searchTerm"
              (input)="onSearchChange(criteria)" [(ngModel)]="criteria.searchTerm" />
            <div class="input-group-append">
              <span class="input-group-text bg-white">
                <i class="bi bi-search"></i>
              </span>
            </div>
          </div>
          <button
            *ngIf="currentView === 'WITHOUT_PERCENTS'"
            type="button"
            class="btn btn-light border d-flex align-items-center text-primary"
            (click)="openClubPercentsModal(rakebackPlayers)"
            [disabled]="!rakebackPlayers.length && criteria.filterBy === clubFinancialStatus['WITHOUT_PERCENTS']"
            [tooltipEnable]="!rakebackPlayers.length && criteria.filterBy === clubFinancialStatus['WITHOUT_PERCENTS']"
            tooltip="Tenha pelo menos 1 jogador disponível para habilitar adicionar em massa">
            <i class="bi bi-plus-lg mr-2"></i> Adicionar % em massa
          </button>
        </div>
        <div *ngIf="currentView !== 'UNRELEATED'" class="w-100">
          <ng-container *ngIf="!isSkeletonLoading; else showSkeleton">
            <ng-container *ngIf="rakebackPlayers.length !== 0; else noPlayers">
              <div class="w-100">
                <app-financial-import-list
                  #financialImportList
                  [rakebackPlayers]="rakebackPlayers"
                  [criteria]="criteria"
                  (getRakebackPlayerList)="getRakebackPlayerList($event)" 
                  (playerPercentageUpdated)="onPlayerPercentageUpdated($event)"
                  (playerSelected)="onPlayerSelection($event)"
                  (rakebackPlayerDeleted)="onDeleteRakeback($event)">
                </app-financial-import-list>
              </div>
            </ng-container>
            <ng-template #noPlayers>
              <div class="bg-white alert alert-light text-center mx-auto mb-0 col-10">
                {{ getEmptyStateMessage() }}
              </div>
            </ng-template>
          </ng-container>
          <ng-template #showSkeleton>
            <div class="table-responsive table table-striped table-loader">
              <table class="table table-borderless mb-0">
                <thead class="border-top border-bottom border-light">
                  <tr>
                    <th scope="col" class="column-id">
                      <ngx-skeleton-loader
                        [theme]="{'width': '60%', 'background': '#e5e4e4 no-repeat'}"
                        appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </th>
                    <th scope="col" class="column-percentage align-middle">
                      <ngx-skeleton-loader
                        [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </th>
                    <th scope="col" class="column-rake align-middle text-center loader-center">
                      <ngx-skeleton-loader
                        [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </th>
                    <th scope="col" class="column-rakeback align-middle text-center loader-center">
                      <ngx-skeleton-loader
                        [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </th>
                    <th scope="col" class="column-status align-middle text-center loader-center">
                      <ngx-skeleton-loader
                        [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </th>
                    <th scope="col" class="column-delete align-middle text-center loader-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-top border-bottom table-d1" *ngFor="let player of [0,0,0]">
                    <td class="column-id align-middle">
                      <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}" appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </td>
                    <td class="column-percentage align-middle">
                      <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}" appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </td>
                    <td class="column-rake align-middle text-center loader-center">
                      <ngx-skeleton-loader
                        [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line"
                        animation="progress">
                      </ngx-skeleton-loader>
                    </td>
                    <td class="column-rakeback align-middle text-center loader-center">
                      <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line" animation="progress">
                      </ngx-skeleton-loader>
                    </td>
                    <td class="column-status align-middle text-center loader-center">
                      <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line" animation="progress">
                      </ngx-skeleton-loader>
                    </td>
                    <td class="column-delete align-middle text-center loader-center">
                      <ngx-skeleton-loader [theme]="{'background': '#e5e4e4 no-repeat'}"
                        appearance="line" animation="progress">
                      </ngx-skeleton-loader>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-pag w-100 align-content-center">
              <p class="m-0 w-25">
                <ngx-skeleton-loader
                  [theme]="{'width': '100%', 'background': '#e5e4e4 no-repeat'}"
                  appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </p>
              <div class="text-right w-75">
                <ngx-skeleton-loader
                  [theme]="{'height': '36px', 'width': '35%', 'background': '#e5e4e4 no-repeat'}"
                  appearance="line"
                  animation="progress">
                </ngx-skeleton-loader>
              </div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="currentView === 'UNRELEATED'">
          <app-financial-unreleated-list
            #financialUnreleatedList
            [rakebackPlayersUnreleated]="rakebackPlayers"
            [criteria]="criteria"
            [isLoading]="isSkeletonLoading"
            (getRakebackPlayerUnreleatedList)="getRakebackPlayerList($event)">
          </app-financial-unreleated-list>
        </div>
      </div>
    </div>
  </div>
  <div class="slide-content">
    <app-financial-slide-panel #financialSlidePanel [player]="selectedPlayer" [isOpen]="isSlidePanelOpen"
      (eventClose)="closeFinancialSlidePanel()" (playerUpdated)="updatePlayerState($event)"></app-financial-slide-panel>
  </div>
</div>

