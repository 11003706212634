import { CookieService } from 'ngx-cookie-service';
import { SidebarComponent } from './../components/sidebar/sidebar.component';
import { FinanceComponent } from './finance/finance.component';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import Constants from '../shared/constants';
import { BankAccount, FinanceAccount, FinanceAccountResponse } from './finance/finance.model';
import { FinanceService } from './finance/finance.service';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})

export class PageComponent implements OnInit {
  @ViewChild('financeComponent') public financeComponent!: FinanceComponent;
  @ViewChild('sidebar', { static: true }) public sidebar!: SidebarComponent;
  financeAA!: FinanceAccount;
  bankAccount!: BankAccount;

  constructor(
    private service: FinanceService,
    private router: Router,
    public route: ActivatedRoute,
    private cookieService: CookieService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getFinanceAccount();
  }

  getFinanceAccount(): void {
    this.service.getFinanceAccount().subscribe(
      (financeAccountResponse: FinanceAccountResponse) => {
        this.financeAA = financeAccountResponse.data;
        this.cookieService.set('financeAccountActiveTab', JSON.stringify(1));
        this.cookieService.set('financeAccountCreated', JSON.stringify(true));
      },
      (error) => {
        switch (error.status) {
          case 400:
            if (error.error.error_code === Constants.CREATE_FINANCE_ACCOUNT_FIRST) {
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  financeAccountActiveTab: 1,
                  financeAccountCreated: false,
                }
              };
              this.cookieService.set('financeAccountActiveTab', JSON.stringify(2));
              this.cookieService.set('financeAccountCreated', JSON.stringify(false));
              this.router.navigateByUrl('/finance#finance');
            }
            break;
          default:
            this.financeAA = {} as FinanceAccount;
            this.bankAccount = {} as BankAccount;
        }
      }
    );
  }
}
