import { CurrencyMaskInputMode } from "ngx-currency";

export const customCurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: true,
    decimal: ',',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: '.',
    nullable: true,
    min: 0,
    max: 100000000,
    inputMode: CurrencyMaskInputMode.FINANCIAL,
};