import { ClaimbackPaymentType } from 'src/app/shared/control.enum';
import { AutoClaimbackErrorCode, TransactionType } from 'src/app/shared/transaction.enum';
import { TransactionStatus } from '../../shared/transaction.enum';

export interface Receipt {
  currentAccountName: string;
  givenNickName: string;
  currentValue: string;
  previousValue: string;
  differenceValue: string;
  e2eId?: string;
  hasError?: boolean;
  pixId?: string;
  platform?: string;
  playerId: string;
  playerName?: string;
}

export interface Transaction {
  _id: string;
  updatedAt: string;
  createdAt: string;
  _club: string;
  amount: number;
  attachment: string;
  status: TransactionStatus;
  type: TransactionType;
  _gameAccount: GameAccount;
  pixPaymentCode: string;
  _user: User;
  errorCode: number;
  claimbackPaymentType: ClaimbackPaymentType;
  autoClaimbackErrorCode?: AutoClaimbackErrorCode;
  autoClaimbackErrorMessage?: string;
  receipt?: Receipt;
}
export interface TransactionResponse {
  data: Transaction;
}
export interface GameAccount {
  _id: string;
  updatedAt: string;
  createdAt: string;
  playerAccountId: string;
  platform: string;
}

export interface User {
  _id: string;
  updatedAt: string;
  name: string;
  email: string;
  userName: string;
  lastName: string;
  createdAt: string;
  ddd: string;
  phoneNumber: string;
  paymentData: PaymentData;
}

export interface PaymentData {
  customerId: string;
  pixAccountName: string;
  pixKey: string;
}

export interface Pageable<T> {
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  data: Array<T>;
}

export class Criteria {
  limit: number;
  page: number;
  totalPages: number;
  searchTerm: string; // name, phoneNumber e Id do jogador
  listByType: TransactionType;
  listByStatus?: TransactionStatus;
  totalDocs: number;
  sortColumn: any;
  sortDirection: string;

  constructor() {
    this.limit = 15;
    this.page = 1;
    this.totalPages = 1;
    this.searchTerm = '';
    this.listByType = TransactionType.PIX;
    this.listByStatus = TransactionStatus.ALL;
    this.totalDocs = 0;
    this.sortColumn = '';
    this.sortDirection = 'ASC';
  }
}
