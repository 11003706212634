import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClubPercentsModalComponent } from './club-percents-modal.component';



@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    ClubPercentsModalComponent
  ],
  exports: [
    ClubPercentsModalComponent
  ],
  bootstrap: [
    ClubPercentsModalComponent
  ]
})

export class ClubPercentsModalModule { }
