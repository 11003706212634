<div class="d-flex justify-content-between align-items-center">
  <div class="h4 font-weight-bold">Contas Vinculadas</div>
  <button type="button" class="btn btn-light border d-flex align-items-center text-primary" (click)="resetTabData()">
    <i class="bi bi-arrow-clockwise mr-2"></i>
    Atualizar
  </button>
</div>
<div class="border border-0 bg-white mt-5 ">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" (navChange)="onTabChange()">
    <li [ngbNavItem]="'PIX'">
      <ng-template ngbNavContent>
      </ng-template>
    </li>
  </ul>
  <app-player-list #playerList></app-player-list>
  <div [ngbNavOutlet]="nav"></div>
</div>
