<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">Adicionar</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row no-gutters p-0">
        <div class="col-12 p-0">
          <div class="modal-body confirm-subtitle px-4 pt-4 pb-0">
            <div class="mb-2">
              <h5>Adicionar com PIX</h5>
              <p class="text-muted m-0">
                Escaneie o QR Code, ou copie o código PIX para adicionar.
              </p>
              <div class="d-flex justify-content-center">
                <qrcode [qrdata]="addBalanceData.pix_qr_code" [width]="256" colorDark [level]="'M'"></qrcode>
              </div>
              <div class="input-group has-validation">
                <input disabled type="text" id="disabledTextInput" class="form-control border"
                  [placeholder]="addBalanceData.pix_qr_code">
                <div class="input-group-append">
                  <button class="btn btn-outline-white border text-muted" ngxClipboard *ngxClipboardIfSupported
                    [cbContent]="'target string'" (cbOnSuccess)="copied($event)" (click)="copyContent()"
                    type="button">Copiar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-0 col-12">
          <div class="modal-body confirm-subtitle p-4">
            <h5>
              Dados
            </h5>
            <div class="bg-light px-4 py-3 text-dark rounded-lg">
              <div>
                <b>Valor:</b>
                {{ addBalanceData.amount / 100 | currency:'BRL':'symbol':'1.2-2' }}
              </div>
              <div>
                <b>Data:</b>
                {{ addBalanceData.createdAt | date: 'd/M/yy' }}
              </div>
              <div>
                <b>Horário:</b>
                {{ addBalanceData.createdAt | date: 'HH:mm' }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="px-4 pb-4 pt-0">
            <div class="rounded-0 border-0 alert alert-info mb-0 d-flex align-items-center" role="alert">
              <i class="bi bi-info-circle-fill mr-3"></i>
              <small>
                <p class="m-0">
                  Após realizar o pagamento do <strong>PIX</strong>, o valor será adicionado.
                </p>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
