import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlayerThermometerService } from 'src/app/pages/player-thermometer/player-thermometer.service';
import { Player } from 'src/app/pages/players/player.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})

export class PushNotificationComponent implements OnInit {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;
  
  @Output() eventClose: EventEmitter<any> = new EventEmitter();

  title: string = '';
  message: string = '';
  clubName!: string;
  NAME = 'name';
  action!: string;

  notification!: any;

  totalPlayers!: number;

  selectedPlayersId: any[] = [];
  deselectedPlayersId: any[] = [];

  isAllPlayersSelected: boolean = false;

  constructor(
    private notificationService: NotificationService,
    public playerThermometerService: PlayerThermometerService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.clubName = this.getClubName();
  }

  
  /**
   * Open modal
   */
  public openModal(totalPlayers: any, isAllPlayersAcrossPagesSelected: boolean, selectedPlayersId: any[], deselectedPlayersId: any[]): void {
    this.selectedPlayersId = selectedPlayersId;
    this.deselectedPlayersId = deselectedPlayersId;
    this.isAllPlayersSelected = isAllPlayersAcrossPagesSelected
    this.totalPlayers = totalPlayers

    this.spinner.hide();
    this.autoShownModal.show()
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.clearFields();
    this.autoShownModal.hide();
    this.eventClose.emit();
  }

  public sendNotification(): void {
    this.notification = {
      title: `${this.clubName} ${this.title}`,
      content: this.message,
      isGlobal: this.isAllPlayersSelected,
      ignoredUserId: this.deselectedPlayersId,
      userId: this.selectedPlayersId
    };

    
    this.playerThermometerService.sendPushNotification(this.notification)
      .subscribe(
        (response: any) => {
          this.notificationService.showSuccess('Mensagem enviada com sucesso!', 'Envio concluído')
        },
        (error: any) => {
          this.notificationService.showError('Verifique se preencheu os espaços corretamente','Não foi possível realizar esta ação');
        }
      );

    this.closeModal();
  }

  public getClubName(): string {
    return JSON.parse(this.cookieService.get(this.NAME));
  }

  checkDeselectedPlayers() {
    if(this.deselectedPlayersId.length !== 0 || this.isAllPlayersSelected) {
      return this.totalPlayers - this.deselectedPlayersId.length
    } else {
     return this.totalPlayers
    }
  }

  clearFields() {
    this.title = '';
    this.message = '';
  }

}
