import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  OperationPurpose,
  OperationPurposeNamed,
  OperationStatusNamed,
  OperationType,
  OperationTypeNamed,
} from 'src/app/shared/finance-account-type.enum';
import { TransactionService } from 'src/app/pages/transactions/transaction.service';
import { Router } from '@angular/router';
import { CashoutReceipt } from './../../shared/cashout-receipt.model';

@Component({
  selector: 'app-operation-modal',
  templateUrl: './operation-modal.component.html',
  styleUrls: ['./operation-modal.component.scss'],
})
export class OperationModalComponent {
  @ViewChild('autoShownModal', { static: true })
  public autoShownModal!: ModalDirective;

  operation: any = {};

  @Output() deny = new EventEmitter();

  constructor(
    public transactionService: TransactionService,
    private router: Router
  ) {}

  /**
   * Open modal
   */
  public openModal(operation: any): void {
    this.operation = operation;
    this.autoShownModal.show();
  }

  public closeModal(): void {
    this.autoShownModal.hide();
    //this.deny.emit();
  }

  public get operationPurposeNamed(): any {
    return OperationPurposeNamed;
  }

  onGenereteCashoutReceipt(pixId: string) {
    this.transactionService
      .getCashoutReceipt(pixId)
      .subscribe((cashoutReceipt: Blob) => {
        const fileURL = URL.createObjectURL(cashoutReceipt);
        
        window.open(fileURL, '_blank');
        
        const downloadAnchor = document.createElement('a');
        downloadAnchor.href = fileURL;
        downloadAnchor.download = 'Comprovante.pdf';
        
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
        
        URL.revokeObjectURL(fileURL);
      });
  }

  openDetailsInTransactions(transactionID: string) {
    this.router.navigate(['/transaction'], {
      queryParams: {
        transactionId: transactionID,
        openModal: 'true',
      },
    });
  }

  convertAmountToString(amount: number): string {
    const amountValue = amount.toString().length === 2 ? `0${amount}` : amount;
    return amountValue === 0
      ? 'R$ 0.00'
      : `R$ ${amountValue.toString().slice(0, -2)},${amountValue
          .toString()
          .slice(-2)}`;
  }

  public get operationTypeNamed(): any {
    return OperationTypeNamed;
  }

  public get operationStatusNamed(): any {
    return OperationStatusNamed;
  }

  checkValueForLiquid(operation: any) {
    const amount = operation.amount / 100;
    if (operation.type === OperationType.CASH_IN) {
      return amount - 0.5;
    }
    return amount;
  }

  checkValueForGross(operation: any) {
    const amount = operation.amount / 100;
    if (operation.type === OperationType.CASH_OUT) {
      if (operation.purpose === OperationPurpose.WITHDRAW_BALANCE) {
        return amount + 1.5;
      } else if (operation.purpose === OperationPurpose.CLAIMBACK_PAYMENT) {
        return amount + 0.5;
      }
    }
    return amount;
  }
}
