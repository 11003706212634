import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DoubleCheckModalComponent } from "src/app/modals/double-check-modal/double-check-modal.component";
import { Player } from "src/app/pages/players/player.model";
import { DoubleCheckModalText, PlayerAction, PlayerStatus, PlayerStatusNamed } from "src/app/shared/player.enum";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit  {

  @Input('data') data!: any;
  @Input('isModal') isModal!: any;
  @Input('player') player!: any;

  @Output() actionPerformed = new EventEmitter<any>();
  @Output() itemSelect = new EventEmitter<any>();


  public get playerStatus(): typeof PlayerStatus {
    return PlayerStatus;
  }

  public get playerStatusNamed(): typeof PlayerStatusNamed {
    return PlayerStatusNamed;
  }

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  public get doubleCheckModalText(): typeof DoubleCheckModalText {
    return DoubleCheckModalText;
  }

  constructor(
    public modalService: NgbModal
  ) { }

  ngOnInit(): void { }

  openDoubleCheckModal(player: Player, type: string): void {

    const actionConfig = {
      [this.playerAction.ACTIVATE]: {
        titleText: "Realmente deseja ativar a conta desse jogador?",
        buttonText: "Ativar",
        alertText: this.doubleCheckModalText.ACTIVATE_ALERT_TEXT,
      },
      [this.playerAction.RESET_ATTEMPS]: {
        titleText: "Realmente deseja resetar as tentativas desse jogador?",
        buttonText: "Resetar",
        alertText: this.doubleCheckModalText.RESET_ATTEMPS_ALERT_TEXT,
      },
      [this.playerAction.RESET_WITHDRAW]: {
        titleText: "Resetar quantidades de saque do jogador",
        buttonText: "Resetar",
        alertText: this.doubleCheckModalText.RESET_WITHDRAW_ALERT_TEXT,
      },
      [this.playerAction.BLOCK_WITHDRAW]: {
        titleText: "Realmente deseja bloquear o saque desse jogador?",
        buttonText: "Bloquear saque",
        alertText: this.doubleCheckModalText.BLOCK_WITHDRAW_ALERT_TEXT,
      },
      [this.playerAction.UNBLOCK_WITHDRAW]: {
        titleText: "Realmente deseja desbloquear o saque desse jogador?",
        buttonText: "Desbloquear saque",
        alertText: this.doubleCheckModalText.UNBLOCK_WITHDRAW_ALERT_TEXT,
      },
      [this.playerAction.BLOCK]: {
        titleText: "Realmente deseja bloquear a conta desse jogador?",
        buttonText: "Bloquear",
        alertText: this.doubleCheckModalText.BLOCK_ALERT_TEXT,
      },
      [this.playerAction.WITHDRAW_MANUAL]: {
        titleText: "Realmente deseja alterar o tipo de saque desse jogador para manual?",
        buttonText: "Alterar para saque manual",
        alertText: this.doubleCheckModalText.WITHDRAW_MANUAL_ALERT_TEXT,
      },
      [this.playerAction.WITHDRAW_AUTOMATIC]: {
        titleText: "Realmente deseja alterar o tipo de saque desse jogador para automático?",
        buttonText: "Alterar para saque automático",
        alertText: this.doubleCheckModalText.WITHDRAW_AUTOMATIC_ALERT_TEXT,
      },
      [this.playerAction.CUSTOMIZE_WITHDRAW]: {
        titleText: "Realmente deseja personalizar o saque desta conta?",
        buttonText: "Personalizar",
        alertText: this.doubleCheckModalText.CUSTOMIZE_WITHDRAW,
      },
    };

    const config = actionConfig[type as keyof typeof actionConfig];
    
    if (type === this.playerAction.CUSTOMIZE_WITHDRAW && !this.isModal) {
      this.onItemSelect(player);
    } else {
      const modalActivate = this.modalService.open(DoubleCheckModalComponent, {
        centered: true,
      });

      modalActivate.componentInstance.titleText = config.titleText;
      modalActivate.componentInstance.buttonText = config.buttonText;
      modalActivate.componentInstance.alertText = config.alertText;
      modalActivate.componentInstance.openModal(player, type);

      modalActivate.result.then(
        (result) => {
          if (result === 'confirmed') {
            this.onModalConfirmed(result);
          }  
        },
        (reason) => {
          console.log('Modal dismissed:', reason);
        }
      );
    }
  }


  onModalConfirmed(result: any): void {
    this.actionPerformed.emit(result);
  }

  onItemSelect(item: any) {
    this.itemSelect.emit(item);
  }

}