import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { Login } from 'src/app/auth/auth.model';
import { AuthService } from 'src/app/auth/auth.service';
import Constants from 'src/app/shared/constants';
import { NotificationService } from './../../shared/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  login: Login = {} as Login;
  validCredentials = true;
  userConfirmed = true;
  confirmUser = true;

  constructor(
    private notificationService: NotificationService,
    private service: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  onLogin(): void {
    this.login.role = 'CLUB';
    this.service.authenticate(this.login).subscribe(
      () => {
        this.notificationService.showSuccess(
          'Login efetuado com sucesso',
          'Login'
        );
        //this.router.navigate(['']);
        // Get url and then redirect
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
        let fragment = 'transaction';
        if (returnUrl.includes('#')) {
          [returnUrl, fragment] = returnUrl.split('#');
        }

        let queryParams: { [key: string]: string } = {};
        if (returnUrl.includes('?')) {
          let [path, queryString] = returnUrl.split('?');
          returnUrl = path;
          queryString.split('&').forEach((pair: string) => {
            const [key, value] = pair.split('=');
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
          });
        }
        const decodedUrl = decodeURIComponent(returnUrl);
        this.router.navigate([decodedUrl], { queryParams, fragment });
      },
      (error) => {
        this.validCredentials = true;
        this.userConfirmed = true;
        switch (error.error.error_code) {
          case Constants.UNAUTHORIZED_ACCESS:
            this.validCredentials = false;
            this.notificationService.showError('Dados inválidos', '');
            break;
          case Constants.NOT_CONFIRMED_ACCESS:
            this.userConfirmed = false;
            this.notificationService.showError('Conta não confirmada', '');
            break;
          default:
            this.notificationService.showError(
              'Não foi possível realizar o login',
              ''
            );
            break;
        }
      }
    );
  }

  onSignup(): void {
    this.onLogin();
  }

  onRecoveryPassword(confirmUser: boolean): void {
    this.cookieService.set('confirmUser', JSON.stringify(confirmUser));
    this.router.navigate(['recovery-password']);
  }

  onConfirmUser(confirmUser: boolean): void {
    this.cookieService.set('confirmUser', JSON.stringify(confirmUser));
    this.router.navigate(['wait-confirm']);
  }
}
