import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { Transaction } from 'src/app/pages/transactions/transaction.model';
import Constants from 'src/app/shared/constants';
import { NotificationService } from 'src/app/shared/notification.service';
import { addBalanceData, addBalanceResponse } from './../../pages/finance/finance.model';
import { FinanceService } from './../../pages/finance/finance.service';

@Component({
  selector: 'app-finance-add-funds-modal',
  templateUrl: './finance-add-funds-modal.component.html',
  styleUrls: ['./finance-add-funds-modal.component.scss']
})
export class FinanceAddFundsModalComponent {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  transaction!: Transaction;
  amount!: number;
  addBalanceData = {} as addBalanceData;

  @Output() deny = new EventEmitter();

  constructor(
    public service: FinanceService,
    private notificationService: NotificationService,
    private clipboardService: ClipboardService,
  ) { }

  /**
   * Open modal
   */
  public openModal(amount: any): void {
    amount = this.fixAmountFormat(amount);
    this.service.postAddBalance(amount).subscribe((addBalanceResponse: addBalanceResponse) => {
      this.autoShownModal.show();
      this.addBalanceData = addBalanceResponse.data;
    },
    (error) => {
      switch (error.error.errors[0].code) {
        case Constants.INVALID_REQUEST_PARAMETER:
          this.notificationService.showError('O valor minímo para adicionar é de R$ 1,00', 'Não foi possível adicionar');
          break;
        default:
          this.notificationService.showError('', 'Não foi possível adicionar');
      }
    })
  }

  private fixAmountFormat(amount: number): number {
    const formatString = amount.toString().split('.');
    if (!formatString[1]) {
      formatString[1] = '';
    }
    formatString[1] = formatString[1].padEnd(2, '0');
    return parseInt(formatString.join(''), 10);
  }

  public closeModal(): void {
    this.autoShownModal.hide();
    this.deny.emit();
  }

  copyContent(): void {
    this.clipboardService.copyFromContent(this.addBalanceData.pix_qr_code);
  }

  copied(event: any): void {
    if (event.isSuccess === true) {
      this.notificationService.showSuccess('', 'Código copiado!');
    }
  }
}
