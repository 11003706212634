<div class="modal-dialog modal-dialog-centered" role="dialog">
  <div class="modal-content border border-light">
    <div class="modal-header bg-light border-light">
      <h4 class="font-weight-bold">Confirmar envio manual</h4>
      <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <span class="percents-alert pt-0 text-dark">Confirma que foi realizado o envio de fichas 
          de forma manual para o jogador ID <strong>{{player?.playerId}}</strong>? <strong>Atenção!</strong> Esta informação
          <strong class="text-danger">não poderá</strong> ser alterada novamente.
        </span>
      </div>
      <div class="pt-3">
        <div class="rounded-0 border-0 alert alert-warning mb-0 d-flex align-items-center" role="alert">
          <i class="bi bi-x-circle-fill mr-3"></i>
          <small>
            <p class="m-0">
              Confira se as fichas foram enviadas pela Chippix antes de realizar o envio manual para o jogador.
              <strong>
                Não nos responsabilizamos pelo envio realizado manualmente.
              </strong>
            </p>
          </small>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button id="cancel-percent-button" type="button" class="percent-btn btn btn-light" data-dismiss="modal"
      (click)="cancel()">>Cancelar</button>
      <button type="submit" class="btn btn-primary" (click)="confirm()">>Sim, confirmar</button>
    </div>
  </div>
</div>