<div id="__layout">
  <div class="d-flex align-items-center min-vh-100 min-vw-100 bg-light p-0">
    <div class="container">
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Solicitação enviada com sucesso!
        </h2>
        <p class="mt-2 text-center text-muted">
          Assim que entrarmos em contato, você
          estará a alguns passos de usar o serviço Chippix.
      </div>
    </div>
  </div>
</div>