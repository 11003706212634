import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-confirm-manually-modal',
  templateUrl: './confirm-manually-modal.component.html',
  styleUrls: ['./confirm-manually-modal.component.scss']
})
export class ConfirmManuallyModalComponent implements OnInit {

  @Input('player') player: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  confirm() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.dismiss(false);
  }
}