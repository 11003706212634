<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="dialog">
    <div class="modal-content border border-light">
      <div class="modal-header bg-light border-light">
        <h4 class="font-weight-bold">Excluir Jogador</h4>
        <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <div class="row">
            <span class="delete-alert pt-0 text-dark">Você realmente deseja <span class="delete-span">excluir</span> o
              jogador de ID <span class="font-weight-bold">{{ rakebackPlayer?.playerId }}</span> da lista?
            </span>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button id="delete-rake-button" type="button" class="rake-btn btn btn-light" data-dismiss="modal"
            (click)="closeModal()">Cancelar</button>
          <button type="submit" class="delete btn btn-primary" id="delete-confirm" (click)="deletePlayer()">Excluir</button>
        </div>
      </form>
    </div>
  </div>
</div>