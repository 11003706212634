import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { Login } from 'src/app/auth/auth.model';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  login: Login = {} as Login;
  validPhoneNumber = true;
  confirmUser = false;
  batata: any;

  constructor(
    private notificationService: NotificationService,
    private service: AuthService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.confirmUser = JSON.parse(this.cookieService.get('confirmUser'));
  }

  ngOnInit(): void { }

  recoveryPassword(): void {
    this.separatePhoneNumber();
    this.login.role = 'CLUB';
    this.service.recoveryPassword(this.login).subscribe(
      () => {
        // this.showContent = false;
        this.notificationService.showSuccess('', 'Código enviado com sucesso');
        const navigationExtras: NavigationExtras = {
          queryParams: {
            ddd: this.login.ddd,
            phoneNumber: this.login.phoneNumber,
            confirmUser: this.confirmUser
          }
        };
        this.router.navigate(['change-password'], navigationExtras);
      },
      (error) => {
        switch (error.status) {
          case 400:
            if (error.error.data.includes('1 hour to send another request')) {
              this.notificationService.showError('Você poderá solicitar o códido novamente após 1 hora', 'Limite máximo excedido');
            }
            break;
          case 422:
            this.notificationService.showError('Número do telefone não existe', '');
            break;
          default:
            this.validPhoneNumber = false;
            this.notificationService.showError('Número do telefone inválido', '');
            break;
        }
      });
  }

  separatePhoneNumber(): void {
    this.login.ddd = parseInt(this.login.fullPhoneNumber.substring(0, 2), 10);
    this.login.phoneNumber = parseInt(this.login.fullPhoneNumber.substring(2, 11), 10);
  }
}
