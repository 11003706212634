<div bsModal #autoShownModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="dialog">
      <div class="modal-content border border-light">
        <div class="modal-header bg-light border-light">
          <h4 class="font-weight-bold">Enviar notificação</h4>
          <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="dropdown-divider"></div>
        <div class="modal-body">
          <div class="row">
            <span class="pt-0 noti-alert text-dark">Digite um título e uma mensagem para enviar para
              <span class="noti-number">{{ deselectedPlayersId.length > 0 || isAllPlayersSelected ? checkDeselectedPlayers() : selectedPlayersId.length}}</span> jogador(es) selecionado(s):
            </span>
          </div>
          <form>
            <div class="form-group">
              <label for="title-msg" class="col-form-label">Título</label>
              <div class="input-group my-2">
                <div class="input-group-prepend">
                  <label class="input-group-text bg-white">{{clubName}}</label>
                </div>
                <input type="text" class="title form-control" id="title-msg" placeholder="ex: Torneio 100k GTD!" [(ngModel)]='title'  maxlength="{{66 - clubName.length}}" onkeypress="return isNumberKey(event)">
              </div>
              <div class="counter-text text-muted">Limite de caracteres <b>{{66 - title.length - clubName.length}}</b></div>
            </div>
            <div class="form-group">
              <label for="message-text" class="text col-form-label">Mensagem</label>
              <textarea class="text form-control" id="message-text" rows="5" placeholder="Digite sua mensagem" [(ngModel)]='message' maxlength="245" onkeypress="return isNumberKey(event)"></textarea>
              <div class="counter-text text-muted mt-2">Limite de caracteres <b>{{245 - message.length}}</b></div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button id="cancel-push-notification-button" type="button" class="canc-btn btn btn-light" data-dismiss="modal" (click)="closeModal()">Cancelar</button>
          <button id="send-push-notification-button" type="button" class="btn btn-primary" (click)="sendNotification()" [disabled]="title.length === 0 || message.length === 0">Enviar</button>
        </div>
      </div>
    </div>
  </div>