import { DoubleCheckModalText } from './../../../shared/player.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { UserInfoModalComponent } from 'src/app/modals/user-info-modal/user-info-modal.component';
import { PlayerAction, PlayerStatus, PlayerStatusNamed } from 'src/app/shared/player.enum';
import { Criteria, Pageable, Player } from '../player.model';
import { PlayerService } from '../player.service';
import { DashboardData, GameAccounts } from './../player.model';
import { ActivatedRoute } from '@angular/router';
import { FeatureToggleService } from 'src/app/shared/feature-toggle.service';


@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit, OnDestroy {
  @ViewChild('userInfoModal', { static: true }) public userInfoModal!: UserInfoModalComponent;

  subscription: Subject<void> = new Subject();
  searchChanged: Subject<Criteria> = new Subject<Criteria>();

  criteria: Criteria = new Criteria();
  players: Player[] = [];
  filterByType: PlayerStatus = this.playerStatus.ALL;
  openModal = false;
  hasFocus: boolean = false;
  hasStatusFocus: boolean = false;
  playerId!: string;
  gameAccountData: GameAccounts = {} as GameAccounts;
  activeTab = 1;
  isInviteNewPlayersPage!: boolean;
  isPlayerInvitationAvailable!: boolean;
  isNewPlayer!: boolean;
  newPlayerId!: boolean;

  constructor(
    public service: PlayerService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private featureToggleService: FeatureToggleService,
  ) {
    this.searchChanged.pipe(debounceTime(700))
      .subscribe(searchCriteria => this.updatePlayerList(searchCriteria));
  }

  ngOnInit(): void {
    this.getDashboardData()
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.searchAcc) {
        this.criteria.searchTerm = params.searchAcc;
      }

      if(params.tab) {
        this.onRadioChange(params.tab) 
      }

      if (params.playerId) {
        this.newPlayerId = params.playerId;
        this.isNewPlayer = true;
      }

      this.updatePlayerList(this.criteria);
      this.getFeaturesStatus();
    });
  }

  getFeaturesStatus() {
    this.featureToggleService.getFeatures().subscribe((status) => {
      this.isPlayerInvitationAvailable = status.playerInvitation;
    });
  }

  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.unsubscribe();
  }

  onSearchChange(criteria: Criteria): void {
    this.searchChanged.next(criteria);
  }

  onRadioChange(tab: string): void {
    this.criteria.tab = tab;
    this.criteria.page = 1;
    this.updatePlayerList(this.criteria);
  }

  updatePlayerList(criteria: Criteria): any {
    if (!criteria.searchTerm) {
      this.spinner.show();
    }
    this.criteria.page = criteria.page;
    this.service.listPlayers(criteria)
      .pipe(
        takeUntil(this.subscription.asObservable()),
        finalize(() => {
          this.spinner.hide();
        }))
      .subscribe((response: Pageable<Player>) => {
        this.players = response.data;
        this.criteria.totalDocs = response.totalDocs || response.total;
        this.criteria.page = response.page;
        this.criteria.limit = response.limit;
        this.criteria.totalPages = response.totalPages || response.pages;

        if (this.criteria.tab === 'invited' && this.isPlayerInvitationAvailable) {
          this.onUpdateInviteNewPlayers();
        } else {
          this.isInviteNewPlayersPage = false;
        }
      });
  }

  /**
   * Change pagination page
   * @param paginationEvent page number clicked.
   */
  jumpPagination(paginationEvent: number): void {
    this.criteria.page = paginationEvent;
    this.updatePlayerList(this.criteria);
  }

  public get playerStatus(): typeof PlayerStatus {
    return PlayerStatus;
  }

  public get playerStatusNamed(): typeof PlayerStatusNamed {
    return PlayerStatusNamed;
  }

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  public get doubleCheckModalText(): typeof DoubleCheckModalText {
    return DoubleCheckModalText;
  }

  onFilterChange(event: PlayerStatus): void {
    this.criteria.listByStatus = event;
    this.updatePlayerList(this.criteria);
  }

  onDoubleCheckConfirmed(): void {
    this.getDashboardData();
    this.updatePlayerList(this.criteria);
  }

  openUserInfoModal(player: Player): void {
    this.userInfoModal.openModal(player);
  }

  getDashboardData(): void {
    this.service.getDashboardData().subscribe(
      (response: DashboardData) => {
        this.gameAccountData = response.data.gameAccounts;
      }
    )
  }

  onUpdateInviteNewPlayers(): void {
    this.isInviteNewPlayersPage = true;
  }
}
