import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { CashoutReceipt } from "src/app/shared/cashout-receipt.model";
import { AutoClaimbackErrorCode, TransactionStatus, TransactionStatusNamed, TransactionType } from "src/app/shared/transaction.enum";
import { ClaimbackPaymentType, ClaimbackPaymentTypeNamed } from "src/app/shared/control.enum";
import { NotificationService } from "src/app/shared/notification.service";
import { TransactionSlidePanelService } from './transaction-slide-panel.services';
import { ClipboardService } from "ngx-clipboard";
import { Transaction, TransactionResponse } from "../../transaction.model";
import { TransactionService } from "../../transaction.service";
import { Router, ActivatedRoute } from "@angular/router";
import { OperationData, Operation } from "src/app/pages/finance/finance.model";

@Component({
	selector: 'app-transaction-slide-panel',
	templateUrl: './transaction-slide-panel.component.html',
	styleUrls: ['./transaction-slide-panel.component.scss']
})
export class TransactionSlidePanelComponent implements OnInit, OnChanges {

	@Input('isOpen') isOpen: boolean = false;
	@Input('transaction') transaction: Transaction = {} as Transaction;

	@Output() eventClose: EventEmitter<any> = new EventEmitter();
	
  isSkeletonLoading: boolean = false;
	mustShowOperationDetails = false;
	operation: any;

	constructor(
		public service: TransactionService,
		private notificationService: NotificationService,
		private clipboardService: ClipboardService,
		private router: Router,
		private transactionSlidePanelSerevice: TransactionSlidePanelService,
		private route: ActivatedRoute
	) { }

	public get transactionType(): typeof TransactionType {
		return TransactionType;
	  }

	ngOnInit(): void { }

	ngOnChanges(): void { }

	onBack() {
		if (this.mustShowOperationDetails) {
		  this.mustShowOperationDetails = false
		} else {
		  this.isOpen = false;
		  this.eventClose.emit();
		}
	  }

	onGenereteCashoutReceipt(pixId: string) {
		this.service.getCashoutReceipt(pixId).subscribe(
			(cashoutReceipt: Blob) => {
				const fileURL = URL.createObjectURL(cashoutReceipt);
				
				window.open(fileURL, '_blank');
				
				const downloadAnchor = document.createElement('a');
				downloadAnchor.href = fileURL;
				downloadAnchor.download = 'Comprovante.pdf';
				
				document.body.appendChild(downloadAnchor);
				downloadAnchor.click();
				document.body.removeChild(downloadAnchor);
				
				URL.revokeObjectURL(fileURL);
			}
		);
	}

	openOperationDetails(transactionId: string) {
		this.transactionSlidePanelSerevice.getOperationByTransactionId(transactionId)
			.subscribe(
				(operation: OperationData) => {
					this.operation = operation.data;
					console.log("openOperationDetails", this.operation)
					this.mustShowOperationDetails = true;
				},
				(err) => {
					console.log("err", err)
					switch (err.status) {
						//Verificar regras de negócio
						case 422:
							this.notificationService.showError('Esta movimentação ainda não existe. Aguarde até o pagamento ser efetuado.', 'Movimentação não existe');
							break;
						default:
							this.notificationService.showError('Comunique a equipe Chippix para entender o que está acontecendo.', 'Movimentação inválida');
							break;
					}
				}
			)
  }

	goToTransactionsList() {
        let fragment = 'finance';
		this.router.navigate(['/finance'], { fragment });
	}
}