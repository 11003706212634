import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QRCodeModule } from 'angularx-qrcode';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { TokenInterceptor } from './auth/token.interceptor';
import { BASE_SOCKET_URL } from './config/settings';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { LoginComponent } from './pages/login/login.component';
import { PageModule } from './pages/page.module';
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SmsConfirmComponent } from './pages/sms-confirm/sms-confirm.component';
import { WaitConfirmComponent } from './pages/wait-confirm/wait-confirm.component';
import { TermsPoliticsModalModule } from './modals/terms-politics-modal/terms-politics-modal.module';
import { ModalService } from './shared/modal.service';

const config: SocketIoConfig = { url: BASE_SOCKET_URL, options: {} };

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'signup', component: SignupComponent },
  { path: 'confirm', component: SmsConfirmComponent },
  { path: 'wait-confirm', component: WaitConfirmComponent },
  { path: 'recovery-password', component: RecoveryPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoveryPasswordComponent,
    SignupComponent,
    ChangePasswordComponent,
    SmsConfirmComponent,
    WaitConfirmComponent
  ],
  imports: [
    AuthModule,
    TermsPoliticsModalModule,
    PageModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    SocketIoModule.forRoot(config),
    NgxSpinnerModule,
    NgbModule,
    TooltipModule.forRoot(),
    QRCodeModule,
    NgxCurrencyModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false
    }),
    NgxSkeletonLoaderModule,
    NgxChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    ModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
