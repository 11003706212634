import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { PushNotificationComponent } from './push-notification.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
    QRCodeModule,
    ClipboardModule,
    NgxMaskModule,
    RouterModule,
  ],
  declarations: [
    PushNotificationComponent
  ],
  exports: [
    PushNotificationComponent
  ],
  bootstrap: [
    PushNotificationComponent
  ]
})

export class PushNotificationModule { }