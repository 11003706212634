import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ImportedPlayer, Criteria, ClubFinancialStatus, ClubFinancialStatusNamed, Pageable, RakebackPayment } from '../../club-financials.model'
import { NotificationService } from 'src/app/shared/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClubPercentsModalComponent } from 'src/app/modals/club-percents-modal/club-percents-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ClubFinancialsService } from '../../club-financials.service';
import { FinancialSlidePanelComponent } from '../financial-slide-panel/financial-slide-panel.component';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';
import { FinancialImportListComponent } from '../financial-import-list/financial-import-list.component';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { FinancialUnreleatedListComponent } from '../financial-unreleated-list/financial-unreleated-list.component';


@Component({
  selector: 'app-financial-settings',
  templateUrl: './financial-settings.component.html',
  styleUrls: ['./financial-settings.component.scss'],
})
export class FinancialSettingsComponent implements OnInit, OnChanges {

  @ViewChild('clubPercentsModalComponent', { static: true }) public clubPercentsModalComponent!: ClubPercentsModalComponent;
  @ViewChild('financialSlidePanel') public financialSlidePanel!: FinancialSlidePanelComponent;
  @ViewChild('financialImportList') public financialImportList!: FinancialImportListComponent;
  @ViewChild('financialUnreleatedList') public financialUnreleatedList!: FinancialUnreleatedListComponent;

  @Output() eventClose: EventEmitter<any> = new EventEmitter();

  resumeData = [{
    title: "Total efetuados",
    number: 8,
    icon: "assets/icons/clubFinancials/DONE.svg"
  },
  {
    title: "Total processando",
    number: 1,
    icon: "assets/icons/clubFinancials/PROCESSING.svg"
  },
  {
    title: "Total na fila",
    number: 5,
    icon: "assets/icons/clubFinancials/ON_QUEUE.svg"
  },
  {
    title: "Total com erros",
    number: 1,
    icon: "assets/icons/clubFinancials/FAILED.svg"
  }];

  rakebackPlayers: ImportedPlayer[] = [];

  criteria: Criteria = new Criteria();
  unrelatedPlayersList: [] = [];
  playersList: ImportedPlayer[] = [];

  hasFocus: boolean = false;
  hasTitleFocus: boolean = false;
  isNewImport: boolean;
  isVisible: boolean = true;
  isSkeletonLoading: boolean = true;

  currentView = 'WITH_PERCENTS'
  progress: string = '0%';
  pageView!: string;

  selectedFinancialID!: any;
  selectedFileName!: any;
  selectedFileStatus!: any;
  selectedFileTotalPlayers!: any;

  isSlidePanelOpen: boolean = false;
  selectedPlayer!: any;
  status: string | undefined;
  searchChanged: Subject<Criteria> = new Subject<Criteria>();
  subscription: Subject<void> = new Subject();

  public get clubFinancialStatus(): typeof ClubFinancialStatus {
    return ClubFinancialStatus;
  }

  public get clubFinancialStatusNamed(): typeof ClubFinancialStatusNamed {
    return ClubFinancialStatusNamed;
  }

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private clubFinancialService: ClubFinancialsService,
    private notificationService: NotificationService,
    public spinner: NgxSpinnerService,
    private sidebarControlService: SidebarService,
  ) {
    this.searchChanged.pipe(debounceTime(700))
      .subscribe(searchCriteria => this.getRakebackPlayerList(searchCriteria));

    this.selectedFinancialID = this.activatedRoute.snapshot.queryParams.clubFinancialsID;

    this.selectedFileName = this.activatedRoute.snapshot.queryParams.clubFinancialsFilename;
    
    this.selectedFileStatus = this.activatedRoute.snapshot.queryParams.clubFinancialsStatus;

    this.selectedFileTotalPlayers = this.activatedRoute.snapshot.queryParams.clubFinancialsTotalPlayers;

    this.isNewImport = !this.selectedFinancialID;
    
    this.pageView = 'details';
  }

  ngOnChanges(): void { }

  ngOnInit(): void {
    this.getRakebackPlayerList(this.criteria);
  }

  onSendRakeback() {
    const rakebackPlayers: Array<RakebackPayment> = this.rakebackPlayers.map((rakebackPlayer: ImportedPlayer) => {
      return {
        _gameAccount: rakebackPlayer._gameAccount,
        rakebackValue: rakebackPlayer.rakebackValue,
        rakebackPlayerId: rakebackPlayer._id
      };
    })
    this.clubFinancialService.createRakebackPayment(rakebackPlayers)
      .subscribe(
        (response: any) => {
          this.onRefreshPage();
          this.notificationService.showInfo('As fichas estão em processo de envio para os jogadores.', 'Fichas em processo de envio');
        },
        (error) => {
          switch (error.status) {
            case 412:
              this.spinner.hide();
              this.notificationService.showError('Por favor, cadastre uma chave PIX', 'Não foi possível realizar o pagamento de fichas');
              break;
            case 423:
              this.spinner.hide();
              this.notificationService.showError('Não foi possivel realizar esta ação, pois o depósito do clube está em manutenção', 'Deposito em manutenção');
              break;
            default:
              this.spinner.hide();
              this.notificationService.showError('Algum erro ocorreu durante o processo', 'Erro');
              break;
          }
        }
      )
  }

  onBack() {
    this.location.back();
  }

	getRakebackPlayerList(criteria: Criteria): any {
    this.isSkeletonLoading = true;

		if (!criteria.searchTerm) {
			this.spinner.show();
    }

		this.criteria.status = this.selectedFileStatus ? this.selectedFileStatus : ClubFinancialStatus.NOT_STARTED;
		this.criteria.searchTerm = criteria.searchTerm;
		this.criteria.page = criteria.page;
		this.criteria.rakebackSenderId = criteria.rakebackSenderId;
		this.criteria.filterBy = criteria.filterBy;

		this.clubFinancialService.getListRakebackPlayers(this.criteria, this.selectedFinancialID).pipe(
			takeUntil(this.subscription.asObservable()),
			finalize(() => {
				this.spinner.hide();
			})
		)
			.subscribe(
				(response: Pageable<ImportedPlayer>) => {
          this.rakebackPlayers = response.data;
					this.criteria.total = response.total;
					this.criteria.page = response.page;
					this.criteria.limit = response.limit;
					this.criteria.pages = response.pages;

          this.isSkeletonLoading = false;
				},
				(error) => {
          console.error(error);
          this.notificationService.showError('Houve um erro para listar os jogadores importados', 'Erro ao listar jogadores');
				}
			);
	}

  onSettingsViewChange(selectedPageView: string) {
    this.pageView = selectedPageView;

    if (selectedPageView === 'details') {
      this.getRakebackPlayerList(this.criteria);
    }
  }

  onAccountViewChange(viewType: ClubFinancialStatus = ClubFinancialStatus.WITH_PERCENTS) {
    this.criteria.filterBy = viewType;
    this.currentView = viewType;
    this.getRakebackPlayerList(this.criteria);
    this.criteria.searchTerm = '';
    this.cdr.detectChanges();
  }

  updateGameAccountRakeback(player: any) {
    this.spinner.show();
    this.clubFinancialService.patchGameAccountRakebackPercent(player)
      .pipe(
        takeUntil(this.subscription.asObservable()),
        finalize(() => {
          this.spinner.hide();
        }))
      .subscribe(
        (response: any) => {
          this.getRakebackPlayerList(this.criteria);
          this.notificationService.showInfo('Porcentagem alterada com sucesso.', 'O valor da porcentagem foi alterado');
        },
        (error: any) => {
          switch (error.status) {
            default:
              this.spinner.hide();
              this.notificationService.showError('Algum erro ocorreu durante o processo', 'Erro');
              break;
          }
        }
      );
  }

  onPlayerPercentageUpdated(player: any) {
    this.updateGameAccountRakeback(player);
    this.cdr.detectChanges();
  }

  onSearchChange(criteria: Criteria): void {
    this.searchChanged.next(criteria);
  }

  openClubPercentsModal(filteredPlayersList: any): void {
    this.clubPercentsModalComponent.openModal(filteredPlayersList);
  }

  closeClubPercentsModal(): void {
    this.clubPercentsModalComponent.closeModal();
  }

  closeFinancialSlidePanel() {
    this.isSlidePanelOpen = false;
    this.selectedPlayer = null;
    this.financialImportList.clearPlayerSelected();
    this.sidebarControlService.toggleSidebarVisibility(true);
  }

  onPlayerSelection(player: any) {
    this.isSlidePanelOpen = true;
    this.selectedPlayer = { ...player };
    this.sidebarControlService.toggleSidebarVisibility(false);
  }

  onDeleteRakeback(isPlayerDeleted: any) {
    if (isPlayerDeleted) {
      this.onRefreshPage();
    }
  }

  updatePlayerState(updatedPlayer: ImportedPlayer) {
    const index = this.rakebackPlayers.findIndex((p: ImportedPlayer) => p._id === updatedPlayer._id);
    if (index > -1) {
      this.rakebackPlayers[index] = updatedPlayer;
    }
  }

  getEmptyStateMessage(): string {
    if (this.criteria.searchTerm) {
      return 'Não encontramos jogadores com esta busca. Verifique se o ID do jogador foi digitado corretamente e tente novamente.';
    }
  
    switch (this.criteria.filterBy) {
      case this.clubFinancialStatus['WITH_PERCENTS']:
        return 'Não há jogadores com % definida.';
      case this.clubFinancialStatus['UNRELEATED']:
        return 'Nenhum jogador não vinculado encontrado.';
      default:
        return 'Não há jogadores sem % definida.';
    }
  }

  onRefreshPage() {
    const criteria = new Criteria();

    criteria.status = this.selectedFileStatus ? this.selectedFileStatus : ClubFinancialStatus.NOT_STARTED;
    criteria.filterBy = ClubFinancialStatus.WITH_PERCENTS;
    criteria.searchTerm = '';
    criteria.page = 1;

    this.currentView = ClubFinancialStatus.WITH_PERCENTS;

    this.getRakebackPlayerList(criteria);
    
    this.cdr.detectChanges();
  }

  wasRakebackPlayerSent() {
    const allHaveJaimeTask = this.rakebackPlayers.every(player => '_jaimeTask' in player);
    return allHaveJaimeTask
  }

  isSendRakebackDisabled(): boolean {
    return (
      (this.rakebackPlayers.length > 0 && this.criteria.filterBy === this.clubFinancialStatus['WITHOUT_PERCENTS']) ||
      (this.rakebackPlayers.length < this.selectedFileTotalPlayers) ||
      (this.selectedFileStatus !== this.clubFinancialStatus['NOT_STARTED']) ||
      this.wasRakebackPlayerSent()
    );
  }
  
}