<div class="h4 font-weight-bold">Controle</div>
<div class="container-fluid bg-light">
  <div class="row row-cols-1 row-cols-md-2 justify-content-between">
    <div class="col-md-4 mt-5 p-0">
      <h5>Controle dos jogadores</h5>
      <p class="">
        Personalize os <strong>valores mínimos de compra/saque</strong> e a <strong>quantidade de saques</strong> que
        seus jogadores poderão
        realizar diariamente. Além disso, você pode habilitar/desabilitar o recebimento de <strong>mensagens de
          notificações de saque</strong>.
      </p>
    </div>
    <div class="col-md-7 mt-5 p-0 rounded-lg bg-white">
      <form #playerControlForm="ngForm" class="form">
        <div class="p-4 pt-0">
          <div class="form-group row">
            <label class="col-sm-7 col-form-label" for="minValueToBuy">Valor mínimo de compra</label>
            <div class="col-lg-5">
              <div class="input-group mb-2 mr-sm-2 mr-4">
                <div class="input-group-prepend">
                  <div class="input-group-text">R$</div>
                </div>
                <input type="tel" class="form-control" name="minValueToBuy" [(ngModel)]="settings.minValueToBuy"
                  #minValueToBuy="ngModel"
                  [ngClass]="{ 'is-invalid': playerControlForm.submitted && minValueToBuy.invalid}" required
                  minValueToBuy currencyMask />
                <div *ngIf="playerControlForm.submitted && minValueToBuy.invalid" class="invalid-feedback">
                  <div *ngIf="minValueToBuy.errors?.required">Esse campo é obrigatório</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-7 col-form-label" for="minValueToWithdraw">Valor mínimo de saque</label>
            <div class="col-lg-5">
              <div class="input-group mb-2 mr-sm-2 mr-4">
                <div class="input-group-prepend">
                  <div class="input-group-text">R$</div>
                </div>
                <input type="tel" class="form-control" name="minValueToWithdraw"
                  [(ngModel)]="settings.minValueToWithdraw" #minValueToWithdraw="ngModel"
                  [ngClass]="{ 'is-invalid': playerControlForm.submitted && minValueToWithdraw.invalid }" required
                  minValueToWithdraw currencyMask />
                <div *ngIf="playerControlForm.submitted && minValueToWithdraw.invalid" class="invalid-feedback">
                  <div *ngIf="minValueToWithdraw.errors?.required">Esse campo é obrigatório</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-7 col-form-label" for="maxWithdrawPerDay">Limite de saques diário</label>
            <div class="col-lg-3">
              <input type="number" class="form-control" name="maxWithdrawPerDay"
                [(ngModel)]="settings.maxWithdrawPerDay" #maxWithdrawPerDay="ngModel"
                [ngClass]="{ 'is-invalid': playerControlForm.submitted && maxWithdrawPerDay.invalid}" required
                maxWithdrawPerDay />
              <div *ngIf="playerControlForm.submitted && maxWithdrawPerDay.invalid" class="invalid-feedback">
                <div *ngIf="maxWithdrawPerDay.errors?.required">Esse campo é obrigatório</div>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-lg-7 col-form-label" for="mustReceiveAutoWithdrawMessages">Mensagens de
              notificações dos saques</label>
            <div class="col-lg-5">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="mustReceiveAutoWithdrawMessages"
                  [(ngModel)]="settings.mustReceiveAutoWithdrawMessages" name="mustReceiveAutoWithdrawMessages">
                <label class="custom-control-label" for="mustReceiveAutoWithdrawMessages">
                  <span *ngIf="settings.mustReceiveAutoWithdrawMessages; else showDesativado"
                    style="font-size: 0.8em; color: #888;">Ativado</span>
                  <ng-template #showDesativado>
                    <span style="font-size: 0.8em; color: #888;">Desativado</span>
                  </ng-template>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-md-2 justify-content-between">
    <div class="col-md-4 mt-5 p-0">
      <h5>Tipos de saques</h5>
      <p>
        Defina o tipo de saque: <strong>Saque automático</strong> ou
        <strong>Saque manual</strong> para realizar o pagamento das solicitações dos seus jogadores.
      </p>
    </div>
    <div class="col-md-7 mt-5 p-0 rounded-lg bg-white">
      <form #claimbackPaymentTypeForm="ngForm" class="form" id="ngForm">
        <div class="p-4 pt-0">
          <div class="form-group row">
            <label class="col-lg-6 col-form-label" for="selecObject">Tipo de saque</label>
            <div class="col-lg-6">
              <select [ngClass]="{ 'is-invalid': claimbackPaymentTypeForm.submitted && claimbackPaymentType.invalid }"
                class="custom-select my-1 mr-sm-2" id="selecObject" #claimbackPaymentType="ngModel"
                [(ngModel)]="this.settings.claimbackPaymentType" name="claimbackPaymentType" required
                claimbackPaymentType>
                <option [value]="claimbackPaymentTypeEnum.AUTOMATIC">
                  {{ claimbackPaymentTypeNamedEnum[claimbackPaymentTypeEnum.AUTOMATIC] }}
                </option>
                <option [value]="claimbackPaymentTypeEnum.MANUALLY">
                  {{ claimbackPaymentTypeNamedEnum[claimbackPaymentTypeEnum.MANUALLY] }}
                </option>
              </select>
              <div *ngIf="claimbackPaymentTypeForm.submitted && claimbackPaymentType.invalid" class="invalid-feedback">
                <div *ngIf="claimbackPaymentType.errors?.required">Esse campo é obrigatório</div>
              </div>
            </div>
          </div>
          <div *ngIf="this.settings.claimbackPaymentType === claimbackPaymentTypeEnum.AUTOMATIC" class="form-group row">
            <label class="col-lg-6 col-form-label" for="maxValueToAutoClaimback">Valor máximo</label>
            <div class="col-lg-5">
              <div class="input-group mb-2 mr-sm-2 mr-4">
                <div class="input-group-prepend">
                  <div class="input-group-text">R$</div>
                </div>
                <input type="tel" class="form-control" name="maxValueToAutoClaimback"
                  [(ngModel)]="settings.maxValueToAutoClaimbackPayment" #maxValueToAutoClaimback="ngModel"
                  [ngClass]="{ 'is-invalid': claimbackPaymentTypeForm.submitted && maxValueToAutoClaimback.invalid }"
                  required maxValueToAutoClaimback currencyMask />
                <div *ngIf="claimbackPaymentTypeForm.submitted && maxValueToAutoClaimback.invalid"
                  class="invalid-feedback">
                  <div *ngIf="maxValueToAutoClaimback.errors?.required">Esse campo é obrigatório</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.settings.claimbackPaymentType === claimbackPaymentTypeEnum.AUTOMATIC"
            class="rounded-0 border-0 alert alert-info mt-4 mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-info-circle-fill mr-3"></i>
            <small>Se uma solicitação de saque exceder o <strong>Valor máximo</strong> estipulado para o
              <strong>Saque automático</strong>, essa solicitação deverá ser feita manualmente.</small>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-5 py-3 border-top border-light align-items-end d-flex justify-content-end">
    <button type="submit" class="btn btn-primary" (click)="onControlSubmit()">
      Salvar alterações
    </button>
  </div>
</div>