export enum TransactionStatus {
  ALL = 'ALL',
  DONE = 'DONE',
  ERROR = 'ERROR',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  PROCESSING = 'PROCESSING',
  REFUNDED = 'REFUNDED',
  SENDING_CHIPS = 'SENDING_CHIPS',
  WITHDRAWING_CHIPS = 'WITHDRAWING_CHIPS',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export enum TransactionStatusNamed {
  ALL = 'Todos',
  DONE = 'Efetuado',
  ERROR = 'Erro',
  PAID = 'Pago',
  NOT_PAID = 'Não pago',
  PROCESSING = 'Processando',
  REFUNDED = 'Reembolsado',
  SENDING_CHIPS = 'Enviando fichas',
  WITHDRAWING_CHIPS = 'Retirando fichas',
  WAITING_PAYMENT = 'Aguardando pgto',
}

export enum TransactionType {
  PIX = 'PIX',
  WITHDRAW = 'WITHDRAW',
}

export enum TransactionTypeNamed {
  PIX = 'Vendas',
  WITHDRAW = 'Saques',
}

export enum AutoClaimbackErrorCode {
  CLUB_HAS_NO_BALANCE = 'CLUB_HAS_NO_BALANCE',
  PIX_DESTINATION_NOT_CREATED = 'PIX_DESTINATION_NOT_CREATED',
  PIX_NOT_STARTED = 'PIX_NOT_STARTED',
  PIX_NOT_CONFIRMED = 'PIX_NOT_CONFIRMED',
  PIX_REFUSED = 'PIX_REFUSED',
  TWO_STEP_NOT_CONFIRMED = 'TWO_STEP_NOT_CONFIRMED',
  VALUE_EXCEEDED = 'VALUE_EXCEEDED',
  MANUAL_WITHDRAW_STATE = 'MANUAL_WITHDRAW_STATE',
  NONE = 'NONE'
}
