<app-delete-rake-modal #deleteRakeModalComponent (rakeDeleted)="onPlayerDelete($event)"></app-delete-rake-modal>

<div class="table-responsive table table-striped table-hover mt-3">
  <table class="table table-borderless mb-0">
    <thead class="border-top border-bottom border-light">
      <tr class="text-muted">
        <th scope="col">Jogador ID</th>
        <th scope="col">Porcentagem</th>
        <th scope="col">Rake</th>
        <th scope="col">Rakeback</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr class="border-top border-bottom table-d1" *ngFor="let player of rakebackPlayers"
        [ngClass]="{'selected' : selectedPlayer === player}">
        <td class="align-middle">
          <ngb-highlight [result]="player.playerId" [term]="criteria.searchTerm"></ngb-highlight>
        </td>
        <td class="align-middle" (click)="editPercentage(player)"
          [ngClass]="{'add-button' : !player.rakebackPercent && player.status === 'NOT_STARTED',
                      'edit-button' : player.rakebackPercent && player.status === 'NOT_STARTED' }">
          <span *ngIf="!editing || editingPlayerId !== player._id"
            [ngClass]="{'no-value' : !player.rakebackPercent}"
            class="phone-info">
            {{ player.rakebackPercent ? player.rakebackPercent + '%' : 'Sem porcentagem definida' }}
          </span>
          <input #percentageInput *ngIf="editing && editingPlayerId === player._id" type="number" min="1" max="100"
            maxlength="3" placeholder="1% a 100%" class="form-control w-75"
            [ngClass]="{'is-invalid': errorMessage && editingPlayerId === player._id}"
            [(ngModel)]="player.rakebackPercent" (blur)="validatePercentage(player)">
          <div *ngIf="errorMessage && editingPlayerId === player._id" class="error-message">{{ errorMessage }}</div>
        </td>
        <td class="align-middle">
          <span *ngIf="player.rake">
            {{ player.rake }}
          </span>
          <span *ngIf="!player.rake" class="text-muted font-italic">
            Sem rake definido
          </span>
        </td>
        <td class="align-middle">
          <ng-container *ngIf="player.rakebackValue; else noRakeback">
            {{ (player.rakebackValue / 100) | number:'1.2-2' }}
            <span class="amount-txt">
              {{ (player.rakebackValue / 100) >= 1 && (player.rakebackValue / 100) < 2 ? 'ficha' : 'fichas' }} </span>
          </ng-container>
          <ng-template #noRakeback>
            <span class="text-muted font-italic">Sem rake definido</span>
          </ng-template>
        </td>
        <td [ngSwitch]="player.status" class="font-weight-light align-middle">
          <span class="badge badge-pill badge-warning font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.IN_PROGRESS'>{{clubFinancialStatusNamed[clubFinancialStatus.IN_PROGRESS]}}</span>
          <span class="badge badge-pill badge-success font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.SUCCESS'>{{clubFinancialStatusNamed[clubFinancialStatus.SUCCESS]}}</span>
          <span class="badge badge-pill badge-info font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.IN_QUEUE'>{{clubFinancialStatusNamed[clubFinancialStatus.IN_QUEUE]}}</span>
          <span class="badge badge-pill badge-info font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.TO_QUEUE'>{{clubFinancialStatusNamed[clubFinancialStatus.TO_QUEUE]}}</span>
          <span class="badge badge-pill badge-danger font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.FAILURE'>{{clubFinancialStatusNamed[clubFinancialStatus.FAILURE]}}</span>
          <span class="badge badge-pill badge-waiting-start font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.NOT_STARTED'>{{clubFinancialStatusNamed[clubFinancialStatus.NOT_STARTED]}}</span>
          <span class="badge badge-pill badge-no-percentage font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.WITH_PERCENTS'>{{clubFinancialStatusNamed[clubFinancialStatus.WITH_PERCENTS]}}</span>
          <span class="badge badge-pill badge-no-percentage font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.NO_PERCENTAGE_DEFINED'>{{clubFinancialStatusNamed[clubFinancialStatus.NO_PERCENTAGE_DEFINED]}}</span>
          <span class="badge badge-pill badge-no-percentage font-weight-normal"
            *ngSwitchCase='clubFinancialStatus.UNRELEATED'>{{clubFinancialStatusNamed[clubFinancialStatus.UNRELEATED]}}</span>
        </td>
        <td [ngSwitch]="player.status" class="text-right align-middle">
          <button *ngSwitchCase='clubFinancialStatus.FAILURE' type="button" class="nav-btn btn btn-light btn-sm border"
            (click)="openDetailedPanel(player)">
            <span class="material-symbols-outlined">chevron_right</span>
          </button>
          <button *ngSwitchCase='clubFinancialStatus.NOT_STARTED' class="delete button"
            (click)="openDeleteRakeModal(player)">
            <span class="material-symbols-outlined">
              delete
            </span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="row d-flex justify-content-between table-responsive m-0">
    <p class="mt-4 mx-3">
      <small class="font-weight-light">
        Exibindo
        <span class="font-weight-normal">{{criteria.page}}</span>
        de
        <span class="font-weight-normal">{{criteria.pages}}</span>
        {{criteria.pages > 1 ? 'páginas': 'página'}}
      </small>
    </p>
    <ngb-pagination class="mt-3 mx-3" [collectionSize]="criteria.total" [(page)]="criteria.page"
      [pageSize]="criteria.limit" [maxSize]="maxSize" [rotate]="rotatePagination" [ellipses]="false"
      [boundaryLinks]="true" (pageChange)="jumpPagination($event)">
    </ngb-pagination>
  </div>