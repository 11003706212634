import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClubFinancialsService } from 'src/app/pages/club-financials/club-financials.service';
import { ImportedPlayer } from 'src/app/pages/club-financials/club-financials.model';

@Component({
  selector: 'app-delete-rake-modal',
  templateUrl: './delete-rake-modal.component.html',
  styleUrls: ['./delete-rake-modal.component.scss']
})
export class DeleteRakeModalComponent implements OnInit {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;
  
  @Output() eventClose: EventEmitter<any> = new EventEmitter();
  @Output() rakeDeleted: EventEmitter<any> = new EventEmitter(); 

  rakebackPlayer: any;

  constructor(
    private notificationService: NotificationService,
    private clubFinancialService: ClubFinancialsService,
    ) { }

  ngOnInit(): void {
  }

  deletePlayer(): void {
    this.clubFinancialService.getDeleteRakebackPlayer(this.rakebackPlayer._id, this.rakebackPlayer._rakebackSender)
      .subscribe({
        next: (response) => {
          this.notificationService.showSuccess('Jogador foi excluído com sucesso!', 'Jogador excluído!');
          this.rakeDeleted.emit(true);
          this.closeModal();
        },
        error: (err) => {
          this.notificationService.showError('Falha ao excluir jogador da lista.', 'Erro');
          this.rakeDeleted.emit(false);
          this.closeModal();
        }
      });
  }

  /**
 * Open modal
 */
  public openModal(): void {
    //this.spinner.hide();
    this.autoShownModal.show()
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.autoShownModal.hide();
    this.eventClose.emit();
  }

}
