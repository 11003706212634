import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  ClaimbackPaymentType,
  ClaimbackPaymentTypeNamed,
} from 'src/app/shared/control.enum';
import { NotificationService } from 'src/app/shared/notification.service';
import { ClubInfo, ClubResponse, Settings } from './control.model';
import { ControlService } from './control.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
})
export class ControlComponent implements OnInit {
  clubInfo: ClubInfo = {} as ClubInfo;
  settings: Settings = {} as Settings;
  minValueToBuy!: number;
  minValueToWithdraw!: number;
  maxWithdrawPerDay!: number;

  public get claimbackPaymentTypeEnum(): typeof ClaimbackPaymentType {
    return ClaimbackPaymentType;
  }

  public get claimbackPaymentTypeNamedEnum(): typeof ClaimbackPaymentTypeNamed {
    return ClaimbackPaymentTypeNamed;
  }

  constructor(
    public service: ControlService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getClub();
  }

  getClub(): void {
    this.service.getClubInfo().subscribe((response: ClubResponse) => {
      this.clubInfo = response.data;
      this.settings = response.data.settings;
      this.settings.minValueToBuy = response.data.settings.minValueToBuy / 100;
      this.settings.minValueToWithdraw =
        response.data.settings.minValueToWithdraw / 100;
      this.settings.maxValueToAutoClaimbackPayment =
        response.data.settings.maxValueToAutoClaimbackPayment / 100;
      this.settings.mustReceiveAutoWithdrawMessages =
        response.data.settings.mustReceiveAutoWithdrawMessages;

      this.route.queryParams.pipe(take(1)).subscribe((params) => {
        if (params['mustEnableAutoWithdrawMsg']) {
          this.settings.mustReceiveAutoWithdrawMessages = JSON.parse(
            params['mustEnableAutoWithdrawMsg']
          );
          this.onControlSubmit();
        }
      });
    });
  }

  updateClubSettings(): void {
    this.clubInfo.settings = this.settings;
    const clubInfoCopy = JSON.parse(JSON.stringify(this.clubInfo));
    clubInfoCopy.settings.minValueToBuy = this.fixAmountFormat(
      clubInfoCopy.settings.minValueToBuy
    );
    clubInfoCopy.settings.minValueToWithdraw = this.fixAmountFormat(
      clubInfoCopy.settings.minValueToWithdraw
    );
    clubInfoCopy.settings.maxValueToAutoClaimbackPayment = this.fixAmountFormat(
      clubInfoCopy.settings.maxValueToAutoClaimbackPayment
    );
    clubInfoCopy.settings.mustReceiveAutoWithdrawMessages =
      this.settings.mustReceiveAutoWithdrawMessages;
    this.service.putClubSettings(clubInfoCopy).subscribe(
      (data) => {
        this.notificationService.showSuccess('Controle atualizado!', '');
      },
      (error) => {
        this.notificationService.showError('Erro ao atualizar os dados', '');
      }
    );
  }

  private fixAmountFormat(amount: number): number {
    const formatString = amount.toString().split('.');
    if (!formatString[1]) {
      formatString[1] = '';
    }
    formatString[1] = formatString[1].padEnd(2, '0');
    return parseInt(formatString.join(''), 10);
  }

  onControlSubmit(): void {
    this.updateClubSettings();
  }
}
