import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { UserInfoModalModule } from './../../modals/user-info-modal/user-info-modal.module';
import { PlayerComponent } from './player.component';
import { PlayerListComponent } from './players/player-list.component';
import { CustomizeWithdrawModule } from 'src/app/components/customize-withdraw/customize-withdraw.module';
import { ActionsModule } from 'src/app/components/actions/actions.module';
import { PushNotificationModule } from 'src/app/modals/push-notification/push-notification.module';
import { customCurrencyMaskConfig } from 'src/app/config/custom-currency-mask.config';
import { InviteNewPlayersModule } from 'src/app/components/invite-new-players/invite-new-players.module';
import { NgxCurrencyModule } from 'ngx-currency';


@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
    QRCodeModule,
    ClipboardModule,
    NgxMaskModule,
    RouterModule,
    UserInfoModalModule,
    CustomizeWithdrawModule,
    ActionsModule,
    PushNotificationModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    InviteNewPlayersModule
  ],
  declarations: [
    PlayerListComponent,
    PlayerComponent,
  ],
  exports: [
    PlayerComponent
  ],
  bootstrap: [
    PlayerComponent
  ]
})
export class PlayerModule { }
