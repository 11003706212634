import { Component, Input, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LegendPosition, NgxChartsModule, Timeline } from '@swimlane/ngx-charts';
import { Observable } from 'rxjs';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-operation-chart',
  templateUrl: './operationChart.component.html',
  styleUrls: ['./operationChart.component.scss']
})
export class OperationChartComponent implements OnInit {
  @Input() view!: [number, number];
  @Input() chartData !: Observable<any>;

  multi!: any[];

  // options
  animations = true;
  showDataLabel = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Operações';
  legendTitle: string = '';
  yAxisTickFormatting = this.yAxisFormat;
  legendPosition = LegendPosition.Right;

  colorScheme = {
    domain: [
      '#18bc9c',
      '#342b4f',
      '#f39c12',
      '#d00158',
      '#8b0000',
    ]
  };

  constructor(
    private readonly dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    this.chartData.subscribe(data => {
      if (data && data.length > 60) {
        this.multi = this.dashboardService.sumValuesByMonthYear(data)
        return;
      }
      this.multi = data;
    });
  }

  yAxisFormat(val: number) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
}