import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ClubFinancial, ClubFinancialStatus, ClubFinancialStatusNamed, Criteria, RakebackList } from './club-financials.model';
import { NotificationService } from 'src/app/shared/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClubFinancialsModalComponent } from 'src/app/modals/club-financials-modal/club-financials-modal.component';
import { Router } from '@angular/router';
import { ClubFinancialsService } from './club-financials.service';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs'
import { debounceTime, takeUntil, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-club-financials',
  templateUrl: './club-financials.component.html',
  styleUrls: ['./club-financials.component.scss'],
})
export class ClubFinancialsComponent implements OnInit {
  @ViewChild('clubFinancialsModalComponent', { static: true }) public clubFinancialsModalComponent!: ClubFinancialsModalComponent;

  @Output() eventClose: EventEmitter<any> = new EventEmitter();

  rakebackList: ClubFinancial[] = [];
  fileType: string | null = null;
  allowedFileType: string[] = [`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`];
  searchChanged: Subject<Criteria> = new Subject<Criteria>();
  subscription: Subject<void> = new Subject();
  criteria: Criteria = new Criteria();

  hasFocus: boolean = false;
  isNewImport!: boolean;
  isSkeletonLoading: boolean = true;

  public get clubFinancialStatus(): typeof ClubFinancialStatus {
    return ClubFinancialStatus;
  }

  public get clubFinancialStatusNamed(): typeof ClubFinancialStatusNamed {
    return ClubFinancialStatusNamed;
  }

  constructor(
    private notificationService: NotificationService,
    private clubFinancialsService: ClubFinancialsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public service: ClubFinancialsService,
    public progressbarConfig: NgbProgressbarConfig
  ) {
    progressbarConfig.striped = true;
    progressbarConfig.animated = true;
    progressbarConfig.type = 'success';

    this.searchChanged.pipe(debounceTime(700))
    .subscribe(searchCriteria => this.getClubFinancials(searchCriteria));
  }

  ngOnInit(): void {
    this.getClubFinancials(this.criteria);
  }

  jumpPagination(newPage: number): void {
    this.criteria.page = newPage
    this.getClubFinancials(this.criteria);
  }

  getClubFinancials(criteria: Criteria): void {
    this.isSkeletonLoading = true;

    if (!criteria.searchTerm) {
      this.spinner.show();
      }
      this.criteria.searchTerm = criteria.searchTerm;
      this.criteria.page = criteria.page;

    this.service.getClubFinancialsData(criteria).pipe(
    takeUntil(this.subscription.asObservable()),
    finalize(() => {
      this.spinner.hide();
    })
    ).subscribe(
      (response: RakebackList) => {
        this.rakebackList = response.data;
        this.criteria.limit = response.limit;
        this.criteria.pages = response.pages;
        this.criteria.total = response.total;

        this.isSkeletonLoading = false;
      },
      (error: any) => {
        console.error(error);
      }
    );
    this.spinner.hide();
  }

  onFileChange(event: Event): void {
    console.log(event);

    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.fileType = file.type;

      if (this.allowedFileType.includes(this.fileType)) {
        // Atualizado para simplesmente carregar o arquivo
        this.clubFinancialsService.postRakeback(file).subscribe({
          next: (response) => {            
            this.openClubFinancialsModal(response.data.data.savedRakebackSender)            
          },
          error: (error) => {
            switch (error.status) {
              case 400:
                switch (error.error.error_code) {
                  case "FILE_ALREADY_IMPORTED":
                    this.notificationService.showError(
                      'Essa planilha já foi importada. ',
                      'Erro ao importar'
                    );
                    break;
                  case "FILE_UPLOAD_REQUIRED":
                    this.notificationService.showError(
                      'É necessário importar uma planilha!',
                      'Erro ao importar'
                    );
                    break;
                  case "DATE_NOT_FOUND":
                    this.notificationService.showError(
                      'Campos de datas não encontrados.',
                      'Erro ao importar'
                    );
                    break;
                  case "HEADER_NOT_FOUND":
                    this.notificationService.showError(
                      'Campos necessários não encontrados.',
                      'Erro ao importar'
                    );
                    break;
                  case "FILE_UPLOAD_S3_ERROR":
                    this.notificationService.showError(
                      'Erro ao salvar planilha no S3.',
                      'Erro ao importar'
                    );
                    break;

                  default:
                    this.notificationService.showError(
                      'Não foi possível importar o arquivo',
                      'Erro ao importar'
                    );
                    break;
                }
                break;
              default:
                this.notificationService.showError(
                  'Não foi possível importar o arquivo',
                  'Erro ao importar'
                );
                break;
            }
          }
        });
      } else {
        this.fileType = null;
        this.notificationService.showError('O arquivo selecionado não é compatível com a importação. Selecione apenas arquivos do tipo .xlsx ', 'Arquivo não compatível');
      }

    } else {
      this.fileType = null;
    }
  }

  onSearchChange (criteria: Criteria) {
    this.searchChanged.next(criteria);
  }

  openResumeSettings(clubFinancialsID: any, filename: any, status: any, totalPlayers: any) {    
    this.router.navigate(['club-financial/settings'], {
      queryParams: {
        clubFinancialsID: clubFinancialsID,
        clubFinancialsFilename: filename,
        clubFinancialsStatus: status,
        clubFinancialsTotalPlayers: totalPlayers,
      },
    });
  }

  openClubFinancialsModal(rakebackSender: any): void {
    this.spinner.show();
    this.clubFinancialsModalComponent.openModal(rakebackSender);
  }

  closeClubFinancialsModal(): void {
    this.clubFinancialsModalComponent.closeModal();
    this.fileType = null;
  }

  countClubFinancialsProgress(countErrors: number, countSucess: number) {
    return countErrors + countSucess;
  }
}
