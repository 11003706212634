<div id="__layout">
  <div
    [ngClass]="{ 'd-flex align-items-center min-vh-100 min-vw-100 bg-light p-0d-flex align-items-center min-vh-100 min-vw-100 bg-light p-0': !insideChangePasswordComponent }">
    <div class="container">
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;"
        *ngIf="!confirmUser && this.codeSolicitation && this.insideChangePasswordComponent">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Alterar senha
        </h2>
        <p class="mt-2 text-center">
          Ao clicar no botão abaixo, você receberá um código em seu Whatsapp para redefinir a senha
        </p>
        <form #requestCodeForm="ngForm" (ngSubmit)="requestCode()">
          <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
            Solicitar código
          </button>
        </form>
      </div>
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;"
        *ngIf="!confirmUser && !this.codeSolicitation">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Alterar senha
        </h2>
        <p class="mt-2 text-center text-muted">
          Insira o código que você recebeu por mensagem com a sua nova senha
        </p>
      </div>
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;" *ngIf="confirmUser">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Confimar usuário
        </h2>
        <p class="mt-2 text-center text-muted">
          Insira o código que você recebeu por mensagem com uma nova senha para confirmar o usuário
        </p>
      </div>
      <div class="container-fluid mt-4 mx-auto p-0 mw-100 bg-white p-4 shadow-sm rounded-lg" style="width: 430px;"
        *ngIf='!this.codeSolicitation'>
        <form #changePasswordForm="ngForm"
          (ngSubmit)="changePasswordForm.form.valid && this.login.passwordConfirm === this.login.password && changePassword()">
          <div class="form-group mb-0">
            <label class="mb-1">
              Código
            </label>
            <input type="tel" pattern="[0-9]*" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="verificationCode" [(ngModel)]="login.verificationCode"
              #verificationCode="ngModel"
              [ngClass]="{ 'is-invalid': changePasswordForm.submitted && verificationCode.invalid }" required
              minlength="5" maxlength="5" verificationCode>
            <div *ngIf="changePasswordForm.submitted && verificationCode.invalid" class="invalid-feedback">
              <div *ngIf="verificationCode.errors?.minlength">O código enviado deve conter 5 digítos</div>
              <div *ngIf="verificationCode.errors?.required">Esse campo é obrigatório</div>
            </div>
          </div>
          <div class="form-group mb-0 mt-4">
            <label class="mb-1">
              Nova senha
            </label>
            <input type="password" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="password" [(ngModel)]="login.password" #password="ngModel"
              [ngClass]="{ 'is-invalid': changePasswordForm.submitted && password.invalid }" required minlength="6"
              password />
            <div *ngIf="changePasswordForm.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf="password.errors?.required">Senha necessária</div>
              <div *ngIf="password.errors?.minlength">A senha deve conter no mínimo 6 dígitos</div>
            </div>
          </div>
          <div class="form-group mb-0 mt-4">
            <label class="mb-1">
              Confirmar nova senha
            </label>
            <input type="password" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              [ngClass]="{ 'is-invalid': changePasswordForm.submitted && password.invalid }" required minlength="6"
              style="height: 40px;" name="passwordConfirm" [(ngModel)]='login.passwordConfirm'
              #passwordConfirm='ngModel' passwordConfirm />
            <div *ngIf="(this.login.passwordConfirm !== this.login.password)" class='text-danger'
              style='font-size: 0.875em; margin-top: 0.25rem;'>
              <div>*As senhas devem coincidir</div>
            </div>
          </div>
          <div *ngIf="validVerificationCode === false"
            class="rounded-0 border-0 alert alert-danger mt-4 mb-0 d-flex align-items-center" role="alert ">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>Código inválido</small>
          </div>
          <div class="mt-4" *ngIf="!confirmUser">
            <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
              Redefinir senha
            </button>
          </div>
          <div class="mt-4" *ngIf="confirmUser">
            <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>