import { TransactionStatus, TransactionStatusNamed } from 'src/app/shared/transaction.enum';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Transaction } from 'src/app/pages/transactions/transaction.model';
import { TransactionService } from 'src/app/pages/transactions/transaction.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { WithdrawSettings } from 'src/app/shared/player.interface';
import { PlayerThermometerService } from 'src/app/pages/player-thermometer/player-thermometer.service';
import { Player, User } from 'src/app/pages/players/player.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss'],
})

export class UserInfoModalComponent {
  @ViewChild('autoShownModal', { static: true }) public autoShownModal!: ModalDirective;

  title!: string;
  transaction!: Transaction;
  name!: string;
  email!: string;
  ddd!: string;
  phoneNumber!: string;
  playerAccountId!: string;
  withdrawSettings: WithdrawSettings = {} as WithdrawSettings;
  showAccountCustomData!: boolean;

  @Output() paymentRefounded = new EventEmitter();

  constructor(
    public service: TransactionService,
    private notificationService: NotificationService,
    public playerThermometerService: PlayerThermometerService,
    private spinner: NgxSpinnerService,
  ) { }

  public get transactionStatus(): typeof TransactionStatus {
    return TransactionStatus;
  }

  public get transactionStatusNamed(): typeof TransactionStatusNamed {
    return TransactionStatusNamed;
  }

  public get formattedCurrencyValue(): string {
    return this.withdrawSettings.maxValueToWithdraw.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  setUserData(user: User) {
    if (user) {
      this.name = user.name;
      this.email = user.email;
      this.ddd = user.ddd;
      this.phoneNumber = user.phoneNumber;
    }
  };

  /**
   * Open modal
   */
  public openModal(playerTransaction: any): void {
    if(!playerTransaction._user) {
      this.playerThermometerService.getGameAccountsData(playerTransaction._id)
        .subscribe((response: any) => {
          const user = response.data.find((item: Player) => item._user);
          if (user) {
            this.setUserData(user._user);
          }
          this.spinner.hide();
          this.autoShownModal.show();
        },
        (error) => {
          console.log(error);
        }
        );
    } else {
      this.setUserData(playerTransaction._user);
      this.withdrawSettings.maxValueToWithdraw = playerTransaction.maxValueToWithdraw / 100;
      this.showAccountCustomData = playerTransaction.maxValueToWithdraw >= 0 || 
        playerTransaction.maxWithdrawPerDay >= 0;
      this.playerAccountId = playerTransaction.playerAccountId ?
        playerTransaction.playerAccountId :
        playerTransaction._gameAccount.playerAccountId;
      this.spinner.hide();
      this.autoShownModal.show();
    }
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.autoShownModal.hide();
  }
}
