import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { Login } from 'src/app/auth/auth.model';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  login: Login = {} as Login;
  validVerificationCode = true;
  confirmUser = false;
  insideChangePasswordComponent = false;
  codeSolicitation = true;
  validPhoneNumber = true;

  constructor(
    private notificationService: NotificationService,
    private service: AuthService,
    private authGuard: AuthGuard,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.confirmUser = JSON.parse(this.cookieService.get('confirmUser'));
    this.activatedRoute.queryParams.subscribe(params => {
      this.login.ddd = parseInt(params.ddd, 10);
      this.login.phoneNumber = parseInt(params.phoneNumber, 10);
      this.insideChangePasswordComponent = params.insideChangePasswordComponent;
      if (this.insideChangePasswordComponent) {
        this.codeSolicitation = true;
      } else {
        this.codeSolicitation = false;
      }
    });
  }

  changePassword(): void {
    this.cookieService.deleteAll();
    this.login.role = 'CLUB';
    this.service.changePassword(this.login).pipe(
      mergeMap((changePasswordResult: any) => {
        this.login.email = changePasswordResult.email;
        return this.service.authenticate(this.login);
      })
    ).subscribe(
      () => {
        this.router.navigate(['']);
        this.notificationService.showSuccess('Nova senha definida com sucesso!', '');
      },
      (error) => {
        if (error.status === 401) {
          this.notificationService.showError('Não foi possível definir a nova senha', '');
        } else {
          this.validVerificationCode = false;
          this.notificationService.showError('Não foi possível definir a nova senha', '');
        }
      });
  }

  requestCode(): void {
    this.login.role = 'CLUB';
    this.service.recoveryPassword(this.login).subscribe(
      () => {
        // this.showContent = false;
        this.notificationService.showSuccess('', 'Código enviado com sucesso');
        this.codeSolicitation = false;
        const navigationExtras: NavigationExtras = {
          queryParams: {
            ddd: this.login.ddd,
            phoneNumber: this.login.phoneNumber,
            confirmUser: this.confirmUser
          }
        };
      },
      (error) => {
        switch (error.status) {
          case 400:
            if (error.error.data.includes('1 hour to send another request')) {
              this.notificationService.showError('Você poderá solicitar o códido novamente após 1 hora', 'Limite máximo excedido');
            }
            break;
          case 422:
            this.notificationService.showError('Número do telefone não existe', '');
            break;
          default:
            this.validPhoneNumber = false;
            this.notificationService.showError('Número do telefone inválido', '');
            break;
        }
      });
  }
}
