export class Criteria {
  startDate: string;
  endDate?: string;
  constructor() {
    this.startDate = '';
    this.endDate = '';
  }
}

export class DatePicker {
  startDate!: string;
  endDate!: string;
}

export class OperationFlow {
  cashIn: number;
  cashOut: number;
  constructor() {
    this.cashIn = 0;
    this.cashOut = 0;
  }
}

export interface OperationFlowResponse {
  data: OperationFlow;
}


export interface OperationResult {
  _id: {
    date: string;
  };
  cashIn: number;
  cashOut: number;
  cashInErrors: number;
  cashOutErrors: number;
  manualCashOut: number;
}

export interface ChartData {
  name: string,
  series: Serie[]
}

export interface ChartDataSingle {
  name: string,
  value: number
}

type Serie = {
  name: string;
  value: number
}