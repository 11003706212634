<div id="__layout">
  <div class="d-flex align-items-center min-vh-100 min-vw-100 bg-light p-0">
    <div class="container">
      <div class="container-fluid mx-auto p-0 mw-100" style="width: 430px;">
        <img src="assets/chippix-logo.svg" alt="Workflow" class="d-flex px-4 mx-auto" style="width: 215px;">
        <h2 class="mt-4 text-center font-weight-bold">
          Entrar no seu clube
        </h2>
        <p class="mt-2 text-center text-muted">
          Ou
          <a [routerLink]="['/signup']" class="text-decoration-none font-weight-bolder">
           fazer cadastro
          </a>
        </p>
      </div>
      <div class="container-fluid mt-4 mx-auto p-0 mw-100 bg-white p-4 shadow-sm rounded-lg" style="width: 430px;">
        <form #loginForm="ngForm" (ngSubmit)="loginForm.form.valid && onSignup()">
          <div class="form-group mb-0">
            <label class="mb-1">
              E-mail
            </label>
            <input type="text" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="email" [(ngModel)]="login.email" #email="ngModel"
              [ngClass]="{ 'is-invalid': loginForm.submitted && email.invalid }" required email />
            <div *ngIf="loginForm.submitted && email.invalid " class="invalid-feedback">
              <div *ngIf="email.errors?.required">Email necessário</div>
              <div *ngIf="email.errors?.email">O e-mail deve ser válido</div>
            </div>
          </div>
          <div class="form-group mt-4 mb-0">
            <label class="mb-1">
              Senha
            </label>
            <input type="password" class="form-control form-control-sm d-block w-100 py-2 px-3 rounded-lg"
              style="height: 40px;" name="password" [(ngModel)]="login.password" #password="ngModel"
              [ngClass]="{ 'is-invalid': loginForm.submitted && password.invalid }" required minlength="6" password />
            <div *ngIf="loginForm.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf="password.errors?.required">Senha necessária</div>
              <div *ngIf="password.errors?.minlength">A senha deve conter no mínimo 6 dígitos</div>
            </div>
          </div>
          <div class="mt-2 text-right">
            <a href="javascript:void(0)" (click)="onRecoveryPassword(false)" class="text-decoration-none font-weight-bolder">
              Esqueceu a senha?
            </a>
          </div>
          <div *ngIf="validCredentials === false"
            class="rounded-0 border-0 alert alert-danger mt-4 mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>E-mail e/ou Senha inválidos.</small>
          </div>
          <div *ngIf="userConfirmed === false"
            class="rounded-0 border-0 alert alert-danger mt-4 mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-x-circle-fill mr-3"></i>
            <small>
              Usuário não confirmado,
              <a href="javascript:void(0)" (click)="onConfirmUser(true)" class="font-weight-bolder text-white">
                clique aqui
              </a>
              para saber mais</small>
          </div>
          <div class="mt-4">
            <button type="submit" class="py-2 px-4 border btn btn-block btn-primary shadow-sm rounded-lg">
              Entrar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
