import { UserInfoModalModule } from './../modals/user-info-modal/user-info-modal.module';
import { PlayerModule } from './players/player.module';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from '../auth/auth.guard';
import { FooterComponent } from '../components/footer/footer.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ControlComponent } from './control/control.component';
import { ControlModule } from './control/control.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { FinanceComponent } from './finance/finance.component';
import { FinanceModule } from './finance/finance.module';
import { HelpComponent } from './help/help.component';
import { PageComponent } from './page.component';
import { PlayerComponent } from './players/player.component';
import { TransactionComponent } from './transactions/transaction.component';
import { TransactionModule } from './transactions/transaction.module';
import { PlayerThermometerComponent } from './player-thermometer/player-thermometer.component';
import { PlayerThermometerModule } from './player-thermometer/player-thermometer.module';
import { NotificationHistoryComponent } from './notification-history/notification-history.component';
import { NotificationHistoryModule } from './notification-history/notification-history.module';
import { PushNotificationModule } from '../modals/push-notification/push-notification.module';

const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'transaction',
        component: TransactionComponent,
      },
      {
        path: 'player',
        component: PlayerComponent,
      },
      {
        path: 'player-thermometer',
        component: PlayerThermometerComponent
      },
      {
        path: 'notification-history',
        component: NotificationHistoryComponent
      },
      {
        path: 'finance',
        component: FinanceComponent,
      },
      {
        path: 'control',
        component: ControlComponent,
      },
      {
        path: 'inside-change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      { path: '', redirectTo: 'transaction', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    PageComponent,
    HelpComponent,
    NotificationHistoryComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    NgbModule,
    TransactionModule,
    FinanceModule,
    DashboardModule,
    ControlModule,
    PlayerModule,
    UserInfoModalModule,
    PlayerThermometerModule,
    NotificationHistoryModule,
    PushNotificationModule
  ],
  providers: [AuthGuard, CurrencyPipe],
})
export class PageModule {}
