
import { PlayerStatus } from '../../shared/player.enum';

export interface Player {
  _id: string;
  platform: string;
  wasActivated: boolean;
  validationAttempt: string;
  playerAccountId: string;
  accountAlias:  string;
  _user: User;
  _club: string;
  clubName: string;
  createdAt: string;
  updatedAt: User;
  lastWithdrawAt: string;
  withdrawLimitUsed: string;
  isBlocked: boolean;
  isWithdrawBlocked: boolean;
  restrictionStatus: string;
  maxValueToWithdraw: number;
  maxWithdrawPerDay: number;
}

export interface User {
  _id: string;
  name: string;
  email: string;
  ddd: string;
  phoneNumber: string;
  createdAt: string;
}

export interface PaymentData {
  customerId: string;
  pixAccountName: string;
  pixKey: string;
}

export interface PlayerResponse {
  data: Player;
}

export interface UserInfo {
  data: UserInfoData;
}

export interface UserInfoData {
  _id: string;
  email: string;
  ddd: string;
  phoneNumber: string;
  name: string;
}

export interface Pageable<T> {
  totalDocs: number;
  total: number;
  limit: number;
  page: number;
  totalPages: number;
  pages: number;
  data: Array<T>;
}

export class Criteria {
  limit: number;
  page: number;
  totalPages: number;
  searchTerm: string;
  listByStatus?: PlayerStatus;
  totalDocs: number;
  sortColumn: any;
  sortDirection: string;
  tab: string;


  constructor() {
    this.limit = 15;
    this.page = 1;
    this.totalPages = 1;
    this.searchTerm = '';
    this.listByStatus = PlayerStatus.ALL;
    this.totalDocs = 0;
    this.sortColumn = '';
    this.sortDirection = 'ASC';
    this.tab = 'all';
  }
}

export interface GameAccounts {
  total: string;
  notActivated: string;
  createdAt: string;
  activated: string;
  blocked: string;
  withdrawBlocked: string;
}

export interface PatchgameAccountStatusPayload {
  action: string,
  maxValueToWithdraw?: number,
  maxWithdrawPerDay?: number,
}

export interface PlayerData {
  gameAccounts: GameAccounts
}

export interface DashboardData {
  data: PlayerData;
}
