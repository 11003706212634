import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import * as settings from './../../config/settings';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  PLAYER_URL,
  GAME_ACCOUNT_PLAYER_LIST_URL,
} from '../../config/settings';
import { PageableData, Player, TotalPlayersResponse } from './player-thermometer.model';
import { PlayerResponse } from '../players/player.model';

@Injectable({
  providedIn: 'root',
})
export class PlayerThermometerService {
  constructor(private http: HttpClient, private spinner: NgxSpinnerService,) { }

  sendPushNotification(notification: any): Observable<any> {
    return this.http.post<any>(settings.PUSH_NOTIFICATION_URL, notification)
      .pipe(finalize(() => this.spinner.hide()));
  }

  getTotalPlayers(datePeriod: string): Observable<TotalPlayersResponse> {
    const params = {
      datePeriod: datePeriod,
    };

    return this.http.get<TotalPlayersResponse>(
      `${PLAYER_URL}/total`, {params}
    )
  }

  getPlayerThermometerdData(criteria: any): Observable<PageableData<Player>> {
    const params = {
      listByName: criteria.listByName,
      searchTerm: criteria.searchTerm,
      page: criteria.page.toString(),
      totalPages: criteria.totalPages,
      datePeriod: criteria.datePeriod,
      temperatureLabel: criteria.temperatureLabel,
      isThermometerActive: criteria.isThermometerActive,
    };
    if (params.listByName === null) {
      delete params.listByName;
    }

    return this.http.get<PageableData<Player>>(PLAYER_URL, { params });
  }

  getGameAccountsData(playerId: string): Observable<PlayerResponse> {
    return this.http.get<PlayerResponse>(
      `${GAME_ACCOUNT_PLAYER_LIST_URL}/${playerId}`
    );
  }
}
