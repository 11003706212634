import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DeleteRakeModalComponent } from './delete-rake-modal.component';



@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    DeleteRakeModalComponent
  ],
  exports: [
    DeleteRakeModalComponent
  ],
  bootstrap: [
    DeleteRakeModalComponent
  ]
})

export class DeleteRakeModalModule { }
