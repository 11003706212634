import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastr: ToastrService,
    private router: Router,
    ) { }

  @Output() toastClick = new EventEmitter();

  showCreateMessage(component: string, success: boolean): void {
    if (success) {
      this.showSuccess(`${component} adicionado com sucesso.`, 'Adição');
    } else {
      this.showError(`Erro ao adicionar ${component}.`, 'Adição');
    }
  }

  showEditMessage(component: string, success: boolean): void {
    if (success) {
      this.showSuccess(`${component} editado com sucesso.`, 'Edição');
    } else {
      this.showError(`Erro ao editar ${component}.`, 'Edição');
    }
  }

  showDeleteMessage(component: string, success: boolean): void {
    if (success) {
      this.showSuccess(`${component} deletado com sucesso.`, 'Deleção');
    } else {
      this.showError(`Erro ao deletar ${component}.`, 'Deleção');
    }
  }

  showSuccess(message: string, title: string): void {
    this.toastr.success(message, title);
  }

  showWarning(message: string, title: string): void {
    this.toastr.warning(message, title);
  }

  showInfo(message: string, title: string): void {
    this.toastr.info(message, title);
  }

  showInfoWithTapEvent(message: string, title: string): Observable<void> {
    return this.toastr.info(message, title).onTap;
  }

  showError(message: string, title: string): void {
    this.toastr.error(message, title);
  }
}
