import { NgModule } from "@angular/core";
import { NgbdSortableHeaderComponent } from "./ngbd-sortable-header.component";
import { SortPipe } from "./sort.pipe";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        NgbdSortableHeaderComponent,
        SortPipe
    ],
    exports: [
        NgbdSortableHeaderComponent,
        SortPipe
    ],
    imports: [
      CommonModule,
      NgbModule,
    ]
  })
  export class NgbdSortableHeaderModule { }