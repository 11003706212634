import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { DoubleCheckModalComponent } from "src/app/modals/double-check-modal/double-check-modal.component";
import { Account } from "src/app/pages/player-thermometer/models/account.model";
import { Player } from "src/app/pages/players/player.model";
import { DoubleCheckModalText, PlayerAction, PlayerStatus, PlayerStatusNamed } from "src/app/shared/player.enum";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit  {
  @ViewChild('doubleCheckModal', { static: true }) public doubleCheckModal!: DoubleCheckModalComponent;

  @Input('data') data!: any;
  @Input('isModal') isModal!: any;
  @Input('player') player!: any;

  @Output() actionPerformed = new EventEmitter<any>();
  @Output() itemSelect = new EventEmitter<any>();


  public get playerStatus(): typeof PlayerStatus {
    return PlayerStatus;
  }

  public get playerStatusNamed(): typeof PlayerStatusNamed {
    return PlayerStatusNamed;
  }

  public get playerAction(): typeof PlayerAction {
    return PlayerAction;
  }

  public get doubleCheckModalText(): typeof DoubleCheckModalText {
    return DoubleCheckModalText;
  }

  constructor() { }

  ngOnInit(): void { }

  openDoubleCheckModal(player: Player, type: string): void {
    switch (type) {
      case this.playerAction.ACTIVATE:
        this.doubleCheckModal.titleText = "Realmente deseja ativar a conta desse jogador?";
        this.doubleCheckModal.buttonText = "Ativar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.ACTIVATE_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.ACTIVATE);
        break;
      case this.playerAction.RESET_ATTEMPS:
        this.doubleCheckModal.titleText = "Realmente deseja resetar as tentativas desse jogador?";
        this.doubleCheckModal.buttonText = "Resetar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.RESET_ATTEMPS_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.RESET_ATTEMPS);
        break;
      case this.playerAction.RESET_WITHDRAW:
        this.doubleCheckModal.titleText = "Resetar quantidades de saque do jogador";
        this.doubleCheckModal.buttonText = "Resetar";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.RESET_WITHDRAW_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.RESET_WITHDRAW);
        break;
      case this.playerAction.BLOCK_WITHDRAW:
        this.doubleCheckModal.titleText = "Realmente deseja bloquear o saque desse jogador?";
        this.doubleCheckModal.buttonText = "Bloquear saque";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.BLOCK_WITHDRAW_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.BLOCK_WITHDRAW);
        break;
      case this.playerAction.UNBLOCK_WITHDRAW:
        this.doubleCheckModal.titleText = "Realmente deseja desbloquear o saque desse jogador?";
        this.doubleCheckModal.buttonText = "Desbloquear saque";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.UNBLOCK_WITHDRAW_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.UNBLOCK_WITHDRAW);
        break;
      case this.playerAction.BLOCK:
        this.doubleCheckModal.titleText = "Realmente deseja bloquear a conta desse jogador?";
        this.doubleCheckModal.buttonText = "Bloquear";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.BLOCK_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.BLOCK);
        break;
      case this.playerAction.WITHDRAW_MANUAL:
        this.doubleCheckModal.titleText = "Realmente deseja alterar o tipo de saque desse jogador para manual?";
        this.doubleCheckModal.buttonText = "Alterar para saque manual";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.WITHDRAW_MANUAL_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.WITHDRAW_MANUAL);
        break;
      case this.playerAction.WITHDRAW_AUTOMATIC:
        this.doubleCheckModal.titleText = "Realmente deseja alterar o tipo de saque desse jogador para automático?";
        this.doubleCheckModal.buttonText = "Alterar para saque automático";
        this.doubleCheckModal.alertText = this.doubleCheckModalText.WITHDRAW_AUTOMATIC_ALERT_TEXT;
        this.doubleCheckModal.openModal(player, this.playerAction.WITHDRAW_AUTOMATIC);
        break;
      case this.playerAction.CUSTOMIZE_WITHDRAW:
        if (this.isModal) {
          this.doubleCheckModal.titleText = "Realmente deseja personalizar o saque desta conta?";
          this.doubleCheckModal.buttonText = "Personalizar";
          this.doubleCheckModal.alertText = this.doubleCheckModalText.CUSTOMIZE_WITHDRAW;
          this.doubleCheckModal.openModal(player, this.playerAction.CUSTOMIZE_WITHDRAW);
        } else {
          this.onItemSelect(player)
        }
        break;
      default:
        break;
    }
  }

  onModalConfirmed(result: any): void {
    this.actionPerformed.emit(result);
  }

  onItemSelect(item: any) {
    this.itemSelect.emit(item);
  }

}