import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { USER_URL } from 'src/app/config/settings';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { Session, SessionData } from './terms-politics-modal.models';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class TermsPoliticsModalService {
  private SLOT_TOKENS = 'slotTokens';
  private NAME = 'name';

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private authGuard: AuthGuard,
    private cookieService: CookieService,
  ) { }

  updateUserTermsOfUse(): Observable<any> {
    this.spinner.show();
    return this.http.patch<Session>(`${USER_URL}/accept-user-term`, {})
      .pipe(
        map((session: Session) => session.data),
        tap((sessionData: SessionData) => {
          this.authGuard.setRefreshToken(sessionData.refreshToken);
          this.authGuard.setToken(sessionData.token);
          const clubName = this.cookieService.get(this.NAME);
          let slots = this.cookieService.get(this.SLOT_TOKENS);
          let updatedSlots = this.updateClubSlots(JSON.parse(slots), JSON.parse(clubName), sessionData.token);
          this.cookieService.set(this.SLOT_TOKENS, JSON.stringify(updatedSlots));
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  };

  updateClubSlots(slots: any[], clubName: string, newToken: string) {
    const clubIndex = slots.findIndex(club => club.name === clubName);
    if (clubIndex !== -1) {
      slots[clubIndex].token = newToken;
    }
    return slots;
  };
}