import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy  {
  activeTab = 2;

  isVisible: boolean = true;
  isSidebarVisible: boolean = true;
  isSmallScreen: boolean = false;
  isMobileScreen: boolean = false;

  private subscription: Subscription = new Subscription();
  
  constructor(
    public route: ActivatedRoute,
    private sidebarControlService: SidebarService,
    private breakpointObserver: BreakpointObserver
  ) {
    
  }

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.sidebarControlService.sidebarVisibility$,
      this.breakpointObserver.observe(['(max-width: 1600px)', '(max-width: 1023px)'])
    ])
    .pipe(
      map(([isVisible, breakpointState]) => {
        const isSmallScreen = breakpointState.breakpoints['(max-width: 1600px)'];
        const isMobileScreen = breakpointState.breakpoints['(max-width: 1023px)'];

        return {
          isSidebarVisible: !isSmallScreen || (!isMobileScreen && isVisible),
          isSmallScreen,
          isMobileScreen
        };
      })
    )
    .subscribe(({ isSidebarVisible, isSmallScreen, isMobileScreen  }) => {
      this.isSidebarVisible = isSidebarVisible;
      this.isSmallScreen = isSmallScreen;
      this.isMobileScreen = isMobileScreen;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  hideNavBar(): void {
    if (this.isMobileScreen) {
      const element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
      if (element.getAttribute('aria-expanded') === 'true') {
        element.click();
      }
    }
  }
}
