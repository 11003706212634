import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TRANSACTION_URL } from '../../../../config/settings';
import { Observable } from 'rxjs';
import { OperationData } from 'src/app/pages/finance/finance.model';

@Injectable({ providedIn: 'root' })
export class TransactionSlidePanelService {
  constructor(private http: HttpClient) {}

  getOperationByTransactionId(transactionId: string): Observable<OperationData> {
    return this.http.get<OperationData>(
      `${TRANSACTION_URL}/${transactionId}/operation`
    );
  }
}
