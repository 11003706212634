<div class="off-canvas shadow-sm" *ngIf="isOpen" [ngClass]="{'active' : isOpen}">
  <div class="panel-content">
    <div class="panel-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onBack()">
        <span class="material-symbols-outlined">arrow_back</span>
      </button>
      <div class="title-loader" *ngIf="isSkeletonLoading">
        <ngx-skeleton-loader [theme]="{'height': '30px', 'width': '300px'}" count="1" appearance="line"
          animation="progress">
        </ngx-skeleton-loader>
      </div>
      <div *ngIf="!mustShowTransactionDetails && !isSkeletonLoading">
        <h5 class="panel-title modal-title">
          Operação {{operation?.createdAt | date: 'd/M/yy'}} às {{operation?.createdAt | date: 'HH:mm'}}
        </h5>
        <span *ngIf="operation?.e2EId" class="text-muted text-break-all">pgto ID: {{operation?.e2EId}}</span>
      </div>
      <div *ngIf="mustShowTransactionDetails && !isSkeletonLoading">
        <h5 class="panel-title modal-title">Informações da transação</h5>
      </div>
    </div>
    <div class="panel-body">
      <div *ngIf="isSkeletonLoading">
        <p class="mb-0">
          <ngx-skeleton-loader [theme]="{'height': '24px', 'width': '50%'}" count="1" appearance="line"
            animation="progress">
          </ngx-skeleton-loader>
        </p>
        <div class="bg-light pt-3 pl-3 pr-3 text-dark rounded-lg">
          <ngx-skeleton-loader [theme]="{'height': '24px', 'background': '#d7d7d757 no-repeat'}" count="4"
            appearance="line" animation="progress">
          </ngx-skeleton-loader>
        </div>
      </div>
      <ng-container *ngIf="!mustShowTransactionDetails; else transactionDetails">
        <div *ngIf="!isSkeletonLoading">
          <app-operation-details [isOpen]="isOpen" [operation]="operation"></app-operation-details>
        </div>
      </ng-container>
      <ng-template #transactionDetails>
        <app-withdraw-details [transaction]="transactionID" [isOpen]="isOpen" [isFinanceComponent]="true"
          (back)="onBack()"></app-withdraw-details>
      </ng-template>
    </div>
    <div class="panel-footer">
      <button
        type="button"
        class="btn btn-outline-primary"
        *ngIf="operation.type === 'CASH_OUT'"
        (click)="onGenereteCashoutReceipt(operation.pixId)">Gerar comprovante</button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="!mustShowTransactionDetails && (operation.purpose != 'SERVICE_CHARGE' && operation.purpose != 'WITHDRAW_BALANCE' && operation.purpose != 'ADD_BALANCE')"
        (click)="openDetailsInTransactions(operation._transaction)">Ver transação</button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="mustShowTransactionDetails && (operation.purpose != 'SERVICE_CHARGE' && operation.purpose != 'WITHDRAW_BALANCE' && operation.purpose != 'ADD_BALANCE')"
        (click)="goToTransactionsList()">Ver todas</button>
    </div>
  </div>