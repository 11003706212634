import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthGuard } from './auth.guard';
import { Router } from '@angular/router';
import { REFRESH_TOKEN_URL } from '../config/settings';
import { environment } from '../../environments/environment';
import { NotificationService } from '../shared/notification.service';
import Constants from 'src/app/shared/constants';
import { getAnalytics, logEvent } from "firebase/analytics";
import { ModalService } from '../shared/modal.service';
import { TermsPoliticsModalComponent } from '../modals/terms-politics-modal/terms-politics-modal.component';
import { ErrorCodes } from '../shared/error.enum';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private config: any;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private oneEmit: number = 1;
  private currentTokenData: any = null;
  // Endpoint that haven't credentials on header
  private blackListEndPoints: any;

  validCredentials = true;
  analytics = getAnalytics();

  constructor(
    private auth: AuthGuard,
    private router: Router,
    private injector: Injector,
    private notificationService: NotificationService,
    private modalService: ModalService
  ) {
    this.config = environment;
    this.loadblackListEndPoints();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.error.error_code === ErrorCodes.MUST_ACCEPT_TERMS
        ) {
          this.modalService.openModal(TermsPoliticsModalComponent);
          return throwError(error);
        }
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          error.error.error_code !== ErrorCodes.INVALID_SCOPE &&
          !this.isOnBlackListEndPoints(request.url)
        ) {
          // Check if the failed request is the token refresh request
          if (request.url === REFRESH_TOKEN_URL) {
            this.auth.logout();
            return throwError('Token refresh attempt failed');
          }
          return this.handleUnauthorizedError(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  /**
   * Method to load endpoints that no need token in header
   */
  private loadblackListEndPoints() {
    this.blackListEndPoints = [
      this.config.urls.login,
      this.config.urls.refresh,
      this.config.urls.confirmation,
    ];
  }

  /**
   * Verify if blackListEndPoints is on blackListEndPoints array
   * @param {*} endpoint final of endpoint to verify on array
   */
  private isOnBlackListEndPoints(endpoint: string) {
    const endpointPart = endpoint.split(
      `${this.config.urls.basePath}${this.config.urls.user}`
    )[1];
    const isOnList = this.blackListEndPoints.filter(
      (ndp: string) => ndp === endpointPart
    );

    if (isOnList.length > 0) {
      return true;
    }
    return false;
  }

  /**
   * Handles 401 HTTP responses. If not currently refreshing the token, attempts
   * to refresh it. If refreshing the token results in another 401 error,
   * it logs out the user.
   * @private
   * @param {HttpRequest<any>} request - The original request that resulted in a 401 error.
   * @param {HttpHandler} next - The next middleware which will handle the request.
   * @returns {Observable<HttpEvent<any>>} - An observable of the HTTP event.
   */
  private handleUnauthorizedError(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(this.oneEmit),
        switchMap((token) => {
          return next.handle(
            this.addAuthorizationHeader(request, token.data.token)
          );
        })
      );
    }
    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);

    // Attempt to refresh token
    return this.auth.getNewJwt().pipe(
      switchMap((token: any) => {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(token);
        this.currentTokenData = token.data;        
        return next.handle(
          this.addAuthorizationHeader(request, token.data.token)
        );
      }),
      catchError((error: any) => {
        this.refreshTokenInProgress = false;
        if (error instanceof HttpErrorResponse && error.status === 401) {
          switch (error.error.error_code) {
            case Constants.UNAUTHORIZED_ACCESS:
              this.validCredentials = false;              
              if (this.currentTokenData) {
                logEvent(this.analytics, 'unauthorized_access', {
                  user_id: this.currentTokenData._id,
                  user_name: this.currentTokenData.name,
                  message: 'Usuário sem permissão após tentativa de refresh token'
                });
              }
              this.notificationService.showError('Você não tem permissão para realizar esta ação', 'Ação não autorizada');
              break;
          };
          return throwError('Token refresh failed, logging out.');
        } else {
          return throwError(error);
        }
      })
    );
  }

  /**
   * Adds the provided token to the request headers.
   *
   * @private
   * @param {HttpRequest<any>} request - The original request.
   * @param {string} token - The JWT token to be added to the request headers.
   * @returns {HttpRequest<any>} - The cloned request with the added token in the headers.
   */
  private addAuthorizationHeader(
    request: HttpRequest<any>,
    token: any
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
