import * as models from './finance.model';
import * as settings from './../../config/settings';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PixKeyType } from 'src/app/shared/finance-account-type.enum';
import { addBalanceResponse } from './finance.model';

@Injectable({ providedIn: 'root' })
export class FinanceService {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
  ) { }

  getBalance(): Observable<models.BalanceResponse> {
    return this.http.get<models.BalanceResponse>(settings.BALANCE_URL)
      .pipe();
  }

  getFinanceAccount(): Observable<models.FinanceAccountResponse> {
    this.spinner.show();
    return this.http.get<models.FinanceAccountResponse>(settings.FINANCE_ACCOUNT_URL)
      .pipe(finalize(() => this.spinner.hide()),
    );
  }

  putFinanceAccount(updateFinanceAccount: models.UpdateFinanceAccount): Observable<models.BankAccountResponse> {
    if (updateFinanceAccount.pixKeyType === PixKeyType.PHONE
      && !updateFinanceAccount.pixKey.includes('+')) {
      updateFinanceAccount.pixKey = `+${updateFinanceAccount.pixKey}`
    }
    this.spinner.show();
    return this.http.put<models.BankAccountResponse>(settings.FINANCE_ACCOUNT_URL, updateFinanceAccount)
      .pipe(finalize(() => this.spinner.hide()),
    );
  }

  postFinanceAccount(createFinanceAccount: models.CreateFinanceAccount): Observable<models.FinanceAccount> {
    this.spinner.show();
    return this.http.post<models.FinanceAccount>(settings.FINANCE_ACCOUNT_URL, createFinanceAccount)
      .pipe(finalize(() => this.spinner.hide())
      );
  }

  postTransferRequest(amount: any): Observable<any> {
    this.spinner.show();
    return this.http.post<any>(settings.FINANCE_TRANSFER_REQUEST_URL, amount)
      .pipe(map((result: any) => {
        const response: any = result;
        return response;
      }), finalize(() => this.spinner.hide()));
  }

  postAddBalance(amount: number): Observable<addBalanceResponse> {
    this.spinner.show();
    return this.http.post<addBalanceResponse>(settings.FINANCE_ADD_BALANCE, { amount: amount })
      .pipe(map((addBalanceResponse: addBalanceResponse) => {
        const response: addBalanceResponse = addBalanceResponse;
        return response;
      }), finalize(() => this.spinner.hide()));
  }

  getListOperations(criteria?: any, date?: any): Observable<any> {
    const params: any = {};
    if (date && date.startDate) {
      params.startDate = date.startDate;
    }
    if (date && date.endDate) {
      params.endDate = date.endDate;
    }
    if (criteria && criteria.page) {
      params.page = criteria.page;
    }
    return this.http.get<models.Pageable<models.Operation>>(settings.FINANCE_OPERATION_URL, { params });
  }

  getOperationFlow(date?: any): Observable<models.OperationFlowResponse> {
    const params: any = {};
    if (date && date.startDate) {
      params.startDate = date.startDate;
    }
    if (date && date.endDate) {
      params.endDate = date.endDate;
    }
    return this.http.get<models.OperationFlowResponse>(settings.FINANCE_OPERATION_FLOW_URL, { params })
      .pipe();
  }
}
