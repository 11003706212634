import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionStatus } from 'src/app/shared/transaction.enum';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { Criteria } from './transaction.model';
import { TransactionSlidePanelComponent } from './components/transaction-slide-panel/transaction-slide-panel.component';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';


@Component({
  selector: 'app-transaction-component',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  providers: [DecimalPipe]
})
export class TransactionComponent implements OnInit, AfterViewInit {
  @ViewChild('transactionList', { static: true }) public transactionList!: TransactionListComponent;
  @ViewChild('transactionSlidePanel') public transactionSlidePanel!: TransactionSlidePanelComponent;

  activeTab!: string;
  isSlidePanelOpen: boolean = false;
  selectedTransaction!: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sidebarControlService: SidebarService,
  ) {
  }

  ngOnInit(){
    this.activeTab = 'PIX';
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.activeTab != undefined){
        setTimeout(() => {
          this.router.navigate(['transaction'], {
            fragment: 'transaction'
          });
          this.activeTab = params.activeTab;
          this.resetTabData(params.activeTab);
        }, 100);
      }
    });
  }

  onTabChange(event: any): void {
    this.resetTabData(event);
  }

  resetTabData(event: any): void {
    const criteria = new Criteria();
    criteria.listByType = event;
    criteria.listByStatus = TransactionStatus.ALL;
    this.transactionList.updateTransactionList(criteria);
    this.transactionList.filterByType = this.transactionList.transactionStatus.ALL; // select ngModel transaction list filter
  }

  onTransactionSelect(item: any) {
    this.isSlidePanelOpen = true;
    this.selectedTransaction = {...item};
    this.sidebarControlService.toggleSidebarVisibility(false);
  }

  closeTransactionSlidePanel() {
    this.isSlidePanelOpen = false;
    this.selectedTransaction = null;
    this.transactionList.clearSelectedTransaction();
    this.sidebarControlService.toggleSidebarVisibility(true);
  }
}
