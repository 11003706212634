<div class="container invite-wrapper">
  <div class="invite-header">
    <p class="font-weight-bold">Convide mais contas para Chippix</p>
  </div>
  <div class="invite-body">
    <div class="px-2">
      <div class="add-player-info-wrapper">
        <p class="invite-info text-muted text-center p-0">
          Não encontrou o jogador por aqui? <br> Adicione um novo jogador pelo botão ao lado e convide para sua plataforma!
        </p>
        <button type="button" class="btn btn-light border d-flex align-items-center ml-2" (click)="onAddNewPlayer()"
            [ngClass]="isNewPlayer ? 'error-color' : 'text-primary'">
          <i class="bi mr-2" [ngClass]="isNewPlayer ? 'bi-x-circle' : 'bi-plus-lg'"></i>
            {{ isNewPlayer ? 'Cancelar adição' : 'Adicionar Jogador' }}
        </button>
      </div>
      <div *ngIf="isNewPlayer">
        <div class="add-player-inputs-wrapper">
          <div class="form-group add-player-inputs w-100">
            <label class="mb-1 font-weight-bold text-muted"> Jogador ID </label>
            <input type="text" class="form-control " placeholder="ID do Jogador" [(ngModel)]="newPlayerId"/>
          </div>
          <div class="form-group add-player-inputs w-100">
            <label class="mb-1 font-weight-bold text-muted"> Telefone </label>
            <div class="input-group" [ngClass]="{ 'focused' : hasPhoneInputFocus }">
              <input mask="(00) 0000-0000||(00) 0 0000-0000" [dropSpecialCharacters]="true" prefix="+55 " [showMaskTyped]="false"
                placeHolderCharacter="*" type="tel" class="form-control phone-input" [(ngModel)]="newPlayerPhoneNumber" minlength="11"
                (focus)="hasPhoneInputFocus = true" (blur)="hasPhoneInputFocus = false" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <button [ngClass]="{'show' : newPlayerPhoneNumber.length > 0}" (click)="newPlayerPhoneNumber = ''"
                      class="clear-input-button"></button>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="mb-1 font-weight-bold text-muted">&nbsp;</label>
            <button (click)="saveNewPlayer()" type="button" class="btn btn-primary" [disabled]="newPlayerPhoneNumber.length < 11 || newPlayerId.length < 5">Salvar</button>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <div class="col-md-12 rounded-0 border-0 alert alert-info mt-2 mb-0 d-flex align-items-center" role="alert">
            <i class="bi bi-info-circle-fill mr-3"></i>
            <small>
              {{alertText}}
            </small>
          </div>
        </div>
      </div>
      <div *ngIf="data.length > 0" class="dropdown-divider"></div>
    </div>
    <ng-container *ngIf="type === 'clubFinancials'; else newPlayersInfo">
      <p *ngIf="data.length > 0" class="px-2 py-2 invite-info text-muted">
        <span class="total-players">{{criteria.totalDocs}} </span> jogador(es) no seu fechamento não estão vinculado(s) à
          <span class="font-weight-bold">Chippix</span>. Convide mais jogadores para sua plataforma.
      </p>
    </ng-container>
    <ng-template #newPlayersInfo>
      <p *ngIf="data.length > 0" class="px-2 py-2 invite-info text-muted">
        <span class="total-players">{{criteria.totalDocs}} </span> jogador(es) convidado(s) a se vincular à sua plataforma.
      </p>
    </ng-template>
    <div *ngIf="data.length > 0" class="table-responsive mt-1">
      <table class="table text-nowrap table-borderless">
        <thead>
          <tr>
            <th class="text-left text-muted font-weight-bold">Jogador ID</th>
            <th class="text-center text-muted font-weight-bold">Telefone</th>
            <th class="text-center text-muted font-weight-bold">Status</th>
            <th class="text-center text-muted font-weight-bold"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let unrelatedPlayer of data" class="player-item">
            <td class="text-left">
              {{unrelatedPlayer.playerAccountId}}
            </td>
            <td 
              (click)="onEditPhoneNumber(unrelatedPlayer)"
              class="text-center"
              [ngClass]="{'add-button' : !unrelatedPlayer.phoneNumber && !unrelatedPlayer._inviter,
                          'edit-button' : unrelatedPlayer.phoneNumber && !unrelatedPlayer._inviter }">
              <span 
                *ngIf="!editingState.get(unrelatedPlayer._id)?.isEditing" 
                [ngClass]="{'no-value' : !unrelatedPlayer.phoneNumber}" 
						    class="phone-info">
                {{ unrelatedPlayer.phoneNumber ? '+55 (' + unrelatedPlayer.ddd + ') ' + formatPhoneNumber(unrelatedPlayer.phoneNumber) : 'Sem telefone preenchido' }}
              </span>
              <input 
                #phoneNumberInput
                *ngIf="editingState.get(unrelatedPlayer._id)?.isEditing"
                mask="(00) 0000-0000 || (00) 0 0000-0000"
                [dropSpecialCharacters]="true"
                prefix="+55"
                [showMaskTyped]="true"
                type="tel" 
                [ngModel]="editingState.get(unrelatedPlayer._id)?.phoneInput"
                (blur)="updatePhoneInput($event, unrelatedPlayer)"
                class="form-control"
                [ngClass]="{'is-invalid': errorMessage && editingPlayerId === unrelatedPlayer._id}" />
                <div *ngIf="errorMessage && editingPlayerId === unrelatedPlayer._id" class="error-message">{{ errorMessage }}</div>
            </td>
            <td class="text-center font-weight-light align-middle">
              <span *ngIf="unrelatedPlayer.restrictionStatus === 'INVITED'" class="badge badge-pill badge-danger font-weight-normal">
                {{unrelatedPlayerStatusNamed[unrelatedPlayerStatus.SENT]}}
              </span>
              <span *ngIf="unrelatedPlayer.wasActivated" class="badge badge-pill badge-success font-weight-normal">
                {{unrelatedPlayerStatusNamed[unrelatedPlayerStatus.LINKED]}}
              </span>
            </td>
            <td class="text-right">
              <div *ngIf="unrelatedPlayer.restrictionStatus !== 'INVITED' && !unrelatedPlayer.wasActivated; else invitedTemplate" class="invite-btn-wrapper">
                <button (click)="onInvitePlayer(unrelatedPlayer)" type="button" class="btn btn-primary">Convidar</button>
              </div>
              <ng-template #invitedTemplate>
                <button *ngIf="!unrelatedPlayer.wasActivated" (click)="deleteInvitation(unrelatedPlayer)" type="button" class="m-auto cancel-invite-button btn" tooltip="Cancelar convite do jogador">
                  <span class="material-symbols-outlined">close</span>
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="data.length" class="px-2 d-flex justify-content-between align-items-center">
        <p class="mt-2 mb-0 text-muted">
          <small class="font-weight-light">
            Exibindo
            <span class="font-weight-normal">{{criteria.page}}</span>
            de
            <span class="font-weight-normal">{{criteria.totalPages}}</span>
            {{criteria.totalPages > 1 ? 'páginas': 'página'}}
          </small>
        </p>
        <ngb-pagination
          class="mt-3 mx-3"
          [collectionSize]="criteria.totalDocs"
          [(page)]="criteria.page"
          [pageSize]="criteria.limit"
          [maxSize]="maxSize"
          [rotate]="rotatePagination"
          [ellipses]="false"
          [boundaryLinks]="true"
          (pageChange)="jumpPagination($event)"
          [hidden]="criteria.page > criteria.totalDocs">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>